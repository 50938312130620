System.register([], (function (exports) {
  'use strict';
  return {
    execute: (function () {

      var accordion = {
        accordiontab: {
          header: ({ props }) => ({
            class: [
              // Sizing
              'pt-6 pb-0',
              'mt-6',

              // Shape
              'border-x-0 border-b-0',

              // Color
              'border border-surface-200',

              // State
              {
                'select-none pointer-events-none cursor-default opacity-60':
                  props?.disabled,
              },
            ],
          }),
          headerAction: ({ context }) => ({
            class: [
              //Font
              'font-semibold',
              'leading-7',

              // Alignments
              'flex items-center justify-between flex-row-reverse',
              'relative',

              // Shape
              'rounded-md',

              // Color
              'bg-transparent',
              'text-surface-900',

              // States
              'focus:outline-none focus:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-600 ring-inset', // Focus

              // Misc
              'cursor-pointer no-underline select-none',
            ],
          }),
          headerIcon: {
            class: 'inline-block ml-2',
          },
          headerTitle: {
            class: 'leading-7',
          },
          content: {
            class: [
              // Font
              'leading-7',

              // Spacing
              'pr-12 pt-2',

              // Color
              'text-surface-600',
            ],
          },
          transition: {
            enterFromClass: 'max-h-0',
            enterActiveClass:
              'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
            enterToClass: 'max-h-[1000px]',
            leaveFromClass: 'max-h-[1000px]',
            leaveActiveClass:
              'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
            leaveToClass: 'max-h-0',
          },
        },
      };

      var autocomplete = {
        root: ({ props }) => ({
          class: [
            'relative',

            // Flex
            'inline-flex',

            // Size
            { 'w-full': props.multiple },

            // Color
            'text-surface-900',

            //States
            {
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        container: ({ props, state }) => ({
          class: [
            // Font
            'font-sans sm:text-sm leading-none',

            // Flex
            'flex items-center flex-wrap',
            'gap-1',

            // Spacing
            'm-0 list-none',
            'px-3 py-1',
            { 'px-3 py-1.5': !props.multiple, 'px-3 py-1': props.multiple },
            // Size
            'w-full',

            // Shape
            'appearance-none rounded-md',

            // Color
            'text-surface-900',
            'bg-surface-0',
            'placeholder:text-surface-400',
            'shadow-sm',

            // States
            'focus:outline-none focus:outline-offset-0',
            {
              'ring-1 ring-inset ring-surface-300':
                !state.focused,
              'ring-2 ring-primary-500': state.focused,
            },

            // Transition
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-text overflow-hidden',
          ],
        }),
        inputtoken: ({ props }) => ({
          class: [
            { 'py-1.5 px-0': !props.multiple, 'p-0.5': props.multiple },
            ,
            'inline-flex flex-auto',
          ],
        }),
        input: ({ props }) => ({
          class: [
            // Font
            'font-sans sm:text-sm leading-none',

            // Shape
            'appearance-none rounded-md',
            { 'rounded-tr-none rounded-br-none': props.dropdown },
            { 'outline-none shadow-none rounded-none': props.multiple },

            // Size
            { 'w-full': props.multiple },

            // Spacing
            'm-0',
            { 'py-1.5 px-3': !props.multiple, 'p-0': props.multiple },

            // Colors
            'text-surface-700/80',
            {
              'bg-surface-0': !props.multiple,
              'border border-surface-300': !props.multiple,
              'border-0 bg-transparent': props.multiple,
            },

            // States
            {
              'focus:outline-none focus:outline-offset-0 focus:ring-inset focus:ring-2 focus:ring-primary-500':
                !props.multiple,
            },

            // Transition
            'transition-colors duration-200',
          ],
        }),
        token: {
          class: [
            // Flexbox
            'inline-flex items-center',

            // Spacing
            'py-0.5 px-3',

            // Shape
            'rounded-[1.14rem]',

            // Colors
            'text-surface-700/70',
            'bg-surface-200',
          ],
        },
        label: {
          class: 'leading-5',
        },
        removeTokenIcon: {
          class: [
            // Shape
            'rounded-md leading-6',

            // Spacing
            'ml-2',

            // Size
            'w-4 h-4',

            // Transition
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer',
          ],
        },
        dropdownbutton: {
          root: {
            class: [
              'relative text-sm leading-none',

              // Alignments
              'items-center inline-flex text-center align-bottom',

              // Shape
              'rounded-r-md',

              // Size
              'px-2.5 py-1.5',
              '-ml-[1px]',

              // Colors
              'text-surface-600',
              'bg-surface-100',
              'ring-1 ring-inset ring-surface-300',

              // States
              'hover:bg-surface-200',
              'focus:outline-none focus:outline-offset-0 focus:ring-1',
              'focus:ring-primary-500',
            ],
          },
        },
        loadingicon: {
          class: [
            'text-sm leading-none text-surface-500',
            'absolute top-[50%] right-[0.5rem] -mt-2 animate-spin',
          ],
        },
        panel: {
          class: [
            // Position
            'absolute top-0 left-0',
            'mt-2',

            // Shape
            'border-0',
            'rounded-md',
            'shadow-md',
            'max-h-[15rem]',
            'overflow-auto',

            // Color
            'bg-surface-0',
            'text-surface-800/80',
            'ring-1 ring-inset ring-surface-300',
          ],
        },
        list: {
          class: 'py-1 list-none m-0',
        },
        item: ({ context }) => ({
          class: [
            // Font
            'sm:text-sm',
            'leading-none',
            { 'font-normal': !context.selected, 'font-bold': context.selected },

            // Position
            'relative',

            // Shape
            'border-0',
            'rounded-none',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            {
              'text-surface-700/80':
                !context.focused && !context.selected,
            },
            {
              'bg-surface-200 text-surface-700/80':
                context.focused && !context.selected,
            },
            {
              'bg-primary-500 text-white':
                context.focused && context.selected,
            },
            {
              'bg-transparent text-surface-700/80':
                !context.focused && context.selected,
            },

            //States
            'hover:bg-primary-500 hover:text-white',

            // Misc
            'cursor-pointer',
            'overflow-hidden',
            'whitespace-nowrap',
          ],
        }),
        itemgroup: {
          class: [
            //Font
            'font-bold',
            'sm:text-sm',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            'text-surface-800/80',
            'bg-surface-0',

            // Misc
            'cursor-auto',
          ],
        },
        emptymessage: {
          class: [
            // Font
            'leading-none',
            'sm:text-sm',

            // Spacing
            'py-2 px-4',

            // Color
            'text-surface-800/80',
            'bg-transparent',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var avatar = {
        root: ({ props, parent }) => ({
          class: [
            // Font
            {
              'text-sm': props.size == null || props.size == 'normal',
              'text-lg': props.size == 'large',
              'text-xl': props.size == 'xlarge',
            },

            // Alignments
            'inline-flex items-center justify-center',
            'shrink-0',
            'relative',

            // Sizes
            {
              'h-[30px] w-[30px]': props.size == null || props.size == 'normal',
              'w-12 h-12': props.size == 'large',
              'w-16 h-16': props.size == 'xlarge',
            },
            { '-ml-4': parent.instance.$style?.name == 'avatargroup' },

            // Shapes
            {
              'rounded-lg': props.shape == 'square',
              'rounded-full': props.shape == 'circle',
            },
            { 'border-[0.5px]': parent.instance.$style?.name == 'avatargroup' },

            // Colors
            'bg-general-300',
            {
              'border-gray-100': parent.instance.$style?.name == 'avatargroup',
            },
            'text-white',
          ],
        }),
        image: {
          class: 'h-full w-full rounded-full',
        },
      };

      var avatargroup = {
        root: {
          'class': 'flex items-center ml-4',
          'data-wv-name': 'usergroup',
          'data-wv-section': 'root',
        },
      };

      var badge = {
        root: ({ props }) => ({
          'class': [
            // Alignment
            'inline-flex items-center',

            // Size
            'py-1 px-2',

            // Shape
            'rounded-[50px]',

            // Color
            {
              '!text-general-400 !bg-general-100': props.disabled,
              'text-success-800 bg-success-100': props.severity == 'success',
              'text-primary-800 bg-primary-200':
                props.severity == null || props.severity == 'primary',
              'text-grayscale-900 bg-grayscale-500': props.severity == 'dark',
              'text-warning-600 bg-warning-100': props.severity == 'warning',
              'text-danger-700 bg-danger-200': props.severity == 'danger',
            },
          ],
          'data-wv-name': 'badge',
          'data-wv-section': 'root',
        }),
        input: ({ props, context }) => ({
          class: [
            'text-nowrap whitespace-nowrap font-normal text-xs leading-4 tracking-[0.2488px]',
            {
              'caret-surface-700': props.editable,
              'cursor-default': !!context.badgeTooltip,
            },
          ],
        }),
        removebutton: ({ props }) => ({
          'class': [
            '!p-0 !w-3 !h-3 ml-1',
            {
              '!text-primary-800':
                !props.badgeSeverity || props.badgeSeverity === 'primary',
              '!text-success-800': props.badgeSeverity === 'success',
              '!text-danger-700': props.badgeSeverity === 'danger',
              '!text-warning-600': props.badgeSeverity === 'warning',
              '!text-grayscale-900': props.badgeSeverity === 'dark',
            },
            {
              'text-general-400': props.disabled,
            },
          ],
          'icon-class': 'w-3 h-3',
          'data-wv-section': 'removebutton',
        }),
      };

      var badgedirective = {
        root: ({ context }) => ({
          class: [
            // Font
            'font-medium',
            'text-xs leading-6 font-sans',

            // Alignment
            'flex items-center justify-center',
            'text-center',

            // Position
            'absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 origin-top-right',

            // Size
            'm-0',
            {
              'p-0': context.nogutter || context.dot,
              'p-1': !context.nogutter && !context.dot,
              'min-w-[0.5rem] h-2': context.dot,
              'min-w-[1rem] h-4': !context.dot,
            },

            // Shape
            {
              'rounded-full': context.nogutter || context.dot,
              'rounded-[10px]': !context.nogutter && !context.dot,
            },

            // Color
            'text-white',
            'ring-1 ring-white',
            {
              'bg-primary-500':
                !context.info &&
                !context.success &&
                !context.warning &&
                !context.danger &&
                !context.help &&
                !context.secondary,
              'bg-surface-500': context.secondary,
              'bg-green-500': context.success,
              'bg-blue-500': context.info,
              'bg-orange-500': context.warning,
              'bg-purple-500': context.help,
              'bg-red-500': context.danger,
            },
          ],
        }),
      };

      var button = {
        root: ({ props, context }) => ({
          class: [
            'relative',

            // Alignments
            'items-center inline-flex text-center align-bottom justify-center',
            {
              'flex-col':
                (props.iconPos === 'top' || props.iconPos === 'bottom') &&
                props.label,
            },

            props.iconPos,
            {
              'flex-row-reverse': props.iconPos === 'right',
            },

            // Sizes & Spacing
            'leading-none font-normal rounded-[50px]',
            {
              '!text-xs px-3 py-[5px]': props.size === null && props.label,
              '!text-xs !p-0.5': props.size === 'small',
              'text-xl py-3 px-4': props.size === 'large',
            },
            { 'gap-1': props.label !== null },

            {
              'p-[7px] w-max':
                props.label === null && props.icon && props.size === null,
            },
            {
              '!p-0.5 !h-4 !w-4':
                props.label === null && props.icon && props.size === 'small',
            },

            // Ring
            'ring-inset ring-1 ',

            // Shapes
            { 'shadow-lg': props.raised },

            // Link Button
            {
              'text-grayscale-900-600 bg-transparent ring-transparent': props.link,
            },

            // Plain Button
            {
              'text-white bg-gray-500 ring-1 ring-gray-500':
                props.plain && !props.outlined && !props.text,
            },
            // Plain Text Button
            { 'text-primary-400': props.plain && props.text },
            // Plain Outlined Button
            {
              'text-primary-400 ring-1 ring-gray-500': props.plain && props.outlined,
            },

            // Text Button
            { 'bg-transparent ring-transparent': props.text && !props.plain },

            // Outlined Button
            { 'bg-transparent ring-1': props.outlined && !props.plain },

            // --- Severity Buttons ---

            // Secondary Button
            {
              'text-white':
                !props.link &&
                props.severity === 'secondary' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-grayscale-900':
                !props.link &&
                props.severity === 'secondary' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-grayscale-900':
                !props.link &&
                props.severity === 'secondary' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Secondary Text Button
            {
              'text-grayscale-900':
                props.text && props.severity === 'secondary' && !props.plain,
            },
            // Secondary Outlined Button
            {
              'text-grayscale-900 ring-1 ring-grayscale-900':
                props.outlined && props.severity === 'secondary' && !props.plain,
            },

            // Primary Gold Button
            {
              'text-white':
                props.severity === null &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-primary-400':
                props.severity === null &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-primary-400':
                props.severity === null &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Primary Gold Text Button
            {
              'text-primary-400':
                props.text && props.severity === null && !props.plain,
            },
            // Primary Gold Outlined Button
            {
              'text-primary-400 ring-1 ring-primary-400 hover:bg-primary-400/20':
                props.outlined && props.severity === null && !props.plain,
            },

            // Success Button
            {
              'text-white':
                props.severity === 'success' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-success-500':
                props.severity === 'success' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-success-500':
                props.severity === 'success' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Success Text Button
            {
              'text-success-500':
                props.text && props.severity === 'success' && !props.plain,
            },
            // Success Outlined Button
            {
              'text-success-500 ring-1 ring-success-500 hover:bg-success-300/20':
                props.outlined && props.severity === 'success' && !props.plain,
            },

            // Info Button
            {
              'text-white':
                props.severity === 'info' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-blue-500':
                props.severity === 'info' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-blue-500':
                props.severity === 'info' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Info Text Button
            {
              'text-blue-500':
                props.text && props.severity === 'info' && !props.plain,
            },
            // Info Outlined Button
            {
              'text-blue-500 ring-1 ring-blue-500 hover:bg-blue-300/20 ':
                props.outlined && props.severity === 'info' && !props.plain,
            },

            // Warning Button
            {
              'text-white':
                props.severity === 'warning' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-warning-500':
                props.severity === 'warning' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-warning-500':
                props.severity === 'warning' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Warning Text Button
            {
              'text-warning-500':
                props.text && props.severity === 'warning' && !props.plain,
            },
            // Warning Outlined Button
            {
              'text-warning-500 ring-1 ring-warning-500 hover:bg-warning-300/20':
                props.outlined && props.severity === 'warning' && !props.plain,
            },

            // Help Button
            {
              'text-white':
                props.severity === 'help' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-purple-500':
                props.severity === 'help' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-purple-500':
                props.severity === 'help' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Help Text Button
            {
              'text-purple-500':
                props.text && props.severity === 'help' && !props.plain,
            },
            // Help Outlined Button
            {
              'text-purple-500 ring-1 ring-purple-500 hover:bg-purple-300/20':
                props.outlined && props.severity === 'help' && !props.plain,
            },

            // Danger Button
            {
              'text-white':
                props.severity === 'danger' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-danger-500':
                props.severity === 'danger' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-danger-500':
                props.severity === 'danger' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Danger Text Button
            {
              'text-danger-500':
                props.text && props.severity === 'danger' && !props.plain,
            },
            // Danger Outlined Button
            {
              'text-danger-500 ring-1 ring-danger-500 hover:bg-danger-300/20':
                props.outlined && props.severity === 'danger' && !props.plain,
            },
            // Contrast Button
            {
              'text-white':
                props.severity === 'contrast' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'bg-surface-900':
                props.severity === 'contrast' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
              'ring-1 ring-surface-900':
                props.severity === 'contrast' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            // Contrast Text Button
            {
              'text-surface-900':
                props.text && props.severity === 'contrast' && !props.plain,
            },
            // Contrast Outlined Button
            {
              'text-surface-900 ring-1 ring-surface-900':
                props.outlined && props.severity === 'contrast' && !props.plain,
            },

            // --- Severity Button States ---
            'focus:outline-none focus:outline-offset-0',

            // Link
            { 'focus:ring-grayscale-900': props.link },

            // Plain
            {
              'hover:bg-gray-600 hover:ring-gray-600':
                props.plain && !props.outlined && !props.text,
            },
            // Text & Outlined Button
            {
              'hover:bg-primary-400/20':
                props.plain && (props.text || props.outlined),
            },

            // Secondary
            {
              'hover:bg-grayscale-900/[8%] active:bg-grayscale-900/[12%] ':
                props.severity === 'secondary',
              'hover:shadow-hover active:!shadow-none hover:!bg-grayscale-900/90 active:bg-grayscale-900/90 hover:ring-0':
                !props.link &&
                props.severity === 'secondary' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },

            // Primary
            {
              'hover:bg-primary-400/[8%] active:bg-primary-400/[12%] ':
                props.severity === null,
              'hover:shadow-hover active:!shadow-none hover:!bg-primary-400/90 active:bg-primary-400/90 hover:ring-0':
                !props.link &&
                props.severity === null &&
                !props.text &&
                !props.outlined &&
                !context.disabled &&
                !props.plain,
            },

            // Success
            {
              'hover:bg-success-500/[8%] active:bg-success-500/[12%] ':
                props.severity === 'success',
              'hover:shadow-hover active:!shadow-none hover:!bg-success-500/90 active:bg-success-500/90 hover:ring-0':
                !props.link &&
                props.severity === 'success' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },

            // Info
            {
              'hover:bg-blue-600 hover:ring-blue-600':
                props.severity === 'info' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            {
              'focus:ring-blue-400/50': props.severity === 'info',
            },
            // Text & Outlined Button
            {
              'hover:bg-blue-300/20':
                (props.text || props.outlined) &&
                props.severity === 'info' &&
                !props.plain,
            },

            // Warning
            {
              'hover:bg-warning-500/[8%] active:bg-warning-500/[12%] ':
                props.severity === 'warning',

              'hover:shadow-hover active:!shadow-none hover:!bg-warning-500/90 active:bg-warning-500/90 hover:ring-0':
                !props.link &&
                props.severity === 'warning' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },

            // Text & Outlined Button
            {
              'hover:bg-warning-300/20':
                (props.text || props.outlined) &&
                props.severity === 'warning' &&
                !props.plain,
            },

            // Help
            {
              'hover:bg-purple-600 hover:ring-purple-600':
                props.severity === 'help' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            {
              'focus:ring-purple-400/50': props.severity === 'help',
            },
            // Text & Outlined Button
            {
              'hover:bg-purple-300/20':
                (props.text || props.outlined) &&
                props.severity === 'help' &&
                !props.plain,
            },

            // Danger
            {
              'hover:bg-danger-500/[8%] active:bg-danger-500/[12%] ':
                props.severity === 'danger',
              'hover:shadow-hover active:!shadow-none hover:!bg-danger-500/90 active:bg-danger-500/90 hover:ring-0':
                !props.link &&
                props.severity === 'danger' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },

            // Text & Outlined Button
            {
              'hover:bg-danger-300/20':
                (props.text || props.outlined) &&
                props.severity === 'danger' &&
                !props.plain,
            },
            // Contrast
            {
              'hover:bg-surface-800 hover:ring-surface-800':
                props.severity === 'contrast' &&
                !props.text &&
                !props.outlined &&
                !props.plain,
            },
            {
              'focus:ring-primary-400': props.severity === 'contrast',
            },
            // Text & Outlined Button
            {
              'hover:bg-surface-900/10':
                (props.text || props.outlined) &&
                props.severity === 'contrast' &&
                !props.plain,
            },
            // Disabled
            {
              'pointer-events-none cursor-default': context.disabled,
              '!text-general-300': context.disabled && (props.outlined || props.text),
              '!ring-general-300': context.disabled && props.outlined,
              '!bg-general-300 !text-white !ring-0':
                context.disabled && !props.outlined && !props.text,
            },

            // Transitions
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer overflow-hidden select-none',

            // Badge
            '[&>[data-pc-name=badge]]:min-w-4 [&>[data-pc-name=badge]]:h-4 [&>[data-pc-name=badge]]:leading-4',
          ],
        }),
        label: ({ props }) => ({
          class: [
            'duration-200',
            'font-normal text-xs',
            props.label ? 'leading-5' : 'leading-4',
            {
              'hover:underline': props.link,
            },
            { 'flex-1': props.label !== null, 'invisible w-0': props.label == null },
          ],
        }),
        icon: ({ props }) => ({
          class: ['shrink-0', { '!h-[11px] !w-[11px]': props.size === 'small' }],
        }),
        loadingIcon: ({ props }) => ({
          class: [
            'h-4 w-4',
            'mx-0',
            {
              'mr-2': props.iconPos === 'left' && props.label != null,
              'ml-2 order-1': props.iconPos === 'right' && props.label != null,
              'mb-2': props.iconPos === 'top' && props.label != null,
              'mt-2': props.iconPos === 'bottom' && props.label != null,
            },
            'animate-spin',
          ],
        }),
        badge: ({ props }) => ({
          class: [
            {
              'ml-2 w-4 h-4 leading-none flex items-center justify-center':
                props.badge,
            },
          ],
        }),
      };

      var buttondownload = {
        root: {
          'class':
            'focus-within:ring-2 focus-within:ring-primary-400 focus-within:outline-none rounded-sm',
          'data-wv-name': 'buttondownload',
          'data-wv-section': 'root',
        },
        icon: {
          'class': 'text-2xl',
          'data-wv-section': 'icon',
        },
      };

      var buttonfilter = {
        root: {
          'class':
            'focus-within:ring-2 focus-within:ring-primary-400 focus-within:outline-none rounded-sm',
          'data-wv-name': 'buttonfilter',
          'data-wv-section': 'root',
        },
        icon: {
          'class': 'text-2xl',
          'data-wv-section': 'icon',
        },
      };

      var buttonsearch = {
        buttontrigger: {
          root: ({ context }) => ({
            'class': [
              { 'w-max': !context.showSearchInput },
              'focus-within:ring-2 focus-within:ring-primary-400 focus-within:outline-none rounded-sm',
            ],
            'data-wv-section': 'buttontrigger',
          }),
          icon: {
            class: 'w-6 h-6 text-grayscale-900 shrink-0',
          },
        },

        collapsebutton: {
          root: ({ context }) => ({
            'class': [
              { 'w-max': !context.showSearchInput },
              'focus-within:ring-2 focus-within:ring-primary-400 focus-within:outline-none rounded-sm',
            ],
            'data-wv-section': 'collapsebutton',
          }),
          icon: {
            class: 'w-6 h-6 text-grayscale-900 shrink-0',
          },
        },

        resetbutton: {
          root: ({ query }) => ({
            'class': [
              { invisible: !query },
              'focus-within:ring-2 focus-within:ring-primary-400 focus-within:outline-none rounded-sm',
            ],
            'data-wv-section': 'resetbutton',
          }),
          icon: { class: ['text-2xl shrink-0'] },
        },

        hiddensubmit: { class: 'hidden' },

        form: ({ context }) => ({
          'class': [
            { show: context.showSearchInput },
            'grid grid-cols-[max-content,auto,max-content] items-center gap-0.5 max-w-[224px] h-6',
            'border-b border-primary-100',
          ],
          'data-wv-section': 'form',
        }),

        inputtext: {
          class: '!h-max',
        },
      };

      const timepickerlabel = {
        class:
          '[&_span]:w-[37px] [&_span]:h-[26px] [&_span]:flex [&_span]:justify-center [&_span]:items-center [&_span]:border [&_span]:border-general-200 [&_span]:rounded [&_span]:',
      };

      var calendar = {
        root: ({ props }) => ({
          class: [
            // Display and Position
            'inline-flex flex-auto justify-between items-center gap-[5px]',
            'rounded',

            // Spacing
            'm-0 px-3 py-1',

            // Border already handled by input group.

            // Misc
            {
              'select-none pointer-events-none cursor-default': props.disabled,
            },
          ],
        }),
        input: ({ props }) => ({
          class: [
            'w-full peer',

            // Font
            'text-xs font-normal placeholder:font-normal leading-6',

            // Colors
            'text-grayscale-900',
            'placeholder:text-general-200',

            // Shape
            'appearance-none',
            // { 'rounded-md': !props.showIcon || props.iconDisplay == 'input' },
            // {
            //   'rounded-l-md  flex-1 pr-9 ':
            //     props.showIcon && props.iconDisplay !== 'input',
            // },
            // {
            //   'rounded-md flex-1 pr-9':
            //     props.showIcon && props.iconDisplay === 'input',
            // },

            // Transitions
            'transition-colors',
            'duration-200',

            // States
            'outline-none focus:ring-primary-400',
          ],
        }),
        inputicon: {
          class: ['text-grayscale-900 peer-disabled:bg-general-300'],
        },
        dropdownbutton: {
          root: {
            class: [
              'relative text-sm',

              // Alignments
              'items-center inline-flex text-center align-bottom',

              // Shape
              'rounded-r-md',

              // Size
              'px-2.5 py-1.5 leading-none',

              // Colors
              'text-surface-600',
              'bg-surface-100',
              'ring-1 ring-inset ring-surface-300',

              // States
              'hover:bg-surface-200',
              'focus:outline-none focus:outline-offset-0 focus:ring-1',
              'focus:ring-primary-400',
            ],
          },
        },
        panel: ({ props }) => ({
          class: [
            'datepicker-panel',
            // Display & Position
            'inline-flex',
            'flex-col justify-start items-center gap-2.5',
            {
              'absolute': !props.inline,
              'inline-block': props.inline,
            },

            // Size
            '!w-[272px] !min-w-[272px] h-max p-6 shadow-panel',
            { 'min-w-[80vw] w-auto p-2 ': props.touchUI },
            { 'p-2 min-w-full': props.inline },

            // Shape
            'rounded-[7px]',

            // Colors
            'bg-white',

            //Misc
            { 'overflow-x-auto': props.inline },
          ],
        }),
        datepickerMask: {
          class: [
            'fixed top-0 left-0 w-full h-full',
            'flex items-center justify-center',
            'bg-black bg-opacity-90',
          ],
        },
        header: ({ props, state }) => ({
          class: [
            'min-h-[26.8px]',

            {
              hidden:
                !props.dateFormat?.toString().toLowerCase().includes('y') &&
                state.currentView === 'month',
            },

            //Font
            'text-xs font-normal',

            // Flexbox and Alignment
            'flex justify-between items-start gap-1',

            // Spacing
            'm-0 mb-2',
            // {
            //   'py-2 pl-2 pb-4': props.numberOfMonths <= 1,
            //   'py-2 pb-4': props.numberOfMonths > 1,
            // },

            // Shape
            'rounded-t-md',

            // Colors
            'text-grayscale-900',
            'bg-white',
          ],
        }),
        previousbutton: ({ props }) => ({
          class: [
            'relative',

            // Flexbox and Alignment
            'inline-flex items-center justify-center',
            'order-1',
            // {
            //   ' order-2': !(props.numberOfMonths > 1),
            //   'order-1': props.numberOfMonths > 1,
            // },

            // Size
            'p-1.5 m-0',

            // Colors
            'text-grayscale-900',
            'border-0 ',
            'bg-transparent',

            // States
            'hover:bg-general-50',

            // Misc
            'cursor-pointer overflow-hidden',
          ],
        }),
        title: ({ props }) => ({
          class: [
            // Text
            'leading-6',
            'my-0',
            'order-2',
            // {
            //   'mr-auto': !(props.numberOfMonths > 1),
            //   ' mx-auto': props.numberOfMonths > 1,
            // },
          ],
        }),
        monthTitle: {
          class: [
            //Font
            'text-xs font-normal',

            // Colors
            'text-grayscale-900',
            'bg-white',

            // Transitions
            'transition duration-200',

            // Spacing
            'mr-1',

            // States
            'hover:text-primary-400',

            // Misc
            'cursor-pointer',
          ],
        },
        yearTitle: ({ props }) => ({
          class: [
            {
              hidden: !props.dateFormat?.toString().toLowerCase().includes('y'),
            },

            //Font
            'text-xs font-normal',

            // Colors
            'text-grayscale-900',
            'bg-white',

            // Transitions
            'transition duration-200',

            // Spacing
            'm-0 mr-1',

            // States
            'hover:text-primary-600',

            // Misc
            'cursor-pointer',
          ],
        }),
        nextbutton: ({ props }) => ({
          class: [
            'relative',

            // Flexbox and Alignment
            'inline-flex items-center justify-center order-3',
            // {
            //   ' order-3': !(props.numberOfMonths > 1),
            //   'order-1': props.numberOfMonths > 1,
            // },

            // Size
            'p-1.5 m-0',

            // Colors
            'text-grayscale-900',
            'border-0 ',
            'bg-transparent',

            // States
            'hover:bg-general-50',

            // Misc
            'cursor-pointer overflow-hidden',
          ],
        }),
        table: {
          class: [
            // Size & Shape
            'block w-[224px]',
            '[&_tbody]:block [&_tbody]:w-[224px]',

            // Spacing
            'm-0',

            '[&_th]:text-xs [&_td]:text-xs',
            '[&_th]:font-normal [&_td]:font-normal',
            '[&_th]:text-grayscale-900 [&_td]:text-grayscale-900',

            // Hide the other months day
            '[&_[data-p-other-month="true"]]:invisible',
          ],
        },
        tableheadercell: {
          class: [
            // Spacing
            'px-2 py-[5.4px]',
          ],
        },
        tablebodyrow: {
          class: [
            // 'border-b border-surface-200 last:border-b-0',
          ],
        },
        weekheader: {
          class: [
            'leading-6 text-sm font-normal',
            'text-surface-600/70',
            'opacity-40 cursor-default',
            'mb-2',
          ],
        },
        weeknumber: {
          class: [
            'text-surface-600/70 font-normal',
            'opacity-40 cursor-default',
          ],
        },
        weekday: {
          class: [
            // Colors
            // 'text-surface-500/60 font-normal',
          ],
        },
        day: {
          class: [
            // Spacing
            'p-0',
          ],
        },
        weeklabelcontainer: ({ context }) => ({
          class: [
            // Flexbox and Alignment
            'flex items-center justify-center',
            'mx-auto',

            // Shape & Size
            'w-10 h-10',
            '',
            'border-transparent border',

            // Colors
            {
              'text-surface-600/70 bg-transparent':
                !context.selected && !context.disabled,
              'text-primary-400 ':
                context.selected && !context.disabled,
            },

            // States
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
            {
              'hover:bg-surface-100': !context.disabled,
            },
            {
              'opacity-40 cursor-default': context.disabled,
              'cursor-pointer': !context.disabled,
            },
          ],
        }),
        daylabel: ({ context }) => ({
          class: [
            // Flexbox and Alignment
            'flex items-center justify-center',
            // 'mx-auto',

            // Shape & Size
            'w-8 h-8',

            // Colors
            {
              'bg-primary-400 text-white rounded-none':
                (context.selected && !context.disabled) || context.date.today,
            },

            {
              '!rounded-full': context.date.today && !context.selected,
            },

            // States
            'focus:outline-none focus-visible:outline-none',
            {
              'hover:bg-primary-400/90': context.selected || context.date.today,
              'hover:bg-primary-200': !context.selected,
            },

            {
              'opacity-40 cursor-default': context.disabled,
              'cursor-pointer': !context.disabled,
            },
          ],
        }),
        monthpicker: {
          class: [
            // Layout
            'w-full grid grid-cols-3 grid-rows-4 gap-y-4 gap-x-0',
          ],
        },
        month: ({ context }) => ({
          'class': [
            // Flexbox and Alignment
            'inline-flex items-center justify-center',

            // Size
            'w-full',
            'px-2.5 py-1.5',
            'text-xs leading-tight',

            // Shape
            '!rounded-[20px]',

            // Colors
            {
              'text-grayscale-900 bg-transparent':
                !context.selected && !context.disabled,
              'bg-primary-400 text-white':
                context.selected && !context.disabled,
            },

            // States
            // 'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-400',
            'hover:bg-primary-50',
            'hover:text-grayscale-900',

            // Misc
            'cursor-pointer',
          ],
          'data-month-in-future':
            parseInt(context?.monthIndex) > new Date().getMonth(),
        }),
        yearpicker: {
          class: [
            // Layout
            'w-full grid grid-cols-2 grid-rows-5 gap-y-1 gap-x-0',
          ],
        },
        year: ({ context }) => ({
          'class': [
            // Flexbox and Alignment
            'inline-flex items-center justify-center',

            // Size
            'w-full',
            'px-2.5 py-1.5',
            'text-xs leading-tight',

            // Shape
            '!rounded-[20px]',

            // Colors
            {
              'text-grayscale-900 bg-transparent':
                !context.selected && !context.disabled,
              'bg-primary-400 text-white':
                context.selected && !context.disabled,
            },

            // States
            // 'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-400',
            'hover:text-grayscale-900',
            'hover:bg-primary-50',

            // Misc
            'cursor-pointer',
          ],
          'data-year-in-future':
            parseInt(context?.year.value) > new Date().getFullYear(),
        }),
        timepicker: {
          class: [
            // Flexbox
            'flex',
            'justify-center items-center',
            'gap-2',

            // Spacing
            'p-1.5',
          ],
        },
        separatorcontainer: {
          class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',

            // Spacing
            // 'px-2',
          ],
        },
        separator: {
          class: [
            // Text
            'text-xs font-normal',
          ],
        },
        hourpicker: {
          class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',
            'gap-3',

            // Spacing
            // 'px-2',

            // Sizing
            'text-xs',

            // Misc
            timepickerlabel.class,
          ],
        },
        minutepicker: {
          class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',
            'gap-3',

            // Spacing
            // 'px-2',

            // Sizing
            'text-xs',

            // Misc
            timepickerlabel.class,
          ],
        },
        secondPicker: {
          class: [
            // Flexbox and Alignment
            'flex',
            'items-center',
            'flex-col',
            'gap-3',

            // Spacing
            // 'px-2',

            // Sizing
            'text-xs',
          ],
        },
        incrementbutton: {
          class: [
            'relative',

            // Flexbox and Alignment
            'inline-flex items-center justify-center',

            // Size
            'p-0.5 m-0',
            '!h-6 !w-6',

            '[&_i]:w-5',
            '[&_i]:h-5',
            '[&_i]:shrink-0',
            '',

            // Colors
            'text-grayscale-900',
            'border-0',
            'bg-transparent',

            // States
            // 'hover:text-surface-700',

            // Misc
            'cursor-pointer overflow-hidden',
          ],
        },
        decrementbutton: {
          class: [
            'relative',

            // Flexbox and Alignment
            'inline-flex items-center justify-center',

            // Size
            'p-0.5 m-0',
            '!h-6 !w-6',

            '[&_i]:w-5',
            '[&_i]:h-5',
            '[&_i]:shrink-0',
            '',

            // Colors
            'text-grayscale-900',
            'border-0',
            'bg-transparent',

            // States
            // 'hover:text-surface-700',

            // Misc
            'cursor-pointer overflow-hidden',
          ],
        },
        groupcontainer: {
          class: [
            // Flexbox
            'flex w-full',
          ],
        },
        group: {
          class: [
            // // Flexbox and Sizing
            // 'flex-1',
            'w-full',

            // // Borders
            // 'border-l',
            // 'border-surface-200',

            // // Spacing
            // 'pr-0.5',
            // 'pl-0.5',
            // 'pt-0',
            // 'pb-0',

            // // Pseudo-Classes
            // 'first:pl-0',
            // 'first:border-l-0',
          ],
        },
        buttonbar: {
          class: [
            // Flexbox
            'flex justify-between items-center',

            // Spacing
            'pt-2.5 pb-1.5 px-0',

            // Shape
            'border-t border-surface-200',
          ],
        },
        todaybutton: {
          root: {
            class: [
              // Flexbox and Alignment
              'inline-flex items-center justify-center',

              // Spacing
              'px-2.5 py-1.5 text-sm leading-none',

              // Shape
              '',

              // Colors
              'bg-transparent border-transparent',
              'text-primary-400',

              // Transitions
              'transition-colors duration-200 ease-in-out',

              // States
              // 'focus:outline-none focus:outline-offset-0 focus:ring-2 ring-inset',
              // 'focus:ring-primary-400',

              // Misc
              'cursor-pointer',
            ],
          },
        },
        clearbutton: {
          root: {
            class: [
              // Flexbox and Alignment
              'inline-flex items-center justify-center',

              // Spacing
              'px-2.5 py-1.5 text-sm leading-none',

              // Shape

              // Colors
              'bg-transparent border-transparent',
              'text-primary-400',

              // Transitions
              'transition-colors duration-200 ease-in-out',

              // States
              'focus:outline-none focus:outline-offset-0 focus:ring-2 ring-inset',
              'focus:ring-primary-400',
              'hover:bg-primary-300/20',

              // Misc
              'cursor-pointer',
            ],
          },
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var card = {
        root: {
          class: [
            //Shape
            'rounded-lg p-6',

            'flex flex-col gap-y-3',

            //Color
            'bg-white',
            'text-grayscale-900',
          ],
        },
        header: {
          class: [''],
        },
        body: {
          class: 'flex flex-col gap-3',
        },
        title: {
          class: 'text-2xl font-bold',
        },
        subtitle: {
          class: ['text-sm font-normal'],
        },
        content: {
          class: 'flex flex-col gap-3 text-xs text-grayscale-900',
        },
        footer: {
          class: ['inline-flex gap-1 text-xs items-center'],
        },
      };

      var carousel = {
        root: {
          class: [
            // Flexbox
            'flex flex-col',
          ],
        },
        content: {
          class: [
            // Flexbox & Overflow
            'flex flex-col overflow-auto',
          ],
        },
        container: ({ props }) => ({
          class: [
            // // Flexbox
            'flex',

            // // Orientation
            {
              'flex-row': props.orientation !== 'vertical',
              'flex-col': props.orientation == 'vertical',
            },
          ],
        }),
        previousbutton: {
          class: [
            // Flexbox & Alignment
            'flex justify-center items-center self-center',

            // Sizing & Overflow
            'overflow-hidden w-8 h-8',

            // Spacing
            'mx-2',

            // Shape
            'rounded-full',

            // Border & Background
            'border-0 bg-transparent',

            // Color
            'text-surface-600',

            // Transitions
            'transition duration-200 ease-in-out',
          ],
        },
        nextbutton: {
          class: [
            // Flexbox & Alignment
            'flex justify-center items-center self-center',

            // Sizing & Overflow
            'overflow-hidden w-8 h-8',

            // Spacing
            'mx-2',

            // Shape
            'rounded-full',

            // Border & Background
            'border-0 bg-transparent',

            // Color
            'text-surface-600',

            // Transitions
            'transition duration-200 ease-in-out',
          ],
        },
        itemscontent: {
          class: [
            // Overflow & Width
            // 'overflow-hidden w-full',
          ],
        },
        itemscontainer: ({ props }) => ({
          class: [
            // Flexbox
            // 'flex',

            // Orientation & Sizing
            // {
            //   'flex-row': props.orientation !== 'vertical',
            //   'flex-col h-full': props.orientation == 'vertical',
            // },
          ],
        }),
        item: ({ props }) => ({
          class: [
            // Flexbox
            // 'flex',

            // Width
            // {
            //   'w-1/3': props.orientation !== 'vertical',
            //   'w-full': props.orientation == 'vertical',
            // },
          ],
        }),
        indicators: {
          class: [
            // Flexbox & Alignment
            'flex flex-row justify-center flex-wrap gap-1',
          ],
        },
        indicator: {
          class: [
            // Spacing
          ],
        },
        indicatorbutton: ({ context }) => ({
          class: [
            // Sizing & Shape
            'w-1.5 h-1.5 rounded-full',

            // Transitions
            'transition duration-200',

            // Focus Styles
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',

            // Color & Background
            {
              'bg-surface-200 hover:bg-surface-300':
                !context.highlighted,
              'bg-primary-500 hover:bg-primary-600': context.highlighted,
            },
          ],
        }),
      };

      var cascadeselect = {
        root: ({ props, state }) => ({
          class: [
            // Display and Position
            'inline-flex',
            'relative',

            // Shape
            'w-full md:w-56',
            'rounded-md',
            'shadow-sm',

            // Color and Background
            'bg-surface-0',

            // States
            {
              'ring-1 ring-inset ring-surface-300':
                !state.focused,
              'ring-2 ring-inset ring-primary-500':
                state.focused,
            },

            // Misc
            'cursor-default',
            'select-none',
            { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled },
          ],
        }),
        label: ({ props }) => ({
          class: [
            //Font
            'font-sans',
            'leading-6',
            'sm:text-sm',

            // Flex & Alignment
            ' flex flex-auto',

            // Sizing and Spacing
            'w-[1%]',
            'py-1.5 px-3',

            //Shape
            'rounded-none',

            // Color and Background
            'bg-transparent',
            'border-0',
            {
              'text-surface-800/80': props.modelValue,
              'text-surface-400': !props.modelValue,
            },
            'placeholder:text-surface-400',

            // Transitions
            'transition',
            'duration-200',

            // States
            'focus:outline-none focus:shadow-none',

            // Misc
            'relative',
            'cursor-pointer',
            'overflow-hidden overflow-ellipsis',
            'whitespace-nowrap',
            'appearance-none',
          ],
        }),
        dropdownbutton: {
          class: [
            //Font
            'sm:text-sm',

            // Flexbox
            'flex items-center justify-center',
            'shrink-0',

            // Color and Background
            'bg-transparent',
            'text-surface-500',

            // Size
            'w-12',

            // Shape
            'rounded-tr-md',
            'rounded-br-md',
          ],
        },
        panel: {
          class: [
            // Position
            'absolute top-0 left-0',
            'mt-2',

            // Shape
            'border-0',
            'rounded-md',
            'shadow-md',

            // Color
            'bg-surface-0',
            'text-surface-800/80',
            'ring-1 ring-inset ring-surface-300',
          ],
        },
        wrapper: {
          class: [
            // Sizing
            'max-h-[200px]',

            // Misc
            'overflow-auto',
          ],
        },
        list: {
          class: 'py-1 list-none mx-1.5',
        },
        item: ({ context }) => ({
          class: [
            // Font
            'sm:text-sm',
            'leading-none',

            // Shape
            'border-0',
            'rounded-md',

            // Spacing
            'm-0',

            //  Colors
            {
              'text-surface-500/70':
                !context.focused && !context.active,
              'text-surface-500/70 bg-surface-200':
                context.focused && !context.active,
              'text-surface-900/80 bg-surface-50':
                context.focused && context.active,
              'text-surface-900/80 bg-surface-50':
                !context.focused && context.active,
            },

            // Hover States
            {
              'hover:bg-surface-50': !context.active,
              'hover:bg-surface-100 text-surface-900/80':
                context.active,
            },

            // Transitions
            'transition-shadow',
            'duration-200',

            // Misc
            'cursor-pointer',
            'overflow-hidden',
            'whitespace-nowrap',
          ],
        }),
        content: {
          class: [
            'relative',

            // Flexbox
            'flex',
            'items-center',

            // Spacing
            'py-2 px-4',

            // Misc
            'no-underline',
            'overflow-hidden',
            'cursor-pointer',
            'select-none',
          ],
        },
        groupicon: {
          class: [
            // Alignment
            'ml-auto',
          ],
        },
        sublist: {
          class: [
            // Size
            'w-full sm:w-48',

            // Spacing
            'p-1.5',
            'm-0',
            'list-none',

            // Shape
            'shadow-none sm:shadow-md',
            'border-0',

            // Position
            'static sm:absolute',
            'z-10',

            // Color
            'bg-surface-0',
          ],
        },
        separator: {
          class: 'border-t border-surface-200 my-1',
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var changelog = {
        button: (buttonClass) => ({
          'class': [buttonClass, 'w-max'],
          'data-wv-section': 'changelog-button',
        }),
        icon: {
          class: ['text-2xl'],
        },
        dialog: {
          'class': 'w-[800px] !z-50',
          'data-wv-name': 'changelog-dialog',
        },
        root: {
          class: 'flex flex-col gap-2 justify-between',
        },
        header: {
          'class': 'flex justify-end gap-1 items-center',
          'data-wv-section': 'changelog-dialog-header',
        },
        buttonsearch: {
          'data-wv-section': 'changelog-dialog-button-search',
        },
        buttonfilter: {
          'data-wv-section': 'changelog-dialog-button-filter',
        },
        buttondownload: {
          'data-wv-section': 'changelog-dialog-button-download',
        },
      };

      var checkbox = {
        root: {
          class: [
            'relative',

            'w-4 h-4',
            'shrink-0',

            // Alignment
            'inline-flex',
            'align-bottom',

            // Transitions
            'transition-all',
            'duration-200',
          ],
        },
        box: ({ props, context }) => ({
          class: [
            // Alignment
            'flex',
            'items-center',
            'justify-center',

            // Size
            'w-full',
            'h-full',

            // Shape
            'rounded',
            'border-2',

            // Colors
            'text-white',
            {
              'border-general-300': props.disabled,
              'border-grayscale-900': !props.disabled,
              'bg-white': !context.checked && !props.disabled,
              'bg-general-50': !context.checked && props.disabled,
              'bg-grayscale-900': context.checked && !props.disabled,
              'bg-general-300': context.checked && props.disabled,
            },

            {
              'ring-8 ring-primary-400/[12%]': !props.disabled && context.focused,
            },

            // States
            {
              'peer-focus-visible:ring-8 peer-focus-visible:ring-offset-0 peer-focus-visible:ring-primary-400/[8%]':
                !props.disabled,
              'peer-active:ring-8 peer-active:ring-offset-0 peer-active:ring-primary-400/[12%]':
                !props.disabled,
              'cursor-default bg-general-50 border-general-200':
                props.disabled && !context.checked,
              'bg-general-200 border-general-200':
                props.disabled && context.checked,
            },

            // Icon state
            {
              '[&_i]:bg-transparent': props.disabled && !context.checked,
              '[&_i]:text-white': context.checked,
              '[&_i]:text-transparent': !context.checked,
            },

            // Transitions
            'transition-all',
            'duration-200',
          ],
        }),
        input: {
          class: [
            'peer',

            // Size
            'w-full ',
            'h-full',

            // Position
            'absolute',
            'top-0 left-0',
            'z-10',

            // Spacing
            'p-0',
            'm-0',

            // Shape
            'rounded',
            'border',

            // Shape
            'opacity-0',
            'rounded-md',
            'outline-none',
            // 'border-2 border-surface-300',

            // Misc
            'appareance-none',

            // Transitions
            'transition-all',
            'duration-200',

            // Misc
            'cursor-pointer',
          ],
        },
        icon: {
          class: [
            // Font
            'text-normal',

            // Size
            'w-3',
            'h-3',

            // Colors
            // 'text-white',

            // Transitions
            'transition-all',
            'duration-200',
          ],
        },
      };

      var chip = {
        root: {
          class: [
            // Flexbox
            'inline-flex items-center',

            // Spacing
            'px-2 py-0.5',

            // Shape
            'rounded-[1.14rem]',

            // Colors
            'text-surface-700',
            'bg-surface-200',
          ],
        },
        label: {
          class: 'text-xs leading-6 mx-0',
        },
        icon: {
          class: 'leading-6 mr-2',
        },
        image: {
          class: ['w-6 h-6 mr-2', 'rounded-full'],
        },
        removeIcon: {
          class: [
            // Shape
            'rounded-md leading-6',

            // Spacing
            'ml-2',

            // Size
            'w-4 h-4',

            // Transition
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer',
          ],
        },
      };

      var chips = {
        root: ({ props }) => ({
          class: [
            'flex',
            {
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        container: ({ state }) => ({
          class: [
            // Font
            'font-sans sm:text-sm leading-none',

            // Flex
            'flex items-center flex-wrap gap-1',

            // Spacing
            'm-0 py-1 px-3',

            // Size
            'w-full',

            // Shape
            'list-none',
            'rounded-md',

            // Color
            'text-surface-900',
            'bg-surface-0',
            'placeholder:text-surface-400',
            'shadow-sm',

            // States
            {
              'ring-1 ring-inset ring-surface-300':
                !state.focused,
              'ring-2 ring-primary-500': state.focused,
            },

            // Transition
            'transition-colors duration-200',

            // Misc
            'cursor-text overflow-hidden',
            'appearance-none',
          ],
        }),

        inputtoken: {
          class: ['py-0.5 px-0', 'inline-flex flex-auto'],
        },
        input: {
          class: [
            // Font
            'font-sans sm:text-sm leading-none',

            // Size
            'w-full',

            // Spacing
            'p-0 m-0',

            // Shape
            'appearance-none rounded-none',
            'border-0 outline-none',

            // Color
            'text-surface-700/80',
            'bg-transparent',
            'placeholder:text-surface-400',
          ],
        },
        token: {
          class: [
            // Flexbox
            'inline-flex items-center',

            // Spacing
            'py-0.5 px-3',

            // Shape
            'rounded-[1.14rem]',

            // Colors
            'text-surface-700/70',
            'bg-surface-200',
          ],
        },
        label: {
          class: 'leading-5',
        },
        removeTokenIcon: {
          class: [
            // Shape
            'rounded-md leading-6',

            // Spacing
            'ml-2',

            // Size
            'w-4 h-4',

            // Transition
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer',
          ],
        },
      };

      var colorpicker = {
        root: ({ props }) => ({
          class: [
            // Display
            'inline-block',

            // Misc
            {
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        input: {
          class: [
            // Font
            'font-sans text-base ',

            // Spacing
            'm-0',

            // Size & Shape
            'rounded',
            'w-4',
            'h-4',

            // Colors
            'bg-surface-0',
            'border border-surface-300',

            // States
            'hover:border-primary-500',
            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-500',

            // Transition
            'transition-colors duration-200',

            // Misc
            'cursor-default',
            'select-none',
          ],
        },
        panel: ({ props }) => ({
          class: [
            // Position & Size
            {
              'relative h-48 w-52': props.inline,
              'absolute h-48 w-52': !props.inline,
            },

            // Shape
            'shadow-md',
            'border-0',
            'rounded-md',

            // Colors
            'bg-surface-800',
            'ring-1 ring-inset ring-surface-900',
          ],
        }),
        selector: {
          class: [
            // Position
            'absolute top-2 left-2',

            // Size
            'h-44 w-40',
          ],
        },
        color: {
          class: [
            // Size
            'h-44 w-40',
          ],
          style:
            'background: linear-gradient(to top, #000 0%, rgb(0 0 0 / 0) 100%), linear-gradient(to right, #fff 0%, rgb(255 255 255 / 0) 100%)',
        },
        colorhandle: {
          class: [
            'absolute',

            // Shape
            'rounded-full border border-solid',

            // Size
            'h-3 w-3',

            // Colors
            'border-white',

            // Misc
            'cursor-pointer  opacity-85',
          ],
        },
        hue: {
          class: [
            // Position
            'absolute top-2 left-44',

            // Size
            'h-44 w-6',

            // Opacity
            'opacity-85',
          ],
          style:
            'background: linear-gradient(0deg, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red)',
        },
        huehandle: {
          class: [
            // Position
            'absolute left-0 -ml-1',

            // Size
            'h-2 w-8',

            // Shape
            'border-solid border-2 rounded',

            // Colors
            'border-white',

            // Misc
            'opacity-85',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var confirmpopup = {
        root: {
          class: [
            // Shape
            'rounded-lg',
            'shadow-xl',
            'border-0',

            // Positioning
            'z-40 transform origin-center',
            'mt-3 absolute left-0 top-0',

            // Color
            '',
            'bg-surface-0',
            'text-surface-700/80',

            // Before: Arrow
            'before:absolute before:w-0 before:-top-3 before:h-0 before:border-transparent before:border-solid before:ml-6 before:border-x-[0.75rem] before:border-b-[0.75rem] before:border-t-0 before:border-b-surface-0',
          ],
        },
        content: {
          class: [
            // Font
            'text-sm',

            // Spacing
            'px-6',
            'py-3',

            // Colors
            'bg-surface-0',
            'text-surface-600',

            // Misc
            'overflow-y-auto',
          ],
        },
        icon: {
          class: 'text-xl mr-2',
        },
        footer: {
          class: [
            // Flexbox and Alignment
            'flex items-center justify-end',
            'shrink-0',
            'text-right',
            'gap-3',

            // Spacing
            'px-6',
            'py-3',

            // Shape
            'border-t-0',
            'rounded-b-lg',

            // Colors
            'bg-surface-50',
            'text-surface-700/80',
          ],
        },
        rejectbutton: {
          root: {
            class: [
              'relative',

              // Alignments
              'items-center inline-flex text-center align-bottom justify-center',

              // Sizes & Spacing
              'px-2.5 py-1.5 min-w-[2rem]',
              'text-sm',

              // Shape
              'rounded-md',

              // Color
              'text-primary-500',

              // States
              'hover:bg-primary-300/20',
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset',
              'focus:ring-primary-500',
            ],
          },
        },
        acceptbutton: {
          root: {
            class: [
              'relative',

              // Alignments
              'items-center inline-flex text-center align-bottom justify-center',

              // Sizes & Spacing
              'px-2.5 py-1.5 min-w-[2rem]',
              'text-sm',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'ring-1 ring-primary-500',

              // States
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
              'focus:ring-primary-500',
            ],
          },
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var contextmenu = {
        root: {
          class: [
            // Sizing and Shape
            'min-w-[12rem]',
            'rounded-md',
            'shadow-md',

            // Spacing
            'p-1.5',

            // Colors
            'bg-surface-0',
            'text-surface-700/80',
          ],
        },
        menu: {
          class: [
            // Spacings and Shape
            'list-none',
            'm-0',
            'p-0',
            'outline-none',
          ],
        },
        menuitem: {
          class: 'relative',
        },
        content: ({ context }) => ({
          class: [
            //Shape
            'rounded-md',

            //  Colors
            {
              'text-surface-500/70':
                !context.focused && !context.active,
              'text-surface-500/70 bg-surface-200':
                context.focused && !context.active,
              'text-surface-900/80 bg-surface-50':
                !context.focused && context.active,
            },

            // Hover States
            {
              'hover:bg-surface-50': !context.active,
              'hover:bg-surface-100 text-surface-900/80':
                context.active,
            },

            // Transitions
            'transition-shadow',
            'duration-200',
          ],
        }),
        action: {
          class: [
            'relative',

            // Font
            'font-semibold',

            // Flexbox
            'flex',
            'items-center',

            // Spacing
            'py-2',
            'px-3',

            // Misc
            'no-underline',
            'overflow-hidden',
            'cursor-pointer',
            'select-none',
          ],
        },
        icon: {
          class: [
            // Spacing
            'mr-2',
            'leading-6',
            'text-sm',
          ],
        },
        label: {
          class: ['leading-6', 'text-sm'],
        },
        submenu: ({ props }) => ({
          class: [
            // Size
            'w-full sm:w-48',

            // Spacing
            'p-1.5',
            'm-0',
            'list-none',

            // Shape
            'shadow-md',
            'rounded-md',

            // Position
            'static sm:absolute',
            'z-10',
            { 'sm:absolute sm:left-full sm:top-0': props.level > 1 },

            // Color
            'bg-surface-0',
          ],
        }),
        submenuicon: {
          class: ['ml-auto'],
        },
        separator: {
          class: 'border-t border-surface-200 my-1',
        },
        transition: {
          enterFromClass: 'opacity-0',
          enterActiveClass: 'transition-opacity duration-250',
        },
      };

      var datatable = {
        root: {
          'data-wv-name': 'datatable',
          'data-wv-section': 'root',
        },

        tablewrapper: {
          'class': [
            'overflow-auto relative scrollbar-w-thin',
            'rounded-lg rounded-br-none rounded-bl-none',
          ],
          'data-wv-section': 'tablewrapper',
        },

        scrollheightwrapper: ({ props }) => ({
          'class': [
            'overflow-hidden w-full min-w-max',
            {
              '!overflow-y-auto': !!props.scrollHeight,
            },
          ],
          'style': `scrollbar-width: none; max-height: ${props.scrollHeight}`,
          'data-wv-section': 'scrollheightwrapper',
        }),

        table: {
          'class': [
            'min-w-max w-full',
            '!border-separate !border-spacing-0 border !border-solid !border-primary-100 !border-t-0 !border-l-0 !border-r-0 !rounded-lg',
          ],
          'data-wv-section': 'table',
        },

        tbody: {
          class: [
            '[&_tr:last-of-type>td]:!border-b-0',
            '[&_tr:last-of-type>td:last-child]:!rounded-br-lg',
            '[&_tr:last-of-type>td:first-child]:!rounded-bl-lg',
          ],
        },
        thead: {
          class: ['sticky top-0 z-50'],
        },
        headerrow: {
          class: ['border-b border-primary-100'],
        },
        bodyrow: ({ context, props }) => ({
          class: [
            'border-b border-general-100',
            {
              '!h-[35px]': props.rowHeight === 'fixed', // Style(DXJCiX2e): set table row height to fixed 35px
            },
            // Color
            'text-general-800 text-xs font-normal',
            {
              'bg-transparent': !context.selected,
              'bg-primary-100': context.selected,
            },

            // State
            {
              'focus:outline-none focus:outline-offset-0': props.selectionType,
              // To sets the bg to single action td
              'hover:bg-primary-50 group':
                props.selectionType &&
                props.selectionType !== 'none' &&
                !context.disabled,
            },

            // Transition
            {
              'transition duration-200':
                (props.selectionType && !context.selected) || props.rowHover,
            },

            'px-4 transition-transform',
            { 'select-none': context.dragging, 'select-auto': !context.dragging },
            { '!cursor-grab [&_label]:!cursor-grab': context.draggable },

            // Misc
            { 'cursor-pointer': props.selectionType !== 'none' && !context.disabled },
          ],
        }),
        headercell: ({ context }) => {
          return {
            'class': [
              'text-xs font-semibold text-white p-2 text-left cursor-pointer',
              { 'bg-primary-400': !context.sorted || !context.sortable },
              { 'bg-primary-500': context.sorted },
              {
                'sticky right-0 hover:bg-primary-500': context.customColumn,
                '!cursor-default': !context.customColumn && !context.sortable,
              },
              'border-0 !border-b border-solid',
              'border-primary-100',
            ],
            'data-wv-section': 'headercell',
          };
        },
        headercellcontent: {
          'class': ['inline-flex gap-2 items-center leading-[18px]'],
          'data-wv-section': 'headercellcontent',
        },
        headercellreorderable: {
          'class': ['w-[35px] !py-1'],
          'data-wv-section': 'headercellreorderable',
        },
        draggableicon: {
          class: 'w-[18px] h-[18px] !p-0 !m-0 !cursor-grab [&_label]:!cursor-grab',
        },
        headercheckbox: ({ context }) => ({
          'class': [
            {
              '[&_[data-pc-section=box]]:!border-white [&_[data-pc-section=box]]:!bg-transparent':
                !context.isSelectedAll,
            },
          ],
          'data-wv-section': 'headercheckbox',
        }),
        headercellcheckbox: {
          'class': 'w-[35px] text-center',
          'data-wv-section': 'headercellcheckbox',
        },
        headertoggler: {
          'class': ['w-[40px] text-center !py-1'],
          'data-wv-section': 'headertoggler',
        },
        columnvisibilityicon: {
          class: ['!w-4 !h-4 !mx-auto'],
          info: 'Visibilitas Kolom',
        },
        headertogglerbutton: ({ context }) => ({
          'class': [
            '!p-0 !m-0 !w-auto !h-auto',
            { 'rotate-180': context.isExpandedAll },
            { 'rotate-0': !context.isExpandedAll },
          ],
          'icon-class': 'w-6 h-6 text-white',
          'data-wv-section': 'headertogglerbutton',
        }),

        rowcheckbox: {
          'class': '',
          'data-wv-section': 'rowcheckbox',
        },
        bodycell: {
          'class': [
            'text-xs px-2 py-0.5',
            'border-0 !border-b !border-solid border-primary-100',

            '[&:has([data-wv-section=rowcheckbox])]:text-center',
            '[&:has([data-wv-section=rowcheckbox])]:text-center',
          ],
          'data-wv-section': 'bodycell',
        },
        rowtogglerbutton: ({ context }) => ({
          'class': [
            '!p-0 !m-0 !w-auto !h-auto',
            { 'rotate-180': context.isRowExpanded },
            { 'rotate-0': !context.isRowExpanded },
          ],
          'icon-class': 'w-6 h-6',
          'data-wv-section': 'rowtogglerbutton',
        }),

        childrowheader: {
          'class': 'font-semibold text-xs',
          'data-wv-section': 'childrowheader',
        },

        celleditableelement: {
          'class': [
            'focus:px-2',
            'w-full inline-block py-2 focus:outline-grayscale-600 focus:outline-1',
          ],
          'data-wv-section': 'celleditableelement',
        },

        rowsingleactioncell: ({ props, context }) => ({
          'class': [
            'group-hover:!bg-primary-50 w-[35px]',
            {
              'sticky bg-white': props.useOption,
              '!bg-primary-100': context.selected,
            },
          ],
          'data-wv-section': 'rowsingleactioncell',
        }),

        singleactionwrapper: {
          'class': ['relative w-full h-full flex items-center justify-center'],
          'data-wv-section': 'singleactionwrapper',
        },

        singleactionbutton: ({ props }) => ({
          'class': [
            {
              'pointer-events-none !border-general-100 [&>i]:text-general-200':
                props.disableAllRows,
            },
            { 'pointer-events-auto': !props.disableAllRows },
          ],
          'tooltip': 'Aksi',
          'data-wv-section': 'singleactionbutton',
        }),

        nodatalottiewrapper: {
          'class': 'w-full p-4 flex items-center justify-center',
          'data-wv-section': 'nodatalottiewrapper',
        },

        nodatalottie: {
          'class': 'w-44 h-auto',
          'data-wv-section': 'nodatalottie',
        },

        loadingtablewrapper: {
          'class': 'sticky left-0 w-full p-4 flex items-center justify-center',
          'data-wv-section': 'loadingtablewrapper',
        },

        loadingtablelottie: {
          'class': 'w-20 h-auto',
          'data-wv-section': 'loadingtablelottie',
        },

        paginator: ({ context }) => ({
          'current-page-report-template': context.totalRecords
            ? 'Menampilkan {first} - {last} dari {totalRecords}'
            : 'Tidak ditemukan data',

          'class': 'sticky left-0 bottom-0',

          'template':
            'FirstPageLink PrevPageLink PageLinks JumpToPageInput NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
        }),
      };

      var dataview = {
        content: {
          class: [
            // Spacing
            'p-0',

            // Shape
            'border-0',

            // Color
            'text-surface-700/80',
            'bg-surface-0',
          ],
        },
        grid: {
          class: [
            // Flex
            'flex flex-wrap',

            // Spacing
            'ml-0 mr-0 mt-0',

            // Color
            'bg-surface-0',
          ],
        },
        header: {
          class: [
            'font-semibold',

            // Spacing
            'p-6',

            // Color
            'text-surface-800/80',
            'bg-surface-0',
            'border-surface-200 border-b',
          ],
        },
      };

      var dataviewlayoutoptions = {
        listbutton: ({ props }) => ({
          class: [
            // Font
            'leading-none',

            // Flex Alignment
            'inline-flex items-center align-bottom text-center',

            // Shape
            'rounded-md rounded-r-none',

            // Spacing
            'px-2.5 py-1.5',

            // Color
            'ring-1 ring-surface-200',
            props.modelValue === 'list'
              ? 'bg-surface-100 text-surface-700'
              : 'bg-surface-0 text-surface-700/80',

            // States
            'focus:outline-none focus:outline-offset-0 focus:ring-primary-500',
            'hover:bg-surface-200/80',

            // Transition
            'transition duration-200',

            // Misc
            'cursor-pointer select-none overflow-hidden',
          ],
        }),
        gridbutton: ({ props }) => ({
          class: [
            // Font
            'leading-none',

            // Flex Alignment
            'inline-flex items-center align-bottom text-center',

            // Shape
            'rounded-md rounded-l-none',

            // Spacing
            'px-2.5 py-1.5',

            // Color
            'ring-1 ring-surface-200',
            props.modelValue === 'grid'
              ? 'bg-surface-100 text-surface-700'
              : 'bg-surface-0 text-surface-700/80',

            // States
            'focus:outline-none focus:outline-offset-0 focus:ring-primary-500',
            'hover:bg-surface-200/80',

            // Transition
            'transition duration-200',

            // Misc
            'cursor-pointer select-none overflow-hidden',
          ],
        }),
      };

      var dialog = {
        root: ({ state }) => ({
          class: [
            // Shape
            // 'rounded-lg',
            // 'shadow-xl',
            // 'border-0',
            'rounded-[0.4375em]',
            'shadow-panel',

            // Layout
            'flex flex-col',

            // Spacing
            'p-6',
            'gap-3',

            // Background
            'bg-white',

            // Size
            'max-h-[90vh]',
            { 'sm:max-w-[90vw]': !state.maximized },
            'm-0',

            // Transitions
            'transform',
            'scale-100',

            // Maximized State
            {
              'transition-none': state.maximized,
              'transform-none': state.maximized,
              '!w-screen': state.maximized,
              '!h-screen': state.maximized,
              '!max-h-full': state.maximized,
              '!top-0': state.maximized,
              '!left-0': state.maximized,
            },
          ],
        }),
        header: {
          class: [
            // Flexbox and Alignment
            'flex items-center gap-2',
            'shrink-0',

            // Shape
            'rounded-tl-lg',
            'rounded-tr-lg',

            // Colors
            'text-general-800',
          ],
        },
        title: {
          class: [
            'text-general-800 text-sm font-bold leading-[19.12px] tracking-[0.24px]',
          ],
        },
        icons: {
          class: ['flex items-center ml-auto'],
        },
        closeButton: {
          class: [
            'relative',

            // Flexbox and Alignment
            'flex items-center justify-center',

            // Size and Spacing
            'w-6 h-6 !p-0.5',

            // Shape
            'border-0',
            'rounded-full',

            // Colors
            'text-general-200',
            'bg-transparent',

            // Transitions
            'transition duration-200 ease-in-out',

            // States
            'hover:bg-general-50 hover:text-general-300',

            // Misc
            'overflow-hidden',
          ],
        },
        maximizablebutton: {
          class: [
            'relative',

            // Flexbox and Alignment
            'flex items-center justify-center',

            // Size and Spacing
            'mr-2',
            'last:mr-0',
            'w-6 h-6',

            // Shape
            'border-0',
            'rounded-full',

            // Colors
            'text-surface-500',
            'bg-transparent',

            // Transitions
            'transition duration-200 ease-in-out',

            // States
            'hover:text-surface-700',
            'hover:bg-surface-100',
            'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset',
            'focus:ring-primary-500',

            // Misc
            'overflow-hidden',
          ],
        },
        closeButtonIcon: {
          class: [
            // Display
            'inline-block',

            // Color
            'text-general-200',

            // Size
            'w-3.5',
            'h-3.5',
          ],
        },
        maximizableicon: {
          class: [
            // Display
            'inline-block',

            // Size
            'w-3',
            'h-3',
          ],
        },
        content: ({ state, instance }) => ({
          class: [
            // Font
            '!text-general-800 text-xs font-normal',

            'pr-1.5 -mr-1.5 pl-1.5 -ml-1.5 pt-1.5 -mt-1.5 !pb-1.5 -mb-1.5',

            // Layout
            'flex flex-col gap-3',

            // Shape

            // Colors
            'text-surface-600',

            // Misc
            'overflow-y-auto scrollbar-w-none',

            {
              grow: state?.maximized,
            },
          ],
        }),
        footer: {
          class: [
            // Flexbox and Alignment
            'flex items-center justify-end',
            'shrink-0',
            'text-right',
            'gap-1 mt-2',

            // Shape
            'border-t-0',
            'rounded-b-lg',

            // Colors
            'bg-inherit',
            'text-surface-700/80',
          ],
        },
        mask: ({ props }) => ({
          class: [
            // Transitions
            'transition',
            'duration-300',
            { 'p-5': !props.position == 'full' },

            // Background and Effects
            {
              'has-[.mask-active]:bg-transparent bg-dialog-mask': props.modal,
              'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal,
            },
          ],
        }),
        transition: ({ props }) => {
          return props.position === 'top'
            ? {
                enterFromClass:
                  'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass:
                  'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
              }
            : props.position === 'bottom'
              ? {
                  enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass:
                    'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active',
                }
              : props.position === 'left' ||
                  props.position === 'topleft' ||
                  props.position === 'bottomleft'
                ? {
                    enterFromClass:
                      'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass:
                      'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active',
                  }
                : props.position === 'right' ||
                    props.position === 'topright' ||
                    props.position === 'bottomright'
                  ? {
                      enterFromClass:
                        'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                      enterActiveClass: 'transition-all duration-200 ease-out',
                      leaveActiveClass: 'transition-all duration-200 ease-out',
                      leaveToClass:
                        'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                    }
                  : {
                      enterFromClass: 'opacity-0 scale-75 mask-active',
                      enterActiveClass: 'transition-all duration-200 ease-out',
                      leaveActiveClass: 'transition-all duration-200 ease-out',
                      leaveToClass: 'opacity-0 scale-75 mask-active',
                    };
        },
      };

      var dialogconfirm = {
        root: {
          'class': ['!w-[clamp(360px,75vw,400px)]'],
          'data-wv-name': (attrsDataName) => attrsDataName ?? 'root',
          'data-wv-section': (attrsDataSection) =>
            attrsDataSection ?? 'dialogconfirm',
        },
        header: {
          'data-wv-section': 'dialog-confirm-header',
        },
        headericon: { 'class': 'text-2xl', 'data-wv-section': 'headericon' },
        headertitle: (severity) => ({
          'class': [
            'text-base leading-none font-semibold',
            {
              'text-success-700': severity === 'success',
              'text-danger-700': severity === 'danger',
            },
          ],
          'data-wv-section': 'dialog-confirm-title',
        }),
        content: {
          'class': '[&_ul]:list-inside [&_ul]:list-disc [&_ul_li]:pl-[6px]',
          'data-wv-section': 'dialog-confirm-content',
        },
        list: {
          'data-wv-section': 'dialog-confirm-list',
        },
        bodymessage: {
          'data-wv-section': 'confirm-message',
        },
        cancelbutton: {
          'data-wv-section': 'cancel-button',
        },
        confirmbutton: {
          'data-wv-section': 'confirm-button',
        },
        footer: {
          'class': 'flex items-end justify-end gap-1 !mt-0',
          'data-wv-section': 'dialog-confirm-footer',
        },
      };

      var dialogform = {
        root: {
          'class': ['max-w-[90vw]'],
          'style': (computedWidth) => `width: ${computedWidth}`,
          'data-wv-section': 'dialog-form',
        },
        header: {
          'class': 'flex items-center gap-2',
          'data-wv-section': 'dialog-form-header',
        },
        headericon: { 'class': 'text-2xl', 'data-wv-section': 'headericon' },
        headertitle: {
          'class':
            'mr-auto text-grayscale-900 text-center text-[0.9rem] leading-[1.125rem] !font-semibold tracking-[0.28px]',
          'data-wv-section': 'dialog-form-title',
        },
        expandasidebutton: {
          'class': ['!px-1.5 !py-1 -mr-1.5 !text-xs'],
          'data-wv-section': 'expand-aside-button',
        },
        closedialog: {
          'class': '!p-0.5 !text-general-200',
          'data-wv-section': 'closebutton',
        },
        container: (expanded) => ({
          'class': [
            'flex overflow-y-auto overflow-x-hidden scrollbar-w-none',
            { 'gap-6': expanded },
          ],
          'data-wv-section': 'dialog-form-container',
        }),
        mainsection: {
          'class': ['flex flex-col gap-3 w-full'],
          'data-wv-section': 'dialog-form-main',
        },
        asidesection: (expanded, asideRightWidth) => ({
          'class': [
            'flex flex-col gap-3 shrink-0',
            { 'opacity-0': !expanded },
            { 'opacity-100': expanded },
          ],
          'style': `width: ${expanded ? asideRightWidth - 24 : 0}px`,
          'data-wv-section': 'dialog-form-aside',
        }),
        form: {
          'class': 'overflow-y-auto',
          'style': 'scrollbar-width: none !important',
          'data-wv-section': 'form',
        },
        footer: {
          'class': 'flex flex-col gap-3 items-end justify-center',
          'data-wv-section': 'dialog-form-footer',
        },
        footerbutton: {
          'class': 'flex gap-1 items-center justify-end',
          'data-wv-section': 'footer-button',
        },
        cancelbtn: {
          'data-wv-section': 'cancel-btn',
        },
        clearfield: {
          'data-wv-section': 'clear-field',
        },
        savesubmitbutton: {
          'data-wv-section': 'save-submit-button',
        },
      };

      var divider = {
        root: ({ props }) => ({
          class: [
            // Flex and Position
            'flex relative',
            { 'justify-center': props.layout == 'vertical' },
            { 'items-center': props.layout == 'vertical' },
            {
              'justify-start': props?.align == 'left' && props.layout == 'horizontal',
              'justify-center':
                props?.align == 'center' && props.layout == 'horizontal',
              'justify-end': props?.align == 'right' && props.layout == 'horizontal',
              'items-center': props?.align == 'top' && props.layout == 'vertical',
              'items-start': props?.align == 'center' && props.layout == 'vertical',
              'items-end': props?.align == 'bottom' && props.layout == 'vertical',
            },

            // Spacing
            {
              'my-5 mx-0 py-0 px-5': props.layout == 'horizontal',
              'mx-4 md:mx-5 py-5': props.layout == 'vertical',
            },

            // Size
            {
              'w-full': props.layout == 'horizontal',
              'min-h-full': props.layout == 'vertical',
            },

            // Before: Line
            'before:block',

            // Position
            {
              'before:absolute before:left-0 before:top-1/2':
                props.layout == 'horizontal',
              'before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2':
                props.layout == 'vertical',
            },

            // Size
            {
              'before:w-full': props.layout == 'horizontal',
              'before:min-h-full': props.layout == 'vertical',
            },

            // Shape
            {
              'before:border-solid': props.type == 'solid',
              'before:border-dotted': props.type == 'dotted',
              'before:border-dashed': props.type == 'dashed',
            },

            // Color
            {
              'before:border-t before:border-surface-200':
                props.layout == 'horizontal',
              'before:border-l before:border-surface-200':
                props.layout == 'vertical',
            },
          ],
        }),
        content: {
          class: [
            // Space and Position
            'p-2 z-10',

            // Color
            'bg-surface-0',
          ],
        },
      };

      var dock = {
        root: ({ props }) => ({
          class: [
            // Positioning
            'absolute z-1',
            {
              'left-0 bottom-0 w-full': props.position == 'bottom',
              'left-0 top-0 w-full': props.position == 'top',
              'left-0 top-0 h-full': props.position == 'left',
              'right-0 top-0 h-full': props.position == 'right',
            },

            // Flexbox & Alignment
            'flex justify-center items-center',

            // Interactivity
            'pointer-events-none',
          ],
        }),
        container: {
          class: [
            // Flexbox
            'flex',

            // Shape & Border
            'rounded-md',

            // Color
            'bg-surface-0/10 border border-surface-0/20',
            'backdrop-blur-sm',

            // Spacing
            'p-2',

            // Misc
            'pointer-events-auto',
          ],
        },
        menu: ({ props }) => ({
          class: [
            // Flexbox & Alignment
            'flex items-center justify-center',
            {
              'flex-col': props.position == 'left' || props.position == 'right',
            },

            // List Style
            'm-0 p-0 list-none',

            // Shape
            'outline-none',
          ],
        }),
        menuitem: ({ props, context, instance }) => ({
          class: [
            // Spacing & Shape
            'p-2 rounded-md',

            // Conditional Scaling
            {
              'hover:scale-150': instance.currentIndex === context.index,
              'scale-125':
                instance.currentIndex - 1 === context.index ||
                instance.currentIndex + 1 === context.index,
              'scale-110':
                instance.currentIndex - 2 === context.index ||
                instance.currentIndex + 2 === context.index,
            },

            // Positioning & Hover States
            {
              'origin-bottom hover:mx-6': props.position == 'bottom',
              'origin-top hover:mx-6': props.position == 'top',
              'origin-left hover:my-6': props.position == 'left',
              'origin-right hover:my-6': props.position == 'right',
            },

            // Transitions & Transform
            'transition-all duration-200 ease-cubic-bezier-will-change-transform transform',
          ],
        }),
        action: {
          class: [
            // Flexbox & Alignment
            'flex flex-col items-center justify-center',

            // Position
            'relative',

            // Size
            'w-16 h-16',

            // Misc
            'cursor-default overflow-hidden',
          ],
        },
      };

      var multiselect = {
        root: ({ props, state }) => ({
          class: [
            // Display and Position
            'inline-flex',
            'relative',

            // Shape
            'w-full',

            // Misc
            'cursor-default',
            'select-none',
            { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled },
          ],
        }),
        labelContainer: {
          class:
            'overflow-hidden flex flex-auto items-center cursor-pointer py-[3px] px-3 pr-0',
        },
        label: ({ props }) => ({
          class: [
            'flex gap-1',

            'h-max',

            //Font
            'text-xs font-normal',
            {
              '!leading-5': props.modelValue == null,
            },

            //Shape
            'rounded-none',

            // Color
            {
              'text-general-200': !props.modelValue?.length,
              'text-grayscale-900': props.modelValue?.length,
            },

            // Transitions
            'transition duration-200',

            // Misc
            'overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis',
          ],
        }),
        token: {
          class: [
            // Flexbox
            'inline-flex items-center gap-1',

            // Spacing
            'py-1 px-2',

            // Shape
            'rounded-[50px]',

            'text-xs',
            'font-normal',
            'leading-[12px]',
            'tracking-[0.03em]',
            'items-center',

            'text-grayscale-900',
            'bg-grayscale-500',
          ],
        },
        removeTokenIcon: {
          class: [
            // Shape
            'rounded-md leading-6',

            // Spacing
            'ml-2',

            // Size
            'w-4 h-4',

            // Transition
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer',
          ],
        },
        trigger: {
          class: [
            //Font
            // 'sm:text-sm',

            // Spacing
            'mr-1',

            // Flexbox
            'flex items-center justify-center',
            'shrink-0',

            // Color and Background
            'bg-transparent',
            'text-surface-500',

            // Size
            'w-6',

            // Shape
            'rounded-tr-md',
            'rounded-br-md',
          ],
        },
        panel: {
          class: [
            // Position
            'absolute top-0 left-0',
            'mt-2',

            'max-w-[30vw]',

            // Shape
            'border-0',
            'rounded-lg',
            'shadow-panel',

            // Color
            'bg-surface-0',
            'text-surface-800',
          ],
        },
        header: {
          class: [
            'flex items-center self-stretch justify-between gap-2',

            // Spacing
            'py-3 px-4',
            'm-0',

            //Shape
            'border-[0.5px] border-b-0',
            'rounded-tl-md',
            'rounded-tr-md',

            // Color
            'text-surface-700',
            'bg-primary-400',
            'border-grayscale-900',
          ],
        },
        headerCheckboxContainer: {
          class: [
            'relative',

            // Alignment
            'inline-flex',
            'align-bottom',

            // Size
            'w-4',
            'h-4',

            // Spacing
            'mr-2',

            // Misc
            'cursor-default',
            'select-none',
          ],
        },
        headerCheckbox: {
          ...checkbox,
          root: {
            class: [
              '!mx-auto',
              ...checkbox.root.class,
              '[&:not(:has(svg))_[data-pc-section=box]]:!bg-primary-400',
              '[&:not(:has(svg))_[data-pc-section=box]]:!border-white',
            ],
          },
        },
        itemCheckbox: checkbox,
        closeButton: {
          class: [
            'hidden',
          ],
        },
        closeButtonIcon: {
          class: [
            // Display
            'inline-block',

            // Size
            'w-3',
            'h-3',
          ],
        },
        wrapper: {
          class: [
            // Sizing
            'max-h-[15rem]',

            // Shape
            'border-[0.5px]',

            // Color
            'bg-white',
            'border-grayscale-900',

            // Misc
            'overflow-auto',
            'rounded-br-lg',
            'rounded-bl-lg',
          ],
        },
        list: {
          class: 'list-none m-0',
        },
        item: ({ context }) => ({
          class: [
            // Font
            'text-xs font-normal',

            // Flexbox
            'flex items-center gap-2',

            // Position
            'relative',

            // Shape
            'border-0',
            'rounded-none',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            { 'bg-primary-50': context.selected },
            {
              'text-surface-700':
                !context.focused && !context.selected && !context.disabled,
            },
            {
              'text-surface-600':
                !context.focused && !context.selected && context.disabled,
            },
            {
              'bg-surface-200 text-surface-700':
                context.focused && !context.selected,
            },
            {
              'bg-primary-bg-weak text-primary':
                context.focused && context.selected,
            },
            {
              'text-surface-700':
                !context.focused && context.selected,
            },

            //States
            'hover:bg-primary-bg-weak hover:text-primary',
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500',

            // Misc
            { 'pointer-events-none cursor-default': context.disabled },
            { 'cursor-pointer': !context.disabled },
            'overflow-hidden',
            'whitespace-nowrap',
          ],
        }),
        option: {
          class: 'overflow-hidden text-ellipsis w-max',
        },
        itemgroup: {
          class: [
            //Font
            'font-bold',
            'sm:text-sm',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            'text-surface-800',
            'bg-surface-0',

            // Misc
            'cursor-auto',
          ],
        },
        filtercontainer: {
          class: [
            'flex flex-row-reverse items-center relative w-full gap-2',

            // Font
            'text-[0.7rem]',
            'leading-4',

            // Sizing
            'w-full',

            //Color
            'text-grayscale-900',
            'bg-primary-400',

            // Shape
            'border-b border-primary-100',
          ],
        },
        filterinput: {
          class: [
            'w-full',

            'bg-primary-400',
            'text-white',
            'placeholder:text-primary-100',

            // Misc
            'appearance-none',

            // State
            'focus-visible:outline-none',
          ],
        },
        filtericon: {
          class: ['absolute', 'top-1/2 right-3', '-mt-2'],
        },
        clearicon: {
          class: [
            // Color
            'text-surface-500',

            // Position
            'absolute',
            'top-1/2',
            'right-12',

            // Spacing
            '-mt-2',
          ],
        },
        emptymessage: {
          class: [
            // Font
            'leading-none',
            'text-xs',

            // Spacing
            'py-2 px-4',

            // Color
            'text-grayscale-800',
            'bg-transparent',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var dropdown = {
        root: ({ props, state }) => ({
          class: [
            // Display and Position
            'inline-flex items-center',
            'relative',

            // Shape
            'w-full',
            'rounded-md',

            // Misc
            'cursor-default',
            'select-none',
            { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled },
          ],
        }),
        input: ({ props }) => ({
          class: [
            //Font
            'text-xs  font-normal',
            {
              '!text-general-200': props.modelValue == null,
              'text-grayscale-900': props.modelValue != null,
            },

            // Display
            'block',
            'flex-auto',

            // Color and Background
            'bg-transparent',
            'border-0',
            'placeholder:text-general-200',

            // Sizing and Spacing
            'h-max',
            'py-[5px] px-3 pr-0',
            { 'pr-7': props.showClear },

            //Shape
            'rounded-none',

            // Transitions
            'transition',
            'duration-200',

            // States
            'focus:outline-none focus:shadow-none',

            // Misc
            'relative',
            'cursor-pointer',
            'overflow-hidden overflow-ellipsis',
            'whitespace-nowrap',
            'appearance-none',
          ],
        }),
        trigger: {
          class: [
            //Font
            // 'sm:text-sm',

            // Spacing
            'mr-1',

            // Flexbox
            'flex items-center justify-center',
            'shrink-0',

            // Color and Background
            'bg-transparent',
            'text-general-200',

            // Size
            'w-6 h-full',

            // Shape
            'rounded-tr-md',
            'rounded-br-md',
          ],
        },
        panel: {
          class: [
            // Position
            'absolute top-0 left-0 !z-[9999]',
            'mt-2',

            'max-w-[30vw]',

            // Shape
            'border-0',
            'rounded-lg',
            'shadow-panel',

            // Color
            'bg-surface-0',
            'text-surface-800',
          ],
        },
        wrapper: ({ props }) => ({
          class: [
            // Sizing
            'max-h-[15rem]',

            // Shape
            'border-[0.5px]',

            // Color
            'bg-white',
            'border-grayscale-900',

            // Misc
            'overflow-auto',
            {
              'rounded-lg': !props.filter,
              'rounded-br-lg rounded-bl-lg': props.filter,
            },
          ],
        }),
        list: {
          class: 'list-none m-0',
        },
        item: ({ context }) => ({
          class: [
            // Font
            'text-xs font-normal',
            'w-full overflow-hidden text-ellipsis',

            // Position
            'relative',

            // Shape
            'border-0',
            'rounded-none',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            { 'bg-primary-50': context.selected },
            {
              'text-surface-700':
                !context.focused && !context.selected && !context.disabled,
            },
            {
              'text-surface-600':
                !context.focused && !context.selected && context.disabled,
            },
            {
              'bg-surface-200 text-surface-700': context.focused && !context.selected,
            },
            {
              'bg-primary-bg-weak text-primary': context.focused && context.selected,
            },
            {
              'text-surface-700': !context.focused && context.selected,
            },

            //States
            'hover:bg-primary-bg-weak hover:text-primary',
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-primary-500',

            // Misc
            { 'pointer-events-none cursor-default': context.disabled },
            { 'cursor-pointer': !context.disabled },
            'overflow-hidden',
            'whitespace-nowrap',
          ],
        }),
        itemgroup: {
          class: [
            //Font
            'font-bold',
            'sm:text-sm',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            'text-surface-800',
            'bg-surface-0',

            // Misc
            'cursor-auto',
          ],
        },
        emptymessage: {
          class: [
            // Font
            'leading-none text-xs',

            // Spacing
            'py-2 px-4',

            // Color
            'text-surface-800',
            'bg-transparent',
          ],
        },
        header: multiselect?.header,
        filtercontainer: multiselect?.filtercontainer,
        filterinput: multiselect?.filterinput,
        filtericon: multiselect?.filtericon,
        clearicon: {
          class: [
            // Color
            'text-general-200',

            // Position
            'absolute',
            'top-1/2',
            'right-12',

            // Spacing
            '-mt-2',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var fieldset = {
        root: {
          class: [
            'block',

            // Spacing
            'px-5 md:px-6 py-5',

            // Shape
            'rounded-md rounded-lg',

            // Color
            'bg-surface-0',
            'text-surface-700/80',
            'ring-1 ring-inset ring-surface-300',
          ],
        },
        legend: ({ props }) => ({
          class: [
            // Font
            'font-medium',
            'leading-none',

            //Spacing
            { 'p-0': props.toggleable, 'px-3 py-1.5': !props.toggleable },

            // Shape
            'rounded-md',

            // Color
            'text-surface-700/80',

            'bg-surface-0',

            // Transition
            'transition-none',

            // States
            { '': props.toggleable },
            {
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-600 ring-inset':
                props.toggleable,
            },
          ],
        }),
        toggler: ({ props }) => ({
          class: [
            // Alignments
            'flex items-center justify-center',
            'relative',

            //Spacing
            { 'px-3 py-1.5': props.toggleable },

            // Shape
            { 'rounded-md': props.toggleable },

            // Color
            {
              'text-surface-700 hover:text-surface-900 hover:text-surface-900':
                props.toggleable,
            },

            // States
            {
              'hover:text-surface-900': props.toggleable,
            },
            {
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-600':
                props.toggleable,
            },

            // Misc
            {
              'transition-none cursor-pointer overflow-hidden select-none':
                props.toggleable,
            },
          ],
        }),
        togglerIcon: {
          class: 'mr-2 inline-block',
        },
        legendTitle: {
          class: 'flex items-center justify-center leading-none',
        },
        content: {
          class: 'p-0',
        },
        transition: {
          enterFromClass: 'max-h-0',
          enterActiveClass:
            'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
          enterToClass: 'max-h-[1000px]',
          leaveFromClass: 'max-h-[1000px]',
          leaveActiveClass:
            'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
          leaveToClass: 'max-h-0',
        },
      };

      var form = {
        // :class="['ts-form', { 'sticky-buttons': props.stickyButtons }]"
        'root': () => ({
          'class': [
            'flex flex-col gap-3 text-general-900 text-[0.75rem] font-medium leading-[16.8px]',
          ],
          'data-wv-section': 'root',
        }),
        'fields': {
          class: ['grid gap-y-3 gap-x-6'],
        },
        'fields-wrapper': {
          'class': ['grid gap-y-3 gap-x-6'],
          'data-wv-section': 'fields',
        },
        'footer': {
          class: ['flex flex-col self-end gap-3'],
        },
        'action-buttons': {
          'class': ['relative flex flex-col items-end justify-end gap-1'],
          'data-wv-section': 'action-buttons',
        },
        'button-wrapper': {
          'class': ['flex items-end justify-end gap-1'],
          'data-wv-section': 'button-wrapper',
        },
        'cancel-button': {
          'data-wv-section': 'cancel-button',
        },
        'clear-button': {
          'data-wv-section': 'clear-button',
        },
        'submit-button': {
          'data-wv-section': 'submit-button',
        },
        'staycheckbox': {
          class: ['cursor-pointer flex gap-2 w-max ml-auto items-center justify-end'],
        },
        'validator-message': {
          class: ['static w-max transform-none'],
        },
      };

      const ringClass = [
        {
          'ring-1 p-[1px]': navigator.userAgent.includes('Firefox'),
          'ring-[0.5px] p-[0.5px]': !navigator.userAgent.includes('Firefox'),
        },
        'ring-inset',
        'ring-general-400',
      ];

      var global = {
        css: `
    *[data-pd-ripple="true"]{
        overflow: hidden;
        position: relative;
    }
    span[data-p-ink-active="true"]{
        animation: ripple 0.4s linear;
    }
    @keyframes ripple {
        100% {
            opacity: 0;
            transform: scale(2.5);
        }
    }

    .progress-spinner-circle {
        stroke-dasharray: 89, 400;
        stroke-dashoffset: 0;
        animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color 6s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes p-progress-spinner-dash{
        0% {
            stroke-dasharray: 1, 400;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 89, 400;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 400;
            stroke-dashoffset: -124px;
        }
    }
    @keyframes p-progress-spinner-color {
        100%, 0% {
            stroke: #ff5757;
        }
        40% {
            stroke: #696cff;
        }
        66% {
            stroke: #1ea97c;
        }
        80%, 90% {
            stroke: #cc8925;
        }
    }

    .progressbar-value-animate::after {
        will-change: left, right;
        animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }
    .progressbar-value-animate::before {
        will-change: left, right;
        animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    @keyframes p-progressbar-indeterminate-anim {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }
`,
      };

      var image = {
        root: ({ props }) => ({
          class: [
            'relative inline-block',
            'shrink-0',
            { 'rounded-full': props.rounded },
            { 'rounded-lg': !props.rounded },
          ],
        }),
        image: ({ props }) => ({
          class: [
            'w-full h-full object-cover',
            { 'rounded-full': props.rounded },
            { 'rounded-lg': !props.rounded },
          ],
        }),
        button: {
          class: [
            'flex items-center justify-center',
            'absolute',
            'w-full h-full',
            'inset-0 opacity-0 transition-opacity duration-300',
            'bg-transparent text-white',
            'hover:cursor-pointer hover:bg-grayscale-900 hover:opacity-50',
          ],
          style: 'border-radius: inherit',
        },
        icon: {
          class: 'w-6 h-6',
        },
        mask: {
          class: [
            'flex items-center justify-center',
            'fixed top-0 left-0',
            'w-full h-full',
            'bg-grayscale-950/90',
          ],
        },
        toolbar: {
          class: ['flex', 'absolute top-0 right-0', 'p-4'],
        },
        rotaterightbutton: {
          class: [
            'z-20',
            'flex justify-center items-center',
            'w-6 h-6',
            'mr-2',
            'rounded-full',
            'text-white bg-transparent',
            'hover:text-white hover:bg-white/10',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
            'transition duration-200 ease-in-out',
          ],
        },
        rotaterighticon: {
          class: 'w-4 h-4',
        },
        rotateleftbutton: {
          class: [
            'z-20',
            'flex justify-center items-center',
            'w-6 h-6',
            'mr-2',
            'rounded-full',
            'text-white bg-transparent',
            'hover:text-white hover:bg-white/10',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
            'transition duration-200 ease-in-out',
          ],
        },
        rotatelefticon: {
          class: 'w-4 h-4',
        },
        zoomoutbutton: {
          class: [
            'z-20',
            'flex justify-center items-center',
            'w-6 h-6',
            'mr-2',
            'rounded-full',
            'text-white bg-transparent',
            'hover:text-white hover:bg-white/10',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
            'transition duration-200 ease-in-out',
          ],
        },
        zoomouticon: {
          class: 'w-4 h-4',
        },
        zoominbutton: {
          class: [
            'z-20',
            'flex justify-center items-center',
            'w-6 h-6',
            'mr-2',
            'rounded-full',
            'text-white bg-transparent',
            'hover:text-white hover:bg-white/10',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
            'transition duration-200 ease-in-out',
          ],
        },
        zoominicon: {
          class: 'w-4 h-4',
        },
        closebutton: {
          class: [
            'z-20',
            'flex justify-center items-center',
            'w-6 h-6',
            'mr-2',
            'rounded-full',
            'text-white bg-transparent',
            'hover:text-white hover:bg-white/10',
            'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
            'transition duration-200 ease-in-out',
          ],
        },
        closeicon: {
          class: 'w-4 h-4',
        },
        transition: {
          enterFromClass: 'opacity-0 scale-75',
          enterActiveClass: 'transition-all duration-150 ease-in-out',
          leaveActiveClass: 'transition-all duration-150 ease-in',
          leaveToClass: 'opacity-0 scale-75',
        },
      };

      var inlinemessage = {
        root: ({ props }) => ({
          class: [
            'rounded-md p-3',
            'w-full',
            {
              'bg-success-50 text-success-500': props.severity == 'success',
              'bg-danger-50 text-danger-500': props.severity == 'error',
              'bg-grayscale-50 text-general-800': props.severity == 'secondary',
            },
          ],
        }),
        icon: {
          class: 'hidden',
        },
        text: {
          class: [
            // Layout
            'w-full flex gap-3 flex-col',

            // Font and Text
            'text-[12px] leading-[normal] tracking-[0.24px]',
            'font-medium',
          ],
        },
      };

      var inputtext = {
        root: ({ props, context, parent }) => ({
          class: [
            'px-3 py-1 w-full bg-transparent',

            // Size
            'h-full',
            {
              'max-h-[43px]': parent.instance?.placeholder?.includes('password'),
            },

            // Font
            'text-grayscale-900 text-xs font-normal',
            'placeholder:text-general-200',
            'placeholder:!font-normal',

            'appearance-none',

            // Interactions
            {
              'outline-none': !context.disabled,
              '!text-general-200 select-none pointer-events-none cursor-default':
                context.disabled,
            },
          ],
        }),
      };

      /**
       * To set different ring width for Firefox Browser
       * Also for InputGroupAddon
       */
      const isFirefoxBased$1 = navigator.userAgent.includes('Firefox');

      var inputbadge = {
        root: ({ context }) => ({
          class: [
            'flex flex-wrap gap-x-0.5 gap-y-[5px] items-center',
            'min-h-[26px] h-fit w-full',
            'ring-inset rounded px-[12px] py-[2px]',
            {
              'ring-[1px]': isFirefoxBased$1,
              'ring-[0.5px]': !isFirefoxBased$1,
            },
            { '!ring-danger-500 !dark:ring-danger-500': context.invalidInput },
            { '!ring-general-200 !dark:ring-general-200': !context.invalidInput },
            { 'pointer-events-none': context.disabled },
          ],
        }),
        input: ({ context, parent, props }) => ({
          class: [
            ...inputtext.root({
              props,
              context,
              parent,
            }).class,
            'outline-none !w-full !bg-transparent !p-0 !shadow-none h-max !leading-4 min-w-[70px]',
            { 'placeholder:text-transparent': context.value?.length },
            'focus:placeholder:text-general-200',
          ],
        }),
        erroricon: {
          class: ['mr-1 text-warning-600 text-base'],
        },
        erroroverlaypanel: {
          class: ['px-3 py-1 text-[12px] text-general-800'],
        },
      };

      /**
       * To set different ring width for Firefox Browser
       * Also for InputGroupAddon
       */
      const isFirefoxBased = navigator.userAgent.includes('Firefox');

      var inputgroup = {
        root: ({ props }) => ({
          'class': [
            {
              '!ring-danger-500 [&_.inputgroupaddon]:ring-danger-500':
                props.invalid && props.ring !== 'none',
              '[&_.inputgroupaddon]:ring-general-400':
                !props.invalid && props.ring !== 'none',
              '!bg-general-50 ': props.disabled,
            },
            {
              '[&:has(:focus)]:!ring-primary-400 [&:has(:focus)_.inputgroupaddon]:!ring-primary-400':
                props.ring !== 'none',
            },
            {
              'ring-[1px] [&_.inputgroupaddon]:ring-[1px]':
                isFirefoxBased && props.ring !== 'none',
              'ring-[0.5px] [&_.inputgroupaddon]:ring-[0.5px]':
                !isFirefoxBased && props.ring !== 'none',
            },

            '[&_.inputgroupaddon[data-addon-variant=plain]]:!ring-[0px]',

            '[&:has(input)]:cursor-text',
            'h-[30px]',
            'flex items-stretch',
            'w-full',
            'ring-inset ring-general-400 rounded',
            'bg-white',
            props.class,
          ],
          'data-wv-section': 'inputgroup',
        }),
      };

      var inputgroupaddon = {
        root: ({ props }) => ({
          'class': [
            'inputgroupaddon', // Removing this class will causes bug style.
            // Flex
            'inline-flex items-center justify-center',

            // Shape
            'first:rounded-tl first:rounded-bl',
            'last:rounded-tr last:rounded-br',
            'ring-inset',
            // 'border-y',
            // 'border-l',

            // Space
            'px-3 gap-3',

            // Size
            'w-max',

            // Font
            'text-general-800',
            'text-xs font-medium leading-none tracking-tight',

            // Color
            { 'bg-general-50': props.addonVariant !== 'plain' },
            {
              '!text-general-200': props.disabled,
              '!px-2': props.showButtons,
            },
          ],
          'data-addon-variant': props.addonVariant, // Used in inputgroup to set styling
        }),
      };

      var inputmask = {
        root: ({ context }) => ({
          class: [
            // Font
            'font-sans leading-6',

            // Spacing
            'm-0 py-1.5 px-3 sm:text-sm',

            // Colors
            'text-surface-900',
            'placeholder:text-surface-400',
            'bg-surface-0',
            'ring-1 ring-inset ring-surface-300',
            'shadow-sm',

            // Shape
            'rounded-md',
            'appearance-none',

            // Interactions
            {
              'outline-none focus:ring-primary-500':
                !context.disabled,
              'opacity-60 select-none pointer-events-none cursor-default':
                context.disabled,
            },
          ],
        }),
      };

      var inputnumber = {
        root: ({ props, parent }) => ({
          class: [
            // Layout
            'flex items-center self-stretch shrink-0 grow',

            //Size
            'h-full',

            // Spacing
            'py-1 px-3',

            // Border
            'rounded',

            'appearance-none',
          ],
        }),
        input: {
          root: ({ parent }) => ({
            class: [
              'border-0 h-full w-full outline-none',
              'bg-inherit',
              'text-xs font-normal',
              'placeholder:text-general-200',
            ],
          }),
        },

        buttongroup: {
          class: ['flex'],
        },

        incrementbutton: {
          root: ({ parent }) => ({
            class: [],
          }),
          label: {
            class: 'h-0 w-0',
          },
        },
        decrementbutton: {
          root: ({ parent }) => ({
            class: [],
          }),
          label: {
            class: 'h-0 w-0',
          },
        },
      };

      var inputotp = {
        root: {
          class: [
            // Alignment
            'flex items-center',
            'gap-[6px]',
          ],
        },
        input: {
          root: ({ props, context, parent }) => ({
            class: [
              // Font
              'leading-none font-medium',

              // Flex & Alignment
              { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },
              'text-center',

              // Spacing
              'm-0',
              {
                'p-3': props.size == null,
              },

              // Size
              'w-[42px] h-[42px] text-base',

              // Shape
              { 'rounded-md': parent.instance.$name !== 'InputGroup' },
              {
                'first:rounded-l-md rounded-none last:rounded-r-md':
                  parent.instance.$name == 'InputGroup',
              },
              {
                'border-0 border-y border-l last:border-r':
                  parent.instance.$name == 'InputGroup',
              },
              {
                'first:ml-0 ml-[-1px]':
                  parent.instance.$name == 'InputGroup' && !props.showButtons,
              },

              // Colors
              'text-general-800',
              'placeholder:text-surface-400',
              'bg-white',
              'border',
              { 'border-general-200': !props.invalid },

              // Invalid State
              { 'border-danger-500': props.invalid },

              // States
              {
                // 'hover:border-primary-500': !context.disabled && !props.invalid,
                'focus:outline-none focus:outline-offset-0': !context.disabled,
                'opacity-60 select-none pointer-events-none cursor-default':
                  context.disabled,
              },

              // Filled State *for FloatLabel
              { filled: parent.instance?.$name == 'FloatLabel' && context.filled },

              // Misc
              'appearance-none',
              'transition-colors duration-200',
            ],
          }),
        },
      };

      const commonClasses = ({ isFirefoxBased, invalidInput }) => [
        { 'ring-[1px]': isFirefoxBased, 'ring-[0.5px]': !isFirefoxBased },
        { '!ring-danger-500': invalidInput },
        'ring-inset',
        'ring-general-400',
        '[&:has(:focus)]:bg-none',
        '[&:has(:focus)]:ring-primary-400',
        'bg-white',
        'z-[0] [&:has(:focus)]:z-[12]',
      ];

      var inputphonenumber = {
        dialcode: (ctx) => ({
          class: [...commonClasses(ctx), 'rounded-l rounded-r-none'],
        }),
        inputnumber: (ctx) => ({
          class: [...commonClasses(ctx), 'rounded-r rounded-l-none -ml-[1px]'],
        }),
      };

      var inputswitch = {
        root: ({ props }) => ({
          class: [
            // Alignments
            'inline-flex relative align-middle',
            'shrink-0',

            // Shape
            'rounded-[60px]',

            // Size
            'h-4 w-8',

            // Misc
            'cursor-pointer',

            // States
            {
              'select-none pointer-events-none cursor-default': props.disabled,
            },
          ],
        }),
        slider: ({ props }) => ({
          class: [
            // Position
            'absolute top-0 left-0 right-0 bottom-0',

            // Shape
            'rounded-[60px]',

            // Before:
            'before:absolute before:top-1/2',
            'before:-mt-[6px] before:ml-0.5',
            'before:h-3 before:w-3',
            'before:rounded-full',
            'before:duration-200 before:transition before:ease-in-out',
            'before:shadow',
            {
              'before:bg-white before:bg-white':
                props.modelValue == props.trueValue,
              'before:bg-primary-200':
                props.modelValue !== props.trueValue && !props.disabled,
              'before:bg-general-50':
                props.modelValue !== props.trueValue && props.disabled,
            },
            {
              'before:transform before:translate-x-3.5':
                props.modelValue == props.trueValue,
            },

            // Colors
            'border',
            {
              'border-primary-200':
                props.modelValue !== props.trueValue && !props.disabled,
              'border-transparent': props.modelValue == props.trueValue,
            },
            {
              'bg-white':
                props.modelValue !== props.trueValue && !props.disabled,
              'bg-primary-500':
                props.modelValue == props.trueValue && !props.disabled,
              'bg-primary-100':
                props.modelValue == props.trueValue && props.disabled,
              'bg-general-100':
                props.modelValue !== props.trueValue && props.disabled,
            },

            // States
            'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500',

            // Transition
            'transition-colors duration-200',

            // Misc
            'cursor-pointer',
          ],
        }),
        input: ({ props }) => ({
          class: [
            'peer',

            // Size
            'w-full ',
            'h-full',

            // Position
            'absolute',
            'top-0 left-0',
            'z-10',

            // Spacing
            'p-0',
            'm-0',

            // Shape
            'rounded',
            'border',

            // Shape
            'opacity-0',
            'rounded-md',
            'outline-none',
            'border-2 border-surface-300',

            // Misc
            'appearance-none',
            {
              'cursor-default': props.disabled,
              'cursor-pointer': !props.disabled,
            },
          ],
        }),
      };

      var knob = {
        root: ({ props }) => ({
          class: [
            // Misc
            {
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        range: {
          class: [
            // Stroke
            'stroke-current',

            // Color
            'stroke-surface-200',

            // Fill
            'fill-none',

            // Transition
            'transition duration-100 ease-in',
          ],
        },
        value: {
          class: [
            // Animation
            'animate-dash-frame',

            // Color
            'stroke-primary-500',

            // Fill
            'fill-none',
          ],
        },
        label: {
          class: [
            // Text Style
            'text-center text-xl',

            // Color
            'fill-surface-600',
          ],
        },
      };

      var listbox = {
        root: {
          class: [
            // Sizing and Shape
            'min-w-[12rem]',
            'rounded-md',
            // Spacing

            // Colors
            'bg-surface-0',
            'text-surface-700/80',
            'ring-1 ring-surface-200',
          ],
        },
        wrapper: {
          class: [
            // Overflow
            'overflow-auto',
          ],
        },
        list: {
          class: 'py-1 list-none m-0',
        },
        item: ({ context }) => ({
          class: [
            // Font
            'sm:text-sm',
            'leading-none',
            { 'font-normal': !context.selected, 'font-bold': context.selected },

            // Position
            'relative',

            // Shape
            'border-0',
            'rounded-none',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            {
              'text-surface-700/80':
                !context.focused && !context.selected,
            },
            {
              'bg-surface-200 text-surface-700/80':
                context.focused && !context.selected,
            },
            {
              'bg-primary-500 text-white':
                context.focused && context.selected,
            },
            {
              'bg-surface-100 text-primary-500':
                !context.focused && context.selected,
            },

            //States
            'hover:bg-primary-500 hover:text-white',
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500',

            // Misc
            'cursor-pointer',
            'overflow-hidden',
            'whitespace-nowrap',
          ],
        }),
        itemgroup: {
          class: [
            //Font
            'font-bold',
            'sm:text-sm',

            // Spacing
            'm-0',
            'py-2 px-4',

            // Color
            'text-surface-800/80',
            'bg-surface-0',

            // Misc
            'cursor-auto',
          ],
        },
        header: {
          class: [
            // Spacing
            'p-0',
            'm-0',

            //Shape
            'rounded-tl-md',
            'rounded-tr-md',
            'border-b border-surface-200',

            // Color
            'text-surface-700/80',
            'bg-surface-100',
          ],
        },
        filtercontainer: {
          class: 'relative',
        },
        filterinput: {
          class: [
            // Font
            'font-sans',
            'leading-none',
            'sm:text-sm',

            // Sizing
            'py-1.5 px-3',
            'pr-7',
            '-mr-7',
            'w-full',

            //Color
            'text-surface-700/80',
            'bg-surface-0',
            'placeholder:text-surface-400',

            // Shape
            'border-0',
            'rounded-tl-md',
            'rounded-tr-md',
            'appearance-none',

            // States
            'focus:ring-2 focus:ring-inset focus:outline-none focus:outline-offset-0',
            'focus:ring-primary-600',

            // Misc
            'appearance-none',
          ],
        },
        filtericon: {
          class: ['absolute', 'top-1/2 right-3', '-mt-2'],
        },
        emptymessage: {
          class: [
            // Font
            'leading-none',
            'sm:text-sm',

            // Spacing
            'py-2 px-4',

            // Color
            'text-surface-800/80',
            'bg-transparent',
          ],
        },
      };

      var megamenu = {
        root: ({ props }) => ({
          class: [
            'relative',

            // Flexbox
            'flex',

            // Spacing
            'min-h-[4rem]',

            // Shape
            'rounded-md',
            'shadow-md',

            // Color
            'bg-surface-0',
            'ring-1 ring-inset ring-surface-100 ring-offset-0',

            {
              'items-center px-2 sm:px-6': props.orientation == 'horizontal',
              'flex-col justify-center sm:justify-start sm:w-48 px-2':
                props.orientation !== 'horizontal',
            },
          ],
        }),
        menu: ({ props }) => ({
          class: [
            // Flexbox
            'sm:flex sm:row-gap-2 sm:col-gap-4',
            'items-center',
            'flex-wrap',
            'flex-col sm:flex-row',
            { hidden: !props?.mobileActive, flex: props?.mobileActive },

            // Position
            'absolute sm:relative',
            'top-full left-0',
            'sm:top-auto sm:left-auto',

            // Size
            'w-full sm:w-auto',

            // Spacing
            'm-0 ',
            'py-2 px-1.5 sm:py-0 sm:p-0 sm:py-1.5',
            'list-none',

            // Shape
            'shadow-md sm:shadow-none',
            'border-0',
            'sm:rounded-none rounded-md',

            // Color
            'bg-surface-0 sm:bg-transparent',

            // Misc
            'outline-none',
          ],
        }),
        menuitem: ({ props }) => ({
          class: [
            'sm:relative static',
            {
              'sm:w-auto w-full': props.horizontal,
              'w-full': !props.horizontal,
            },
          ],
        }),
        content: ({ props, context }) => ({
          class: [
            // Shape
            { 'rounded-md': props.level < 1 },

            //  Colors
            {
              'text-surface-500/70':
                !context.focused && !context.active,
              'text-surface-500/70 bg-surface-200':
                context.focused && !context.active,
              'text-surface-900/80 bg-surface-50':
                context.focused && context.active,
              'text-surface-900/80 bg-surface-50':
                !context.focused && context.active,
            },

            // Hover States
            {
              'hover:bg-surface-50': !context.active,
              'hover:bg-surface-100 text-surface-900/80':
                context.active,
            },

            // Transitions
            'transition-all',
            'duration-200',
          ],
        }),
        action: {
          class: [
            'relative',

            // Font
            'sm:text-sm font-medium',

            // Flexbox
            'flex',
            'items-center',

            // Spacing
            'py-2',
            'px-3',
            'my-1 sm:my-0',

            // Misc
            'select-none',
            'cursor-pointer',
            'no-underline ',
            'overflow-hidden',
          ],
        },
        icon: {
          class: 'mr-2',
        },
        submenuicon: ({ props }) => ({
          class: [
            {
              'ml-auto sm:ml-2': props.horizontal,
              'ml-auto': !props.horizontal,
            },
          ],
        }),
        panel: ({ props }) => ({
          class: [
            // Size
            'w-auto',

            // Spacing
            'py-1',
            'm-0 mx-2',

            // Shape
            'shadow-none sm:shadow-md',
            'border-0',

            // Color
            'bg-surface-0',

            // Position
            'static sm:absolute',
            'z-10',
            {
              'sm:left-full top-0': !props.horizontal,
            },
          ],
        }),
        grid: {
          class: 'flex flex-wrap sm:flex-nowrap',
        },
        column: {
          class: 'w-full sm:w-1/2',
        },
        submenu: {
          class: ['m-0 list-none', 'py-1 px-2 w-full sm:min-w-[14rem]'],
        },
        submenuheader: {
          class: [
            'font-medium',
            'sm:text-md',

            // Spacing
            'py-2.5 px-2',
            'm-0',

            // Shape
            'border-b border-surface-200',

            // Color
            'text-surface-700/80',
            'bg-surface-0',
          ],
        },
        separator: {
          class: 'border-t border-surface-200 my-1',
        },
        menubutton: {
          class: [
            // Flexbox
            'flex sm:hidden',
            'items-center justify-center',

            // Size
            'w-8',
            'h-8',

            // Shape
            'rounded-full',
            // Color
            'text-surface-500/80',

            // States
            'hover:text-surface-600',
            'hover:bg-surface-50',
            'focus:outline-none focus:outline-offset-0',
            'focus:ring-2 focus:ring-inset focus:ring-primary-500',

            // Transitions
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer',
            'no-underline',
          ],
        },
        end: {
          class: 'ml-auto self-center',
        },
      };

      var menu = {
        root: {
          class: [
            // Position

            // Transition
            'transition-transform',
            'z-[3299]',

            // Sizing and Shape
            'w-max min-w-52',
            'min-w-[196px]',
            'max-h-[90vh] overflow-y-auto',
            'rounded-md',
            'border-[0.5px] border-solid border-grayscale-900',
            'max-w-[400px]',

            'translate-y-1',
            // Spacing
            // 'p-1.5',

            'shadow',

            'overflow-hidden',

            // Colors
            'bg-white',
            'text-general-800',
          ],
        },
        menu: {
          class: [
            // Spacings and Shape
            'list-none',
            'm-0',
            'p-0',
            'outline-none',
            'w-full',
          ],
        },
        start: {
          class: ['flex justify-end', 'py-[3px] px-4'],
        },
        menuitem: {
          class: [
            // Space
            // Misc
            'cursor-pointer',
          ],
        },
        content: ({ context }) => ({
          class: [
            // Space
            // 'px-2',

            'w-full',

            // Shape
            'rounded-md',

            // Colors
            'text-inherit',

            // Transitions
            'transition-shadow',
            'duration-200',
          ],
        }),
        action: {
          class: [
            'flex items-center justify-start gap-2',

            'px-4 py-[7px]',
            'hover:bg-primary-50/80',

            'text-xs',
            // 'relative',

            // Font
            // 'font-semibold',

            // Flexbox
            // 'flex',
            // 'items-center',

            // Spacing
            // 'py-2',
            // 'px-3',

            // Misc
            'no-underline',
            'overflow-hidden',
            'cursor-pointer',
            'select-none',
          ],
        },
        icon: {
          class: [
            // Spacing
            'w-4 h-4',
          ],
        },
        label: ({ props }) => ({
          class: ['text-inherit', { '!text-danger-500': props.danger }],
        }),
        submenuheader: {
          class: [
            // Font
            'text-inherit',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var menubar = {
        root: {
          class: [
            'relative',

            // Flexbox
            'flex',
            'items-center',
            'gap-6',

            // Spacing
            'px-6',
            'min-h-[4rem]',

            // Shape
            'rounded-md',
            'shadow-md',

            // Color
            'bg-surface-0',
            'ring-1 ring-inset ring-surface-100 ring-offset-0',
          ],
        },
        menu: ({ props }) => ({
          class: [
            // Flexbox
            'sm:flex sm:row-gap-2 sm:col-gap-4',
            'items-center',
            'flex-wrap',
            'flex-col sm:flex-row',
            { hidden: !props?.mobileActive, flex: props?.mobileActive },

            // Position
            'absolute sm:relative',
            'top-full left-0',
            'sm:top-auto sm:left-auto',

            // Size
            'w-full sm:w-auto',

            // Spacing
            'm-0 ',
            'py-2 px-1.5 sm:py-0 sm:p-0 sm:py-1.5',
            'list-none',

            // Shape
            'shadow-md sm:shadow-none',
            'border-0',
            'sm:rounded-none rounded-md',

            // Color
            'bg-surface-0 sm:bg-transparent',

            // Misc
            'outline-none',
          ],
        }),
        menuitem: {
          class: 'sm:relative sm:w-auto w-full static',
        },
        content: ({ props, context }) => ({
          class: [
            // Shape
            { 'rounded-md': props.root },

            //  Colors
            {
              'text-surface-500/70':
                !context.focused && !context.active,
              'text-surface-500/70 bg-surface-200':
                context.focused && !context.active,
              'text-surface-900/80 bg-surface-50':
                !context.focused && context.active,
            },

            // Hover States
            {
              'hover:bg-surface-50': !context.active,
              'hover:bg-surface-100 text-surface-900/80':
                context.active,
            },

            // Transitions
            'transition-all',
            'duration-200',
          ],
        }),
        action: ({ context }) => ({
          class: [
            'relative',

            // Font
            'sm:text-sm font-medium',

            // Flexbox
            'flex',
            'items-center',

            // Spacing
            'py-2',
            'px-3',
            'my-1 sm:my-0',

            // Size
            {
              'pl-5 sm:pl-3': context.level === 1,
              'pl-7 sm:pl-3': context.level === 2,
            },

            // Misc
            'select-none',
            'cursor-pointer',
            'no-underline ',
            'overflow-hidden',
          ],
        }),
        icon: {
          class: 'mr-2',
        },
        submenuicon: ({ props }) => ({
          class: [
            {
              'ml-auto sm:ml-2': props.root,
              'ml-auto': !props.root,
            },
          ],
        }),
        submenu: ({ props }) => ({
          class: [
            // Size
            'w-full sm:w-48',

            // Spacing
            'py-1',
            'm-0 ',
            'list-none',

            // Shape
            'shadow-none sm:shadow-md',
            'border-0',

            // Position
            'static sm:absolute',
            'z-10',
            { 'sm:absolute sm:left-full sm:top-0': props.level > 1 },

            // Color
            'bg-surface-0',
          ],
        }),
        separator: {
          class: 'border-t border-surface-200 my-1',
        },
        button: {
          class: [
            // Flexbox
            'flex sm:hidden',
            'items-center justify-center',

            // Size
            'w-8',
            'h-8',

            // Shape
            'rounded-full',
            // Color
            'text-surface-500/80',

            // States
            'hover:text-surface-600',
            'hover:bg-surface-50',
            'focus:outline-none focus:outline-offset-0',
            'focus:ring-2 focus:ring-inset focus:ring-primary-500',

            // Transitions
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer',
            'no-underline',
          ],
        },
        end: {
          class: 'ml-auto self-center',
        },
      };

      var message = {
        root: ({ props }) => ({
          class: [
            // Spacing and Shape
            'my-2 mx-0',
            'rounded-md',
            'ring-1 ring-inset ring-surface-200',

            // Colors
            'bg-surface-0',

            {
              'text-blue-500': props.severity == 'info',
              'text-green-500': props.severity == 'success',
              'text-orange-500': props.severity == 'warn',
              'text-red-500': props.severity == 'error',
            },
          ],
        }),
        wrapper: {
          class: [
            // Flexbox
            'flex items-center',

            // Spacing
            'p-4',
          ],
        },
        icon: {
          class: [
            // Sizing and Spacing
            'w-5 h-5',
            'mr-3 shrink-0',
          ],
        },
        text: {
          class: [
            // Font and Text
            'text-sm leading-none',
            'font-medium',
          ],
        },
        button: ({ props }) => ({
          class: [
            // Flexbox
            'flex items-center justify-center',

            // Size
            'w-6 h-6',

            // Spacing and Misc
            'ml-auto relative',

            // Shape
            'rounded-full',

            // Colors
            'bg-transparent',
            'text-surface-700/80',

            // Transitions
            'transition duration-200 ease-in-out',

            // States
            'hover:bg-surface-100',
            'outline-none focus:ring-1 focus:ring-inset',
            'focus:ring-primary-500',

            // Misc
            'overflow-hidden',
          ],
        }),
        closeicon: {
          class: [
            // Sizing and Spacing
            'w-3 h-3',
            'shrink-0',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0',
          enterActiveClass: 'transition-opacity duration-300',
          leaveFromClass: 'max-h-40',
          leaveActiveClass: 'overflow-hidden transition-all duration-300 ease-in',
          leaveToClass: 'max-h-0 opacity-0 !m-0',
        },
      };

      var orderlist = {
        root: {
          class: [
            // Flexbox
            'flex',
          ],
        },
        controls: {
          class: [
            // Flexbox & Alignment
            'flex flex-col justify-center gap-2',

            // Spacing
            'p-3',
          ],
        },
        moveupbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        movedownbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        movetopbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        movebottombutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        container: {
          class: ['flex-auto'],
        },
        header: {
          class: [
            'font-semibold',

            // Shape
            'border-b rounded-t-md',

            // Spacing
            'py-3.5 px-3',

            // Color
            'text-surface-800/80',
            'bg-surface-0',
            'border-surface-200 border-b',
          ],
        },
        list: {
          class: [
            // Spacing
            'list-none m-0 p-0',

            // Size
            'min-h-[12rem] max-h-[24rem]',

            // Shape
            'rounded-b-md border-0',

            // Color
            'text-surface-600/80',
            'bg-surface-0',
            'border border-surface-200',

            // Spacing
            'py-3 px-0',

            // Focus & Outline
            'outline-none',

            // Misc
            'overflow-auto',
          ],
        },
        item: ({ context }) => ({
          class: [
            // Position
            'relative',

            // Spacing
            'py-3.5 px-3 m-0',

            // Shape
            'border-b last:border-b-0',

            // Transition
            'transition duration-200',

            // Color
            'text-surface-700/80',
            'border-surface-200',
            {
              'bg-surface-100':
                context.active && !context.focused,
            },
            {
              'bg-surface-200':
                context.active && context.focused,
            },
            {
              'bg-surface-50/70':
                !context.active && context.focused,
            },

            // State
            'hover:bg-surface-100',

            // Misc
            'cursor-pointer overflow-hidden',
          ],
        }),
      };

      var organizationchart = {
        table: {
          class: [
            // Spacing & Position
            'mx-auto my-0',

            // Table Style
            'border-spacing-0 border-separate',
          ],
        },
        cell: {
          class: [
            // Alignment
            'text-center align-top',

            // Spacing
            'py-0 px-3',
          ],
        },
        node: ({ context }) => ({
          class: [
            'relative inline-block',

            // Font
            'text-sm leading-none',

            // Spacing
            'px-5 py-3',

            // Shape
            'border',
            'rounded',

            // Color
            'border-surface-200',
            {
              'text-surface-600/80': !context?.selected,
              'bg-surface-0': !context?.selected,
              'text-surface-700': context?.selected,
              'bg-surface-50': context?.selected,
            },

            // States
            {
              'hover:bg-surface-100/40':
                context?.selectable,
            },

            { 'cursor-pointer': context?.selectable },
          ],
        }),
        linecell: {
          class: [
            // Alignment
            'text-center align-top',

            // Spacing
            'py-0 px-3',
          ],
        },
        linedown: {
          class: [
            // Spacing
            'mx-auto my-0',

            // Size
            'w-px h-[20px]',

            // Color
            'bg-surface-200',
          ],
        },
        lineleft: ({ context }) => ({
          class: [
            // Alignment
            'text-center align-top',

            // Spacing
            'py-0 px-3',

            // Shape
            'rounded-none border-r',
            { 'border-t': context.lineTop },

            // Color
            'border-surface-200',
          ],
        }),
        lineright: ({ context }) => ({
          class: [
            // Alignment
            'text-center align-top',

            // Spacing
            'py-0 px-3',

            // Shape
            'rounded-none',

            // Color
            {
              'border-t border-surface-200': context.lineTop,
            },
          ],
        }),
        nodecell: {
          class: 'text-center align-top py-0 px-3',
        },
        nodetoggler: {
          class: [
            // Position
            'absolute bottom-[-0.75rem] left-2/4 -ml-3',
            'z-20',

            // Flexbox
            'flex items-center justify-center',

            // Size
            'w-6 h-6',

            // Shape
            'rounded-full',

            // Color
            'bg-inherit text-inherit',

            // Focus
            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-500',

            // Misc
            'cursor-pointer no-underline select-none',
          ],
        },
        nodetogglericon: {
          class: [
            // Position
            'relative inline-block',

            // Size
            'w-4 h-4',
          ],
        },
      };

      var overlaypanel = {
        root: {
          class: [
            'h-max',
            // Shape
            'rounded-lg',
            'shadow-xl',
            'border-0',

            // Position
            'absolute left-0 top-0 mt-2',
            'z-40 transform origin-center',

            // Color
            'bg-white',
            'text-general-800',

            // Before: Triangle
            'before:absolute before:-top-2 before:ml-4 before:z-50',
            'before:w-0 before:h-0 before:shadow-xl',
            // 'before:border-transparent before:border-solid',
            // 'before:border-x-[0.5rem] before:border-b-[0.5rem]',
            // 'before:border-t-0 before:border-b-surface-0',
          ],
        },
        content: {
          class: 'items-center flex',
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var paginator = {
        root: {
          class: [
            // Flex & Alignment
            'flex items-center justify-start flex-wrap gap-0.5',

            'bg-white',
            'text-grayscale-900',
          ],
        },
        paginatorwrapper: {
          class: 'mt-[11px]',
        },
        pages: {
          class: 'flex gap-0.5',
        },
        firstpagebutton: ({ context }) => ({
          class: [
            'relative',

            // Font
            'text-2xl font-medium',

            // Flex & Alignment
            'inline-flex items-center justify-center',

            // Shape
            'border-t-2 border-transparent',

            // Size
            'w-6 h-6',

            // Color
            'text-general-300',

            // Transition
            'transition duration-200',

            // Misc
            'user-none overflow-hidden',
            { 'cursor-default pointer-events-none opacity-60': context.disabled },
          ],
        }),
        previouspagebutton: ({ context }) => ({
          class: [
            'relative',

            // Font
            'text-2xl font-medium',

            // Flex & Alignment
            'inline-flex items-center justify-center',

            // Shape
            'border-t-2 border-transparent',

            // Size
            'w-6 h-6',

            // Color
            'text-general-300',

            // Transition
            'transition duration-200',

            // Misc
            'user-none overflow-hidden',
            { 'cursor-default pointer-events-none opacity-60': context.disabled },
          ],
        }),
        nextpagebutton: ({ context }) => ({
          class: [
            'relative',

            // Font
            'text-2xl font-medium',

            // Flex & Alignment
            'inline-flex items-center justify-center',

            // Shape
            'border-t-2 border-transparent',

            // Size
            'w-6 h-6',

            // Color
            'text-general-300',

            // Transition
            'transition duration-200',

            // Misc
            'user-none overflow-hidden',
            { 'cursor-default pointer-events-none opacity-60': context.disabled },
          ],
        }),
        lastpagebutton: ({ context }) => ({
          class: [
            'relative',

            // Font
            'text-2xl font-medium',

            // Flex & Alignment
            'inline-flex items-center justify-center',

            // Shape
            'border-t-2 border-transparent',

            // Size
            'w-6 h-6',

            // Color
            'text-general-300',

            // Transition
            'transition duration-200',

            // Misc
            'user-none overflow-hidden',
            { 'cursor-default pointer-events-none opacity-60': context.disabled },
          ],
        }),
        pagebutton: ({ context }) => ({
          class: [
            'relative',

            // Font
            'text-xs font-normal',

            // Shape
            'rounded-full',

            // Size
            'w-6 h-6',

            // Color
            {
              'text-general-300 border-none hover:bg-grayscale-50': !context.active,
              'text-white bg-grayscale-900': context.active,
            },

            // State
            {
              'hover:border-surface-300':
                !context.disabled && !context.active,
              'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-500':
                !context.disabled,
            },

            // Transition
            'transition duration-200',

            // Misc
            'user-none overflow-hidden',
            { 'cursor-default pointer-events-none opacity-60': context.disabled },
          ],
        }),
        rowperpagedropdown: {
          root: ({ props, state }) => ({
            class: [
              // Display and Position
              'inline-flex items-center justify-center',
              'relative',

              // Size
              'w-max',
              'px-3 py-1',

              // Shape
              'rounded-[50px]',

              // Color and Background
              'bg-transparent',
              'border-[0.5px] border-primary-100',

              // Transitions
              'transition-all',
              'duration-200',

              // States
              {
                'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-600':
                  !state.focused,
              },

              // Misc
              'cursor-pointer',
              'select-none',
              {
                'opacity-60': props.disabled,
                'pointer-events-none': props.disabled,
                'cursor-default': props.disabled,
              },
            ],
          }),
          input: {
            class: [
              //Font
              'text-xs',

              // Display
              // 'block',
              // 'flex-auto',
              'flex items-center',

              // Color and Background
              'bg-transparent',
              'text-general-800',

              // Sizing and Spacing
              'w-max',
              // 'py-1.5 px-3 pr-0',

              //Shape
              'rounded-none',

              // Transitions
              'transition',
              'duration-200',

              // States
              'focus-visible:outline-none focus-visible:shadow-none',

              // Misc
              // 'relative',
              'cursor-pointer',
              'overflow-hidden overflow-ellipsis',
              'whitespace-nowrap',
              'appearance-none',
            ],
          },
          trigger: {
            class: [
              //Font
              'text-xs',

              // Flexbox
              'flex items-center justify-center',
              'shrink-0',

              // Size
              'w-2',
              'h-2',
              'ml-0.5',

              // Shape
              'rounded-tr-md',
              'rounded-br-md',
            ],
          },
          dropdownicon: {
            class: 'w-2 h-2',
          },
          panel: {
            class: [
              // Position
              'absolute top-0 left-0',
              'mt-1',

              // Shape
              'border-0',
              'rounded-md',
              // 'shadow-md',

              // Color
              'bg-white',
              'text-general-800',
              'ring-1 ring-inset ring-primary-100',
            ],
          },
          wrapper: {
            class: [
              // Sizing
              'max-h-[15rem]',

              // Misc
              'overflow-auto',
            ],
          },
          list: {
            class: 'py-0.5 list-none m-0',
          },
          item: ({ context }) => ({
            class: [
              // Font
              'text-xs',
              'leading-none',
              { 'font-normal': !context.selected, 'font-bold': context.selected },

              // Position
              'relative',

              // Shape
              'border-0',
              'rounded-none',

              // Spacing
              'm-0',
              'py-2 px-4',

              // Color
              // {
              //   'text-surface-700/80':
              //     !context.focused && !context.selected,
              // },
              // {
              //   'bg-white text-general-800':
              //     context.focused && context.selected,
              // },
              // {
              //   'bg-transparent text-general-800':
              //     !context.focused && context.selected,
              // },

              //States
              'hover:bg-primary-50',

              // Misc
              'cursor-pointer',
              'overflow-hidden',
              'whitespace-nowrap',
            ],
          }),
        },
        jumptopageinput: {
          root: {
            class: 'inline-flex mx-2',
          },
          input: {
            root: {
              class: [
                'relative',

                //Font
                'leading-6',
                'text-xs',
                'text-center',

                // Display
                'block',
                'flex-auto',

                // Color and Background
                'bg-transparent',
                'border-primary-100 border-[0.5px]',
                'text-general-800',

                // Sizing and Spacing
                'max-w-[3rem] w-fit',
                'px-3 py-1',

                //Shape
                'rounded-[50px] h-6',

                // Transitions
                'transition',
                'duration-200',

                // States
                'hover:ring-general-200',
                'focus-visible:outline-none focus-visible:shadow-none',
                // 'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-body',

                // Misc
                'cursor-pointer',
                'overflow-hidden overflow-ellipsis',
                'whitespace-nowrap',
                'appearance-none',
              ],
            },
          },
        },
        jumptopagedropdown: {
          root: ({ props, state }) => ({
            class: [
              // Display and Position
              'inline-flex',
              'relative',

              // Shape
              'h-8',
              'rounded-md',

              // Spacing
              'mx-2',

              // Color and Background
              'bg-transparent',

              // Transitions
              'transition-all',
              'duration-200',

              // States
              {
                'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-600':
                  !state.focused,
              },

              // Misc
              'cursor-pointer',
              'select-none',
              {
                'opacity-60': props.disabled,
                'pointer-events-none': props.disabled,
                'cursor-default': props.disabled,
              },
            ],
          }),
          input: {
            class: [
              //Font
              'font-sans',
              'leading-6',
              'text-xs',

              // Display
              'block',
              'flex-auto',
              'flex items-center',

              // Color and Background
              'bg-transparent',
              'border-0',
              'text-surface-800/80',

              // Sizing and Spacing
              'w-[1%]',
              'py-1.5 px-3 pr-0',

              //Shape
              'rounded-none',

              // Transitions
              'transition',
              'duration-200',

              // States
              'focus-visible:outline-none focus-visible:shadow-none',

              // Misc
              'relative',
              'cursor-pointer',
              'overflow-hidden overflow-ellipsis',
              'whitespace-nowrap',
              'appearance-none',
            ],
          },
          trigger: {
            class: [
              //Font
              'text-xs',

              // Flexbox
              'flex items-center justify-center',
              'shrink-0',

              // Color and Background
              'bg-transparent',
              'text-surface-500',

              // Size
              'w-12',
              'h-8',

              // Shape
              'rounded-tr-md',
              'rounded-br-md',
            ],
          },
          panel: {
            class: [
              // Position
              'absolute top-0 left-0',
              'mt-1',

              // Shape
              'border-0',
              'rounded-md',
              'shadow-md',

              // Color
              'bg-surface-0',
              'text-surface-800/80',
              'ring-1 ring-inset ring-surface-300',
            ],
          },
          wrapper: {
            class: [
              // Sizing
              'max-h-[15rem]',

              // Misc
              'overflow-auto',
            ],
          },
          list: {
            class: 'py-1 list-none m-0',
          },
          item: ({ context }) => ({
            class: [
              // Font
              'text-xs',
              'leading-none',
              { 'font-normal': !context.selected, 'font-bold': context.selected },

              // Position
              'relative',

              // Shape
              'border-0',
              'rounded-none',

              // Spacing
              'm-0',
              'py-2 px-4',

              // Color
              {
                'text-surface-700/80':
                  !context.focused && !context.selected,
              },
              {
                'bg-primary-500 text-white':
                  context.focused && context.selected,
              },
              {
                'bg-transparent text-surface-700/80':
                  !context.focused && context.selected,
              },

              //States
              'hover:bg-primary-500 hover:text-white',

              // Misc
              'cursor-pointer',
              'overflow-hidden',
              'whitespace-nowrap',
            ],
          }),
        },
        current: {
          class: ['text-xs font-normal leading-6 px-3'],
        },
      };

      var panel = {
        root: {
          class: 'shadow-md rounded-lg',
        },
        header: ({ props }) => ({
          class: [
            // Alignments
            'flex items-center justify-between',

            // Colors
            'text-surface-700/80',
            'bg-surface-0',
            'border-b border-surface-200',

            //Shape
            'rounded-tl-lg rounded-tr-lg',

            // Conditional Spacing
            {
              'px-5 md:px-6 py-5': !props.toggleable,
              'py-3 px-5 md:px-6': props.toggleable,
            },
          ],
        }),
        title: {
          class: 'leading-none font-medium',
        },
        toggler: {
          class: [
            // Alignments
            'inline-flex items-center justify-center',

            // Sized
            'w-8 h-8',

            //Shape
            'border-0 rounded-full',

            //Color
            'bg-transparent',
            'text-surface-600',

            // States
            'hover:text-surface-900',
            'hover:bg-surface-50',
            'focus:outline-none focus:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-600 focus-visible:ring-inset',

            // Transitions
            'transition duration-200 ease-in-out',

            // Misc
            'overflow-hidden relative no-underline',
          ],
        },
        togglerIcon: {
          class: 'inline-block',
        },
        content: {
          class: [
            // Spacing
            'py-6 px-5 md:px-6',

            // Shape
            'last:rounded-br-lg last:rounded-bl-lg',

            //Color
            'bg-surface-0',
            'text-surface-700/80',
          ],
        },
        footer: {
          class: [
            // Spacing
            'py-6 px-5 md:px-6',

            //Shape
            'rounded-bl-lg rounded-br-lg',

            // Color
            'bg-surface-0',
            'text-surface-600',
            'border-t border-surface-200',
          ],
        },
        transition: {
          enterFromClass: 'max-h-0',
          enterActiveClass:
            'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
          enterToClass: 'max-h-[1000px]',
          leaveFromClass: 'max-h-[1000px]',
          leaveActiveClass:
            'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
          leaveToClass: 'max-h-0',
        },
      };

      var panelmenu = {
        panel: {
          class: 'mb-1',
        },
        header: {
          class: [
            'rounded-md',
            'outline-none',
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-primary-400/50',
          ],
        },
        headercontent: ({ context, instance }) => ({
          class: [
            // Shape
            'rounded-t-md',
            {
              'rounded-br-md rounded-bl-md':
                !context.active || instance.activeItem?.items === undefined,
              'rounded-br-0 rounded-bl-0':
                context.active && instance.activeItem?.items !== undefined,
            },

            // Color
            'border border-surface-200',
            'bg-surface-0',
            'text-surface-600/80',
            { 'text-surface-900 ark:text-surface-0': context.active },

            // Transition
            'transition duration-200 ease-in-out',
            'transition-shadow duration-200',
          ],
        }),
        headeraction: {
          class: [
            'relative',

            // Font
            'font-semibold',
            'text-sm',
            'leading-none',

            // Flex & Alignments
            'flex items-center',

            // Spacing
            'px-3 py-2',

            // Misc
            'select-none cursor-pointer no-underline',
          ],
        },
        headerlabel: {
          class: 'leading-none',
        },
        headerIcon: {
          class: 'mr-2 text-sm',
        },
        submenuicon: {
          class: 'ml-auto order-last text-sm',
        },
        menucontent: {
          class: [
            // Spacing
            'py-1',

            // Shape
            'border border-t-0',
            'rounded-t-none rounded-br-md rounded-bl-md',

            // Color
            'text-surface-700/80',
            'bg-surface-0',
            'border-surface-200',
          ],
        },
        menu: {
          class: ['outline-none', 'm-0 p-0 list-none'],
        },
        content: {
          class: [
            // Shape
            'border-none rounded-none',

            // Color
            'text-surface-700/80',

            // Transition
            'transition-shadow duration-200',
          ],
        },
        action: ({ context }) => ({
          class: [
            'relative',

            // Font
            'text-sm leading-none',

            // Flex & Alignments
            'flex items-center',

            // Spacing
            'py-2 px-3',

            // Color
            'text-surface-700/80',

            // States
            'hover:bg-surface-100/80 hover:text-surface-700',
            {
              'bg-surface-200 text-surface-700/80':
                context.focused,
            },

            // Misc
            'cursor-pointer no-underline',
            'select-none overflow-hidden',
          ],
        }),
        icon: {
          class: 'mr-2',
        },
        submenu: {
          class: 'p-0 pl-4 m-0 list-none',
        },
        transition: {
          enterFromClass: 'max-h-0',
          enterActiveClass:
            'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
          enterToClass: 'max-h-[1000px]',
          leaveFromClass: 'max-h-[1000px]',
          leaveActiveClass:
            'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
          leaveToClass: 'max-h-0',
        },
      };

      var password = {
        root: ({ props }) => ({
          class: [
            'w-full',
            'inline-flex relative',
            // 'border-[0.5px] border-general-200 rounded',
            'text-general-800',
            {
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        input: {
          class: [
            '!bg-primary-500',
            'placeholder:!font-normal',
            // 'bg-white text-general-800 placeholder:text-general-200 placeholder:font-light',
            // 'w-full h-full !max-h-[43px]',
            // 'py-1 px-3 items-center space-x-1',
            // 'flex flex-shrink-0 self-stretch',
            // 'text-xs leading-[133.333%] tracking-[0.24px]',
            // 'rounded shadow-sm',
            // 'appearance-none outline-none focus:ring-primary-500',
          ],
        },
        panel: {
          class: [
            // Spacing
            'p-3',

            // Shape
            'border-0',
            'shadow-md rounded-md',

            // Colors
            'bg-white',
            'text-general-800',
            '',
          ],
        },
        meter: {
          class: [
            // Position and Overflow
            'overflow-hidden',
            'relative',

            // Shape and Size
            'border-0',
            'h-2',
            'rounded-md',

            // Spacing
            'mb-2',

            // Colors
            'bg-surface-100',
          ],
        },
        meterlabel: ({ instance }) => ({
          class: [
            // Size
            'h-full',

            // Colors
            {
              'bg-red-500/50': instance?.meter?.strength == 'weak',
              'bg-orange-500/50':
                instance?.meter?.strength == 'medium',
              'bg-green-500/50':
                instance?.meter?.strength == 'strong',
            },

            // Transitions
            'transition-all duration-1000 ease-in-out',
          ],
        }),
        showicon: {
          class: [
            'absolute top-[54%] right-3 -mt-2',
            'text-general-200',
            'focus-visible:text-general-500 hover:text-general-500',
          ],
        },
        hideicon: {
          class: [
            'absolute top-[54%] right-3 -mt-2',
            'text-general-200',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var picklist = {
        root: {
          class: [
            // Flexbox
            'flex lg:flex-row flex-col',
          ],
        },
        sourcecontrols: {
          class: [
            // Flexbox & Alignment
            'flex lg:flex-col justify-center gap-2',

            // Spacing
            'p-3',
          ],
        },
        sourcemoveupbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        sourcemovetopbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        sourcemovedownbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        sourcemovebottombutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        sourcewrapper: {
          class: 'grow shrink basis-2/4',
        },
        sourceheader: {
          class: [
            'font-semibold',

            // Shape
            'border-b rounded-t-md',

            // Spacing
            'py-3.5 px-3',

            // Color
            'text-surface-800/80',
            'bg-surface-0',
            'border-surface-200 border-b',
          ],
        },
        sourcelist: {
          class: [
            // Spacing
            'list-none m-0 p-0',

            // Size
            'min-h-[12rem] max-h-[24rem]',

            // Shape
            'rounded-b-md border-0',

            // Color
            'text-surface-600/80',
            'bg-surface-0',
            'border border-surface-200',

            // Spacing
            'py-3 px-0',

            // Focus & Outline
            'outline-none',

            // Misc
            'overflow-auto',
          ],
        },
        item: ({ context }) => ({
          class: [
            // Position
            'relative',

            // Spacing
            'py-3.5 px-3 m-0',

            // Shape
            'border-b last:border-b-0',

            // Transition
            'transition duration-200',

            // Color
            'text-surface-700/80',
            'border-surface-200',
            {
              'bg-surface-100':
                context.active && !context.focused,
            },
            {
              'bg-surface-200':
                context.active && context.focused,
            },
            {
              'bg-surface-50/70':
                !context.active && context.focused,
            },

            // State
            'hover:bg-surface-100',

            // Misc
            'cursor-pointer overflow-hidden',
          ],
        }),
        buttons: {
          class: 'flex lg:flex-col justify-center gap-2 p-3',
        },
        movetotargetbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        movealltotargetbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        movetosourcebutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        movealltosourcebutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        targetcontrols: {
          class: 'flex lg:flex-col justify-center gap-2 p-3',
        },
        targetmoveupbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        targetmovetopbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        targetmovedownbutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        targetmovebottombutton: {
          root: ({ context }) => ({
            class: [
              // Flexbox & Alignment
              'relative inline-flex items-center justify-center',

              // Shape
              'rounded-md',

              // Color
              'text-white',
              'bg-primary-500',
              'border border-primary-500',

              // Spacing & Size
              'text-sm',
              'w-8',
              'm-0',
              'px-2.5 py-1.5 min-w-[2rem]',
              'shadow-sm',

              // Transitions
              'transition duration-200 ease-in-out',

              // State
              'hover:bg-primary-600 hover:border-primary-600',
              'focus:outline-none focus:outline-offset-0 focus:ring-2',
              'focus:ring-primary-500',
              { 'cursor-default pointer-events-none opacity-60': context.disabled },

              // Interactivity
              'cursor-pointer user-select-none',
            ],
          }),
          label: {
            class: [
              // Flexbox
              'flex-initial',

              // Size
              'w-0',
            ],
          },
        },
        targetwrapper: {
          class: 'grow shrink basis-2/4',
        },
        targetheader: {
          class: [
            'font-semibold',

            // Shape
            'border-b rounded-t-md',

            // Spacing
            'py-3.5 px-3',

            // Color
            'text-surface-800/80',
            'bg-surface-0',
            'border-surface-200 border-b',
          ],
        },
        targetlist: {
          class: [
            // Spacing
            'list-none m-0 p-0',

            // Size
            'min-h-[12rem] max-h-[24rem]',

            // Shape
            'rounded-b-md border-0',

            // Color
            'text-surface-600/80',
            'bg-surface-0',
            'border border-surface-200',

            // Spacing
            'py-3 px-0',

            // Focus & Outline
            'outline-none',

            // Misc
            'overflow-auto',
          ],
        },
        transition: {
          enterFromClass: '!transition-none',
          enterActiveClass: '!transition-none',
          leaveActiveClass: '!transition-none',
          leaveToClass: '!transition-none',
        },
      };

      var progressbar = {
        root: ({ props }) => ({
          'class': [
            {
              'grid items-center gap-2': props.mode === 'determinate',
              'grid-cols-[max-content,auto]': props.valuePosition === 'left-side',
              'grid-cols-[auto,max-content]': props.valuePosition === 'right-side',
            },
          ],
          'data-wv-name': 'progressbar',
          'data-wv-section': 'root',
        }),

        container: ({ props }) => ({
          'class': [
            // Position and Overflow
            'relative overflow-hidden',

            {
              'order-2': props.valuePosition === 'left-side',
              'order-1': props.valuePosition === 'right-side',
            },

            // Shape and Size
            {
              'h-3.5':
                props.mode !== 'indeterminate' && props.valuePosition === 'inside',
              'h-1.5':
                props.mode === 'indeterminate' || props.valuePosition !== 'inside',
            },

            // Indeterminate Animation Classes
            {
              'bg-general-100 m-0 border-0 progressbar-value-animate before:absolute before:top-0 before:left-0 before:bottom-0 before:bg-primary-400 after:absolute after:top-0 after:left-0 after:bottom-0 after:bg-inherit after:delay-1000':
                props.mode === 'indeterminate',
            },

            'border-0',
            'rounded-full',
            'bg-general-100',
          ],
          'data-wv-section': 'container',
        }),

        value: ({ props }) => ({
          class: [
            // Flexbox & Overflow & Position
            'rounded-full',
            {
              'absolute flex items-center justify-center':
                props.mode !== 'indeterminate',
            },

            // Colors
            {
              'bg-warning-500':
                (props.value < 100 &&
                  props.value > 0 &&
                  props.severity !== 'danger') ||
                props.severity === 'warning',
              'min-w-[6px] bg-grayscale-500': props.value === 0,
              'bg-success-1000': props.severity === 'success',
              'bg-danger-500': props.severity === 'danger',
              'bg-primary-400': props.severity === 'primary',
            },

            // Spacing & Sizing
            'm-0',
            {
              'h-1.5 w-0':
                props.mode !== 'indeterminate' && props.valuePosition !== 'inside',
              'h-3.5 w-0':
                props.mode !== 'indeterminate' && props.valuePosition === 'inside',
            },

            // Shape
            'border-0',

            // Transitions
            {
              'transition-width duration-1000 ease-in-out':
                props.mode !== 'indeterminate',
              'progressbar-value-animate': props.mode === 'indeterminate',
            },

            // Before & After (indeterminate)
            {
              'before:absolute before:top-0 before:left-0 before:bottom-0 before:bg-inherit ':
                props.mode === 'indeterminate',
              'after:absolute after:top-0 after:left-0 after:bottom-0 after:bg-inherit after:delay-1000':
                props.mode === 'indeterminate',
            },
          ],
        }),

        label: ({ props }) => ({
          class: [
            // Flexbox
            'inline-flex justify-end',

            // Font and Text
            'font-medium text-[14px] leading-4',
            'leading-none',

            {
              'absolute inset-0 mr-1 !text-xs': props.valuePosition === 'inside',
              'order-1': props.valuePosition === 'left-side',
              'order-2': props.valuePosition === 'right-side',
            },
          ],
        }),
      };

      var buttonradio = {
        root: {
          'class': 'flex items-center w-max gap-1 cursor-pointer text-xs',
          'data-wv-name': 'buttonradio',
          'data-wv-section': 'root',
        },
        wrapper: ({ props }) => ({
          class: [
            'relative',

            // Flexbox & Alignment
            'flex items-center justify-center',

            // Size
            'w-4 h-4',

            // Shape
            'border-2',
            'rounded-full',

            // Colors
            {
              'bg-white':
                props.value !== props.modelValue &&
                props.value !== undefined &&
                !props.disabled,
              'bg-general-50': props.disabled,
              'border-general-100':
                props.value !== props.modelValue &&
                props.value !== undefined &&
                !props.disabled,
              'border-primary-500':
                props.value == props.modelValue &&
                props.value !== undefined &&
                !props.disabled,
              'border-general-200': props.disabled,
            },

            // Misc
            'cursor-pointer',
          ],
        }),
        box: ({ props }) => ({
          class: [
            // Flexbox
            'flex justify-center items-center',

            // Size
            'w-2 h-2',
            'text-sm',
            'font-medium',

            // Shape
            // 'border-2',
            'rounded-full',

            // Transition
            'transition duration-200 ease-in-out',

            // Colors
            {
              'border-general-100':
                props.value !== props.modelValue && props.value !== undefined,
              'border-primary-500':
                props.value == props.modelValue &&
                props.value !== undefined &&
                !props.disabled,
              'bg-primary-500':
                props.value == props.modelValue &&
                props.value !== undefined &&
                !props.disabled,
              'bg-general-200':
                props.value == props.modelValue &&
                props.value !== undefined &&
                props.disabled,
            },

            // States
            {
              'outline-none outline-offset-0': !props.disabled,
              'peer-focus-visible:ring-2 peer-focus-visible:ring-offset-2 peer-focus-visible:ring-offset-surface-0 peer-focus-visible:ring-primary-500':
                !props.disabled,
              // 'opacity-60 cursor-default': props.disabled,
            },
          ],
        }),
        input: ({ props }) => ({
          class: [
            'peer',

            // Size
            'w-full ',
            'h-full',

            // Position
            'absolute',
            'top-0 left-0',
            'z-10',

            // Spacing
            'p-0',
            'm-0',

            // Shape
            'opacity-0',
            'rounded-md',
            'outline-none',
            // 'border-2 border-surface-300',

            // Misc
            'appearance-none',
            { 'cursor-default': props.disabled, 'cursor-pointer': !props.disabled },
          ],
        }),
        icon: {
          class: 'hidden',
        },
      };

      var rating = {
        root: ({ props }) => ({
          class: [
            'relative',

            // Flex & Alignment
            'flex items-center',
            'gap-1',

            // Misc
            {
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        cancelitem: ({ context }) => ({
          class: [
            // Flex & Alignment
            'inline-flex items-center',

            //State
            {
              'outline-none ring-2 ring-primary-500':
                context.focused,
            },

            // Misc
            'cursor-pointer',
          ],
        }),
        cancelicon: {
          class: [
            // Size
            'w-5 h-5',

            // Color
            'text-red-500',
          ],
        },
        item: ({ props, context }) => ({
          class: [
            // Flex & Alignment
            'inline-flex items-center',

            // State
            {
              'outline-none ring-2 ring-primary-500':
                context.focused,
            },

            // Misc
            {
              'cursor-pointer': !props.readonly,
              'cursor-default': props.readonly,
            },
          ],
        }),
        officon: ({ props }) => ({
          class: [
            // Size
            'w-5 h-5',

            // Color
            'text-surface-700',

            // State
            { 'hover:text-primary-500': !props.readonly },

            // Transition
            'transition duration-200 ease-in',
          ],
        }),
        onicon: ({ props }) => ({
          class: [
            // Size
            'w-5 h-5',

            // Color
            'text-primary-500',

            // Transition
            'transition duration-200 ease-in',
          ],
        }),
      };

      var ripple = {
        root: {
          class: ['block absolute bg-surface-0/50 rounded-full pointer-events-none'],
          style: 'transform: scale(0)',
        },
      };

      var scrollpanel = {
        wrapper: {
          class: [
            // Size & Position
            'h-full w-full',

            // Layering
            'z-[1]',

            // Spacing
            'overflow-hidden',

            // Misc
            'relative float-left',
          ],
        },
        content: {
          class: [
            // Size & Spacing
            'h-[calc(100%+12px)] w-[calc(100%+12px)] pr-[12px] pb-[12px] pl-0 pt-0',

            // Overflow & Scrollbar
            'overflow-scroll scrollbar-none',

            // Box Model
            'box-border',

            // Position
            'relative',

            // Webkit Specific
            '[&::-webkit-scrollbar]:hidden',
          ],
        },
        barX: {
          class: [
            // Size & Position
            'h-[6px] bottom-0',

            // Appearance
            'bg-surface-100 rounded',

            // Interactivity
            'cursor-pointer',

            // Visibility & Layering
            'invisible z-20',

            // Transition
            'transition duration-[250ms] ease-linear',

            // Misc
            'relative',
          ],
        },
        barY: {
          class: [
            // Size & Position
            'w-[6px] top-0',

            // Appearance
            'bg-surface-100 rounded',

            // Interactivity
            'cursor-pointer',

            // Visibility & Layering
            'z-20',

            // Transition
            'transition duration-[250ms] ease-linear',

            // Misc
            'relative',
          ],
        },
      };

      var scrolltop = {
        root: ({ props }) => ({
          class: [
            // Flex & Alignment
            'flex items-center justify-center',

            // Positioning
            {
              sticky: props.target === 'parent',
              fixed: props.target === 'window',
            },
            'bottom-[20px] right-[20px]',
            'ml-auto',

            // Shape & Size
            {
              'rounded-md h-8 w-8': props.target === 'parent',
              'h-12 w-12 rounded-full shadow-md': props.target === 'window',
            },

            // Color
            'text-white',
            {
              'bg-primary-500 hover:bg-primary-600':
                props.target === 'parent',
              'bg-surface-500 hover:bg-surface-600':
                props.target === 'window',
            },

            // States
            {
              'hover:bg-primary-600':
                props.target === 'parent',
              'hover:bg-surface-600':
                props.target === 'window',
            },
          ],
        }),
        transition: {
          enterFromClass: 'opacity-0',
          enterActiveClass: 'transition-opacity duration-150',
          leaveActiveClass: 'transition-opacity duration-150',
          leaveToClass: 'opacity-0',
        },
      };

      var selectbutton = {
        root: ({ props }) => ({
          class: [
            'shadow-sm flex flex-col min-w-[43px] w-max',
            // {
            //   'opacity-60 select-none pointer-events-none cursor-default':
            //     props.disabled,
            // },
          ],
        }),
        button: ({ context }) => ({
          class: [
            'relative',
            // Font
            'text-body-medium',

            // Flex Alignment
            'inline-flex items-center align-bottom text-center',

            // Spacing
            'px-3 py-[5px]',

            // Shape
            'ring-1 ring-inset ring-primary-500',
            'first:!rounded-t first:!rounded-b-none',
            'last:!rounded-t-none last:!rounded-b ',

            // Color
            {
              'bg-surface-0': !context.active,
              'text-primary-500': !context.active,
              'text-surface-0': context.active,
              'bg-primary-500': context.active,
            },

            // States
            'focus:outline-none focus:outline-offset-0 focus:z-10',
            {
              'focus:bg-primary-50 ': !context.active,
              'hover:bg-primary-50 ': !context.active,
              'focus:bg-primary-600': context.active,
              'hover:bg-primary-600': context.active,
            },
            // {
            //   'opacity-60 select-none pointer-events-none cursor-default':
            //     context.disabled,
            // },

            // Transition
            'transition duration-200',

            // Misc
            'cursor-pointer select-none overflow-hidden',
          ],
        }),
        label: {
          class: 'font-semibold',
        },
      };

      var sidebar = {
        root: ({ props }) => ({
          class: [
            // Flexbox
            'flex flex-col',

            // Position
            'relative',
            {
              '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
                props.position == 'full',
            },

            // Size
            {
              'h-full w-full max-w-[28rem]':
                props.position == 'left' || props.position == 'right',
              'h-auto w-full': props.position == 'top' || props.position == 'bottom',
            },

            // Shape
            'border-0',
            'shadow-xl',

            // Colors
            'bg-surface-0',
            'text-surface-700/80',
            '',

            // Transitions
            'transition-transform',
            'duration-300',

            // Misc
            'pointer-events-auto',
          ],
        }),
        header: {
          class: [
            // Flexbox and Alignment
            'flex items-center justify-between',
            'shrink-0',

            // Spacing
            'px-6 pt-6',

            // Colors
            'bg-surface-0',
            'text-surface-700/80',
          ],
        },
        title: {
          class: ['font-semibold leading-6 text-base m-0'],
        },
        icons: {
          class: ['flex items-center'],
        },
        closeButton: {
          class: [
            'relative',

            // Flexbox and Alignment
            'flex items-center justify-center',

            // Size and Spacing
            'mr-2',
            'last:mr-0',
            'w-6 h-6',

            // Shape
            'border-0',
            'rounded-full',

            // Colors
            'text-surface-500',
            'bg-transparent',

            // Transitions
            'transition duration-200 ease-in-out',

            // States
            'hover:text-surface-700',
            'hover:bg-surface-100',
            'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset',
            'focus:ring-primary-500',

            // Misc
            'overflow-hidden',
          ],
        },
        closeicon: {
          class: [
            // Display
            'inline-block',

            // Size
            'w-3',
            'h-3',
          ],
        },
        content: {
          class: [
            // Spacing and Size
            'px-6 pb-6',
            'mt-6',
            'h-full',
            'w-full',

            // Growth and Overflow
            'grow',
            'overflow-y-auto',
          ],
        },
        mask: ({ props }) => ({
          class: [
            // Transitions
            'transition',
            'duration-300',
            { 'p-5': !props.position == 'full' },

            // Background and Effects
            {
              'has-[.mask-active]:bg-transparent bg-surface-500/70/70':
                props.modal,
              'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal,
            },
          ],
        }),
        transition: ({ props }) => {
          return props.position === 'top'
            ? {
                enterFromClass:
                  'translate-x-0 -translate-y-full translate-z-0 mask-active',
                leaveToClass:
                  'translate-x-0 -translate-y-full translate-z-0 mask-active',
              }
            : props.position === 'bottom'
              ? {
                  enterFromClass:
                    'translate-x-0 translate-y-full translate-z-0 mask-active',
                  leaveToClass:
                    'translate-x-0 translate-y-full translate-z-0 mask-active',
                }
              : props.position === 'left'
                ? {
                    enterFromClass:
                      '-translate-x-full translate-y-0 translate-z-0 mask-active',
                    leaveToClass:
                      '-translate-x-full translate-y-0 translate-z-0 mask-active',
                  }
                : props.position === 'right'
                  ? {
                      enterFromClass:
                        'translate-x-full translate-y-0 translate-z-0 mask-active',
                      leaveToClass:
                        'translate-x-full translate-y-0 translate-z-0 mask-active',
                    }
                  : {
                      enterFromClass: 'opacity-0 mask-active',
                      enterActiveClass: 'transition-opacity duration-400 ease-in',
                      leaveActiveClass: 'transition-opacity duration-400 ease-in',
                      leaveToClass: 'opacity-0 mask-active',
                    };
        },
      };

      var skeleton = {
        root: ({ props }) => ({
          class: [
            'overflow-hidden',
            {
              'animate-pulse': props.animation !== 'none',
            },

            // Round
            {
              'rounded-full': props.shape === 'circle',
              'rounded-md': props.shape !== 'circle',
            },

            // Colors
            'bg-general-50',
          ],
        }),
      };

      var slider = {
        root: ({ props }) => ({
          class: [
            'relative',
            'flex items-center',

            // Size
            {
              'h-2 w-full min-w-[15rem]': props.orientation == 'horizontal',
              'w-2 h-full min-h-[15rem]': props.orientation == 'vertical',
            },

            // Shape
            'border-0 rounded-lg',

            // Colors
            'bg-surface-100',

            // States
            {
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        range: ({ props }) => ({
          class: [
            // Position
            'block absolute',
            {
              'top-0 left-0': props.orientation == 'horizontal',
              'bottom-0 left-0': props.orientation == 'vertical',
            },

            //Size
            {
              'h-full': props.orientation == 'horizontal',
              'w-full': props.orientation == 'vertical',
            },

            //Shape
            'rounded-lg',

            // Colors
            'bg-primary-500',
          ],
        }),
        handle: ({ props }) => ({
          class: [
            'block',

            // Size
            'h-4',
            'w-4',
            {
              'top-[50%] mt-[-0.5rem] ml-[-0.5rem]':
                props.orientation == 'horizontal',
              'left-[50%] mb-[-0.5rem] ml-[-0.5rem]': props.orientation == 'vertical',
            },

            // Shape
            'rounded-full',
            'ring-2',

            // Colors
            'bg-surface-0',
            'ring-primary-500',

            // Transitions
            'transition duration-200',

            // Misc
            'cursor-grab',
            'touch-action-none',
          ],
        }),
        starthandler: ({ props }) => ({
          class: [
            'block',

            // Size
            'h-4',
            'w-4',
            {
              'top-[50%] mt-[-0.5rem] ml-[-0.5rem]':
                props.orientation == 'horizontal',
              'left-[50%] mb-[-0.5rem] ml-[-0.5rem]': props.orientation == 'vertical',
            },

            // Shape
            'rounded-full',
            'ring-2',

            // Colors
            'bg-surface-0',
            'ring-primary-500',

            // Transitions
            'transition duration-200',

            // Misc
            'cursor-grab',
            'touch-action-none',
          ],
        }),
        endhandler: ({ props }) => ({
          class: [
            'block',

            // Size
            'h-4',
            'w-4',
            {
              'top-[50%] mt-[-0.5rem] ml-[-0.5rem]':
                props.orientation == 'horizontal',
              'left-[50%] mb-[-0.5rem] ml-[-0.5rem]': props.orientation == 'vertical',
            },

            // Shape
            'rounded-full',
            'ring-2',

            // Colors
            'bg-surface-0',
            'ring-primary-500',

            // Transitions
            'transition duration-200',

            // Misc
            'cursor-grab',
            'touch-action-none',
          ],
        }),
      };

      var splitbutton = {
        root: ({ props }) => ({
          class: [
            // Flexbox and Position
            'inline-flex',
            'relative',

            // Shape
            'rounded-md',
            { 'shadow-lg': props.raised },
          ],
        }),
        button: {
          root: ({ parent }) => ({
            class: [
              'relative',
              // Alignments
              'items-center inline-flex text-center align-bottom justify-center',

              // Sizes & Spacing
              'text-sm',
              {
                'px-2.5 py-1.5 min-w-[2rem]':
                  parent.props.size === null && parent.props.label !== null,
                'px-2 py-1': parent.props.size === 'small',
                'px-3 py-2': parent.props.size === 'large',
              },
              {
                'h-8 w-8 p-0': parent.props.label == null,
              },

              // Shape
              'rounded-r-none',
              'border-r-0',
              { 'rounded-l-full': parent.props.rounded },
              {
                'rounded-md': !parent.props.rounded,
                'rounded-full': parent.props.rounded,
              },

              // Link Button
              {
                'text-primary-600 bg-transparent ring-transparent': parent.props.link,
              },

              // Plain Button
              {
                'text-white bg-gray-500 ring-1 ring-gray-500':
                  parent.props.plain && !parent.props.outlined && !parent.props.text,
              },
              // Plain Text Button
              { 'text-surface-500': parent.props.plain && parent.props.text },
              // Plain Outlined Button
              {
                'text-surface-500 ring-1 ring-gray-500':
                  parent.props.plain && parent.props.outlined,
              },

              // Text Button
              {
                'bg-transparent ring-transparent':
                  parent.props.text && !parent.props.plain,
              },

              // Outlined Button
              { 'bg-transparent': parent.props.outlined && !parent.props.plain },

              // --- Severity Buttons ---

              // Primary Button
              {
                'text-white':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-primary-500':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-primary-500':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Primary Text Button
              {
                'text-primary-500':
                  parent.props.text &&
                  parent.props.severity === null &&
                  !parent.props.plain,
              },
              // Primary Outlined Button
              {
                'text-primary-500 ring-1 ring-primary-500 hover:bg-primary-300/20':
                  parent.props.outlined &&
                  parent.props.severity === null &&
                  !parent.props.plain,
              },

              // Secondary Button
              {
                'text-white':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-surface-500':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-surface-500':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Secondary Text Button
              {
                'text-surface-500':
                  parent.props.text &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },
              // Secondary Outlined Button
              {
                'text-surface-500 ring-1 ring-surface-500 hover:bg-surface-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },

              // Success Button
              {
                'text-white':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-green-500':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-green-500':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Success Text Button
              {
                'text-surface-500':
                  parent.props.text &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },
              // Success Outlined Button
              {
                'text-green-500 ring-1 ring-green-500 hover:bg-green-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'success' &&
                  !parent.props.plain,
              },

              // Info Button
              {
                'text-white':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-blue-500':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-blue-500':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Info Text Button
              {
                'text-blue-500':
                  parent.props.text &&
                  parent.props.severity === 'info' &&
                  !parent.props.plain,
              },
              // Info Outlined Button
              {
                'text-blue-500 ring-1 ring-blue-500 hover:bg-blue-300/20 ':
                  parent.props.outlined &&
                  parent.props.severity === 'info' &&
                  !parent.props.plain,
              },

              // Warning Button
              {
                'text-white':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-orange-500':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-orange-500':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Warning Text Button
              {
                'text-orange-500':
                  parent.props.text &&
                  parent.props.severity === 'warning' &&
                  !parent.props.plain,
              },
              // Warning Outlined Button
              {
                'text-orange-500 ring-1 ring-orange-500 hover:bg-orange-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'warning' &&
                  !parent.props.plain,
              },

              // Help Button
              {
                'text-white':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-purple-500':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-purple-500':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Help Text Button
              {
                'text-purple-500':
                  parent.props.text &&
                  parent.props.severity === 'help' &&
                  !parent.props.plain,
              },
              // Help Outlined Button
              {
                'text-purple-500 ring-1 ring-purple-500 hover:bg-purple-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'help' &&
                  !parent.props.plain,
              },

              // Danger Button
              {
                'text-white':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-red-500':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-red-500':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Danger Text Button
              {
                'text-red-500':
                  parent.props.text &&
                  parent.props.severity === 'danger' &&
                  !parent.props.plain,
              },
              // Danger Outlined Button
              {
                'text-red-500 ring-1 ring-red-500 hover:bg-red-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'danger' &&
                  !parent.props.plain,
              },

              // --- Severity Button States ---
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
              {
                'focus:ring-offset-2':
                  !parent.props.link &&
                  !parent.props.plain &&
                  !parent.props.outlined &&
                  !parent.props.text,
              },

              // Link
              {
                'focus:ring-primary-500':
                  parent.props.link,
              },

              // Plain
              {
                'hover:bg-gray-600 hover:ring-gray-600':
                  parent.props.plain && !parent.props.outlined && !parent.props.text,
              },
              // Text & Outlined Button
              {
                'hover:bg-surface-300/20':
                  parent.props.plain && (parent.props.text || parent.props.outlined),
              },

              // Primary
              {
                'hover:bg-primary-600 hover:ring-primary-600':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-primary-500':
                  parent.props.severity === null,
              },
              // Text & Outlined Button
              {
                'hover:bg-primary-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === null &&
                  !parent.props.plain,
              },

              // Secondary
              {
                'hover:bg-surface-600 hover:ring-surface-600':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-surface-500':
                  parent.props.severity === 'secondary',
              },
              // Text & Outlined Button
              {
                'hover:bg-surface-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },

              // Success
              {
                'hover:bg-green-600 hover:ring-green-600':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-green-500':
                  parent.props.severity === 'success',
              },
              // Text & Outlined Button
              {
                'hover:bg-green-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'success' &&
                  !parent.props.plain,
              },

              // Info
              {
                'hover:bg-blue-600 hover:ring-blue-600':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-blue-500':
                  parent.props.severity === 'info',
              },
              // Text & Outlined Button
              {
                'hover:bg-blue-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'info' &&
                  !parent.props.plain,
              },

              // Warning
              {
                'hover:bg-orange-600 hover:ring-orange-600':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-orange-500':
                  parent.props.severity === 'warning',
              },
              // Text & Outlined Button
              {
                'hover:bg-orange-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'warning' &&
                  !parent.props.plain,
              },

              // Help
              {
                'hover:bg-purple-600 hover:ring-purple-600':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-purple-500':
                  parent.props.severity === 'help',
              },
              // Text & Outlined Button
              {
                'hover:bg-purple-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'help' &&
                  !parent.props.plain,
              },

              // Warning
              {
                'hover:bg-red-600 hover:ring-red-600':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-red-500':
                  parent.props.severity === 'danger',
              },
              // Text & Outlined Button
              {
                'hover:bg-red-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'danger' &&
                  !parent.props.plain,
              },

              // Transitions
              'transition duration-200 ease-in-out',

              // Misc
              'cursor-pointer overflow-hidden select-none',
            ],
          }),
          icon: {
            class: [
              // Margins
              'mr-2',
            ],
          },
        },
        menubutton: {
          root: ({ parent }) => ({
            class: [
              'relative',

              // Alignments
              'items-center inline-flex text-center align-bottom justify-center',

              // Sizes & Spacing
              'text-sm',
              {
                'px-2.5 py-1.5 min-w-[2rem]':
                  parent.props.size === null && parent.props.label !== null,
                'px-2 py-1': parent.props.size === 'small',
                'px-3 py-2': parent.props.size === 'large',
              },
              {
                'h-8 w-8 p-0': parent.props.label == null,
              },
              'ml-[1px]',

              // Shape
              'rounded-l-none',
              { 'rounded-l-full': parent.props.rounded },
              {
                'rounded-md': !parent.props.rounded,
                'rounded-full': parent.props.rounded,
              },

              // Link Button
              {
                'text-primary-600 bg-transparent ring-transparent': parent.props.link,
              },

              // Plain Button
              {
                'text-white bg-gray-500 ring-1 ring-gray-500':
                  parent.props.plain && !parent.props.outlined && !parent.props.text,
              },
              // Plain Text Button
              { 'text-surface-500': parent.props.plain && parent.props.text },
              // Plain Outlined Button
              {
                'text-surface-500 ring-1 ring-gray-500':
                  parent.props.plain && parent.props.outlined,
              },

              // Text Button
              {
                'bg-transparent ring-transparent':
                  parent.props.text && !parent.props.plain,
              },

              // Outlined Button
              { 'bg-transparent': parent.props.outlined && !parent.props.plain },

              // --- Severity Buttons ---

              // Primary Button
              {
                'text-white':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-primary-500':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-primary-500':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Primary Text Button
              {
                'text-primary-500':
                  parent.props.text &&
                  parent.props.severity === null &&
                  !parent.props.plain,
              },
              // Primary Outlined Button
              {
                'text-primary-500 ring-1 ring-primary-500 hover:bg-primary-300/20':
                  parent.props.outlined &&
                  parent.props.severity === null &&
                  !parent.props.plain,
              },

              // Secondary Button
              {
                'text-white':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-surface-500':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-surface-500':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Secondary Text Button
              {
                'text-surface-500':
                  parent.props.text &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },
              // Secondary Outlined Button
              {
                'text-surface-500 ring-1 ring-surface-500 hover:bg-surface-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },

              // Success Button
              {
                'text-white':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-green-500':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-green-500':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Success Text Button
              {
                'text-surface-500':
                  parent.props.text &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },
              // Success Outlined Button
              {
                'text-green-500 ring-1 ring-green-500 hover:bg-green-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'success' &&
                  !parent.props.plain,
              },

              // Info Button
              {
                'text-white':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-blue-500':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-blue-500':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Info Text Button
              {
                'text-blue-500':
                  parent.props.text &&
                  parent.props.severity === 'info' &&
                  !parent.props.plain,
              },
              // Info Outlined Button
              {
                'text-blue-500 ring-1 ring-blue-500 hover:bg-blue-300/20 ':
                  parent.props.outlined &&
                  parent.props.severity === 'info' &&
                  !parent.props.plain,
              },

              // Warning Button
              {
                'text-white':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-orange-500':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-orange-500':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Warning Text Button
              {
                'text-orange-500':
                  parent.props.text &&
                  parent.props.severity === 'warning' &&
                  !parent.props.plain,
              },
              // Warning Outlined Button
              {
                'text-orange-500 ring-1 ring-orange-500 hover:bg-orange-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'warning' &&
                  !parent.props.plain,
              },

              // Help Button
              {
                'text-white':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-purple-500':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-purple-500':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Help Text Button
              {
                'text-purple-500':
                  parent.props.text &&
                  parent.props.severity === 'help' &&
                  !parent.props.plain,
              },
              // Help Outlined Button
              {
                'text-purple-500 ring-1 ring-purple-500 hover:bg-purple-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'help' &&
                  !parent.props.plain,
              },

              // Danger Button
              {
                'text-white':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'bg-red-500':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
                'ring-1 ring-red-500':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              // Danger Text Button
              {
                'text-red-500':
                  parent.props.text &&
                  parent.props.severity === 'danger' &&
                  !parent.props.plain,
              },
              // Danger Outlined Button
              {
                'text-red-500 ring-1 ring-red-500 hover:bg-red-300/20':
                  parent.props.outlined &&
                  parent.props.severity === 'danger' &&
                  !parent.props.plain,
              },

              // --- Severity Button States ---
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
              {
                'focus:ring-offset-2':
                  !parent.props.link &&
                  !parent.props.plain &&
                  !parent.props.outlined &&
                  !parent.props.text,
              },

              // Link
              {
                'focus:ring-primary-500':
                  parent.props.link,
              },

              // Plain
              {
                'hover:bg-gray-600 hover:ring-gray-600':
                  parent.props.plain && !parent.props.outlined && !parent.props.text,
              },
              // Text & Outlined Button
              {
                'hover:bg-surface-300/20':
                  parent.props.plain && (parent.props.text || parent.props.outlined),
              },

              // Primary
              {
                'hover:bg-primary-600 hover:ring-primary-600':
                  !parent.props.link &&
                  parent.props.severity === null &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-primary-500':
                  parent.props.severity === null,
              },
              // Text & Outlined Button
              {
                'hover:bg-primary-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === null &&
                  !parent.props.plain,
              },

              // Secondary
              {
                'hover:bg-surface-600 hover:ring-surface-600':
                  parent.props.severity === 'secondary' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-surface-500':
                  parent.props.severity === 'secondary',
              },
              // Text & Outlined Button
              {
                'hover:bg-surface-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'secondary' &&
                  !parent.props.plain,
              },

              // Success
              {
                'hover:bg-green-600 hover:ring-green-600':
                  parent.props.severity === 'success' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-green-500':
                  parent.props.severity === 'success',
              },
              // Text & Outlined Button
              {
                'hover:bg-green-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'success' &&
                  !parent.props.plain,
              },

              // Info
              {
                'hover:bg-blue-600 hover:ring-blue-600':
                  parent.props.severity === 'info' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-blue-500':
                  parent.props.severity === 'info',
              },
              // Text & Outlined Button
              {
                'hover:bg-blue-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'info' &&
                  !parent.props.plain,
              },

              // Warning
              {
                'hover:bg-orange-600 hover:ring-orange-600':
                  parent.props.severity === 'warning' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-orange-500':
                  parent.props.severity === 'warning',
              },
              // Text & Outlined Button
              {
                'hover:bg-orange-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'warning' &&
                  !parent.props.plain,
              },

              // Help
              {
                'hover:bg-purple-600 hover:ring-purple-600':
                  parent.props.severity === 'help' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-purple-500':
                  parent.props.severity === 'help',
              },
              // Text & Outlined Button
              {
                'hover:bg-purple-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'help' &&
                  !parent.props.plain,
              },

              // Warning
              {
                'hover:bg-red-600 hover:ring-red-600':
                  parent.props.severity === 'danger' &&
                  !parent.props.text &&
                  !parent.props.outlined &&
                  !parent.props.plain,
              },
              {
                'focus:ring-red-500':
                  parent.props.severity === 'danger',
              },
              // Text & Outlined Button
              {
                'hover:bg-red-300/20':
                  (parent.props.text || parent.props.outlined) &&
                  parent.props.severity === 'danger' &&
                  !parent.props.plain,
              },

              // Transitions
              'transition duration-200 ease-in-out',

              // Misc
              'cursor-pointer overflow-hidden select-none',
            ],
          }),
          label: {
            class: ['hidden'],
          },
        },
      };

      var steps = {
        root: {
          class: 'relative',
        },
        menu: {
          class: 'p-0 m-0 list-none flex justify-center',
        },
        menuitem: {
          class: [
            // Flexbox and Position
            'relative',
            'flex',
            'justify-center',
          ],
        },
        action: ({ props, context, state }) => ({
          class: [
            // Flexbox
            'inline-flex items-start relative',
            'flex-col',

            // Transitions and Shape
            'transition-shadow',
            'rounded-md',

            // Spacing
            {
              'pr-4 min-[576px]:pr-32 md:pr-40':
                props.model.length !== context.index + 1,
            },

            // Colors
            'bg-surface-0',
            '',

            // Misc
            { 'cursor-pointer': !props.readonly },

            // After
            'after:border-t-2',
            {
              'after:border-surface-200':
                state.d_activeStep <= context.index,
            },
            {
              'after:border-primary-500':
                state.d_activeStep > context.index,
            },
            'after:w-full',
            'after:absolute',
            'after:top-1/2',
            'after:left-0',
            'after:transform',
            'after:-mt-3',
            { 'after:hidden': props.model.length == context.index + 1 },
          ],
        }),
        step: ({ context, props, state }) => ({
          class: [
            // Flexbox
            'flex items-center justify-center',

            // Position
            'z-40',

            // Shape
            'rounded-full',
            'border-2',

            // Size
            'w-[2rem]',
            'h-[2rem]',
            'text-sm',
            'leading-[2rem]',

            // Colors
            {
              'text-surface-400/60':
                !context.active && state.d_activeStep < context.index,
              'border-surface-100':
                !context.active && state.d_activeStep < context.index,
              'bg-surface-0': state.d_activeStep <= context.index,
            },
            {
              'border-primary-500': context.active,
              'text-primary-500': context.active,
            },
            {
              'bg-primary-500':
                state.d_activeStep > context.index,
              'text-surface-0':
                state.d_activeStep > context.index,
              'border-primary-500':
                state.d_activeStep > context.index,
            },

            // States
            {
              'hover:border-surface-300':
                !context.active && !props.readonly,
            },

            // Transition
            'transition-colors duration-200 ease-in-out',
          ],
        }),
        label: ({ context }) => ({
          class: [
            'relative',
            // Font
            'text-sm leading-none',
            { 'font-medium': context.active },

            // Display
            'block',

            // Spacing
            'mt-2',

            // Colors
            {
              'text-surface-400/60': !context.active,
              'text-surface-800/80': context.active,
            },

            // Text and Overflow
            'whitespace-nowrap',
            'overflow-ellipsis',
            'max-w-full',
          ],
        }),
      };

      var tabmenu = {
        root: {
          class: 'overflow-x-auto',
          style: 'scrollbar-width: none',
        },
        menu: {
          class: [
            // Flexbox
            'flex flex-1',

            // Size

            // Spacing
            'list-none',
            'p-0 m-0',

            // Colors
            'text-general-100',
          ],
        },
        menuitem: {
          class: 'mr-0',
        },
        action: ({ context, state }) => ({
          class: [
            'relative',

            // Font
            'font-normal',
            'text-xs',

            // Flexbox and Alignment
            'flex items-center',

            // Spacing
            'p-2 pb-1.5 pt-0.5 !h-6',

            // Shape
            'border-b-2',

            // Colors and Conditions
            {
              'bg-transparent ': state.d_activeIndex !== context.index,
              'text-grayscale-900': state.d_activeIndex !== context.index,

              'border-primary-400': state.d_activeIndex === context.index,

              'border-primary-100': state.d_activeIndex !== context.index,
              'text-primary-500': state.d_activeIndex === context.index,
            },

            // States
            'hover:bg-primary-50',
            'focus-visible:outline-none focus-visible:outline-offset-0',
            'focus-visible:bg-primary-50',
            {
              'focus-visible:border-primary-200 focus-visible:text-primary-400':
                state.d_activeIndex !== context.index,
            },

            // Disabled States
            {
              'pointer-events-none': context.item?.disabled,
              'opacity-60': context.item?.disabled,
            },

            // Transitions
            'transition-all duration-200',

            // Misc
            'cursor-pointer select-none text-decoration-none',
            'overflow-hidden',
            'user-select-none',
            '!no-underline',
            'whitespace-nowrap',
          ],
        }),
        icon: {
          class: 'mr-2',
        },
        inkbar: {
          class: 'grow border-b-2 border-primary-100',
        },
      };

      var tabview = {
        navContainer: ({ props }) => ({
          class: [
            // Position
            'relative',

            // Misc
            { 'overflow-hidden': props.scrollable },
          ],
        }),
        navContent: {
          class: [
            // Overflow and Scrolling
            'overflow-y-hidden overscroll-contain',
            'overscroll-auto',
            'scroll-smooth',
            '[&::-webkit-scrollbar]:hidden',
          ],
        },
        previousButton: {
          class: [
            // Flexbox and Alignment
            'flex items-center justify-center',

            // Position
            '!absolute',
            'top-0 left-0',
            'z-20',

            // Size and Shape
            'h-full w-12',
            'rounded-none',

            // Colors
            'bg-surface-0',
            'text-primary-500',
            'shadow-md',
          ],
        },
        nextButton: {
          class: [
            // Flexbox and Alignment
            'flex items-center justify-center',

            // Position
            '!absolute',
            'top-0 right-0',
            'z-20',

            // Size and Shape
            'h-full w-12',
            'rounded-none',

            // Colors
            'bg-surface-0',
            'text-primary-500',
            'shadow-md',
          ],
        },
        nav: {
          class: [
            // Flexbox
            'flex flex-1',

            // Spacing
            'list-none',
            'p-0 m-0',

            // Colors
            'bg-surface-0',
            'border-b border-surface-200',
            'text-surface-900/80',
          ],
        },
        tabpanel: {
          header: ({ props }) => ({
            class: [
              // Spacing
              'mr-0',

              // Misc
              {
                'opacity-60 cursor-default user-select-none select-none pointer-events-none':
                  props?.disabled,
              },
            ],
          }),
          headerAction: ({ parent, context }) => ({
            class: [
              'relative',

              // Font
              'font-medium',
              'text-md',

              // Flexbox and Alignment
              'flex items-center',

              // Spacing
              'py-4 px-3',
              '-mb-[1px]',

              // Shape
              'border-b-2',
              'rounded-t-md',

              // Colors and Conditions
              {
                'border-surface-200':
                  parent.state.d_activeIndex !== context.index,
                'bg-surface-0':
                  parent.state.d_activeIndex !== context.index,
                'text-surface-700/80':
                  parent.state.d_activeIndex !== context.index,

                'bg-surface-0':
                  parent.state.d_activeIndex === context.index,
                'border-primary-500':
                  parent.state.d_activeIndex === context.index,
                'text-primary-500':
                  parent.state.d_activeIndex === context.index,
              },

              // States
              'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset',
              'focus-visible:ring-primary-500',
              {
                'hover:bg-surface-0':
                  parent.state.d_activeIndex !== context.index,
                'hover:border-surface-400':
                  parent.state.d_activeIndex !== context.index,
                'hover:text-surface-900':
                  parent.state.d_activeIndex !== context.index,
              },

              // Transitions
              'transition-all duration-200',

              // Misc
              'cursor-pointer select-none text-decoration-none',
              'overflow-hidden',
              'user-select-none',
              'whitespace-nowrap',
            ],
          }),
          headerTitle: {
            class: [
              // Text
              'leading-none',
              'whitespace-nowrap',
            ],
          },
          content: {
            class: [
              // Spacing
              'p-6',

              // Shape
              'rounded-b-md',

              // Colors
              'bg-surface-0',
              'text-surface-700/80',
              'border-0',
            ],
          },
        },
      };

      var tag = {
        root: ({ props }) => ({
          class: [
            //Font
            'text-xs font-bold',

            //Alignments
            'inline-flex items-center justify-center',

            //Spacing
            'px-2 py-1',

            //Shape
            {
              'rounded-md': !props.rounded,
              'rounded-full': props.rounded,
            },

            //Colors
            'text-white',
            {
              'bg-primary-500':
                props.severity == null || props.severity == 'primary',
              'bg-green-500': props.severity == 'success',
              'bg-blue-500': props.severity == 'info',
              'bg-orange-500': props.severity == 'warning',
              'bg-red-500': props.severity == 'danger',
            },
          ],
        }),
        value: {
          class: 'leading-normal',
        },
        icon: {
          class: 'mr-1 text-sm',
        },
      };

      var terminal = {
        root: {
          class: [
            // Spacing
            'p-5',

            // Shape
            'rounded-md',

            // Color
            'bg-surface-900 text-white',
            'border border-surface-700',

            // Sizing & Overflow
            'h-72 overflow-auto',
          ],
        },
        container: {
          class: [
            // Flexbox
            'flex items-center',
          ],
        },
        prompt: {
          class: [
            // Color
            'text-surface-400',
          ],
        },
        response: {
          class: [
            // Color
            'text-primary-400',
          ],
        },
        command: {
          class: [
            // Color
            'text-primary-400',
          ],
        },
        commandtext: {
          class: [
            // Flexbox
            'flex-1 shrink grow-0',

            // Shape
            'border-0',

            // Spacing
            'p-0',

            // Color
            'bg-transparent text-inherit',

            // Outline
            'outline-none',
          ],
        },
      };

      var textarea = {
        root: ({ context, props }) => ({
          'class': [
            // Font
            'font-normal placeholder:text-general-200 placeholder:!font-normal text-xs',

            'w-full',
            {
              'resize-none': props.autoResize,
              'resize': !props.autoResize,
            },

            props.inputClass,
            // Spacing
            'm-0',
            'py-1 px-3',

            // Shape
            'rounded',
            'appearance-none',

            ...ringClass,
            {
              '!ring-danger-500': context.invalidInput,
            },

            // // States
            {
              'bg-transparent text-general-800': !context.disabled,
              'bg-general-50 text-general-200': context.disabled,
            },
            'focus-visible:outline-none',

            // Misc
            'appearance-none',
            'transition-colors duration-200',
          ],
          'style': `min-height: ${props.rows * 26}px`,
          'data-wv-name': 'textarea',
          'data-wv-section': 'root',
        }),
      };

      var tieredmenu = {
        root: {
          class: [
            // Shape
            'rounded-md',

            // Size
            'min-w-[12rem]',
            'p-1.5',

            // Colors
            'bg-surface-0',
            'ring-1 ring-surface-200',
          ],
        },
        menu: {
          class: [
            // Spacings and Shape
            'list-none',
            'm-0',
            'p-0',
            'outline-none',
          ],
        },
        menuitem: {
          class: ['relative first:mt-0 mt-1'],
        },
        content: ({ context }) => ({
          class: [
            //Shape
            'rounded-md',

            //  Colors
            {
              'text-surface-500/70':
                !context.focused && !context.active,
              'text-surface-500/70 bg-surface-200':
                context.focused && !context.active,
              'text-surface-900/80 bg-surface-50':
                context.focused && context.active,
              'text-surface-900/80 bg-surface-50':
                !context.focused && context.active,
            },

            // Hover States
            {
              'hover:bg-surface-50': !context.active,
              'hover:bg-surface-100 text-surface-900/80':
                context.active,
            },

            // Transitions
            'transition-shadow',
            'duration-200',
          ],
        }),
        action: {
          class: [
            'relative',

            // Font
            'font-semibold',

            // Flexbox
            'flex',
            'items-center',

            // Spacing
            'py-2',
            'px-3',

            // Misc
            'no-underline',
            'overflow-hidden',
            'cursor-pointer',
            'select-none',
          ],
        },
        icon: {
          class: [
            // Spacing
            'mr-2',
            'leading-6',
            'text-sm',
          ],
        },
        label: {
          class: ['leading-none', 'text-sm'],
        },
        submenuicon: {
          class: [
            // Position
            'ml-auto',
          ],
        },
        submenu: {
          class: [
            // Size
            'w-full sm:w-48',

            // Spacing
            'p-1.5',
            'm-0 mx-1.5',
            'list-none',

            // Shape
            'shadow-none sm:shadow-md',
            'border-0',

            // Position
            'static sm:absolute',
            'z-10',

            // Color
            'bg-surface-0',
          ],
        },
        separator: {
          class: 'border-t border-surface-200 my-1',
        },
      };

      const isEmptyObject = (obj) => {
        if (!obj) return true;

        const keys = Object.keys(obj);
        if (!keys.length) return true; // Empty at the top level

        return keys.every((key) => {
          const value = obj[key];

          if (typeof value === 'object') {
            if (Array.isArray(value)) {
              return (
                !value.length ||
                value.every((val) =>
                  typeof val === 'object' ? isEmptyObject(val) : val == null,
                )
              );
            }

            return isEmptyObject(value);
          }

          return value == null;
        });
      };

      var timeline = {
        root: ({ props }) => ({
          class: ['flex flex-col grow', 'text-xs font-normal text-grayscale-900'],
        }),
        event: ({ props, context }) => ({
          class: ['grid grid-cols-[max-content,1fr] gap-x-[10px] gap-y-1'],
        }),
        opposite: ({ props, context }) => ({
          class: 'hidden',
        }),
        separator: ({ props }) => ({
          class: [
            'flex items-center flex-initial',
            {
              'flex-col': props.layout === 'vertical',
              'flex-row': props.layout === 'horizontal',
            },
          ],
        }),
        marker: {
          class: [
            'w-max',
            'rounded-full',
            'bg-transparent',
            'ring-2 ring-inset ring-primary-400 p-0.5',

            'flex items-center justify-center',
          ],
        },
        markerArrow: (item) => ({
          'class': [
            'w-4 h-4 invisible transition-transform duration-200 ease-in-out text-grayscale-900',
            { '!visible': item.detail && !isEmptyObject(item.detail) },
            { 'rotate-180': item.showDetail },
          ],
          'data-wv-section': 'markerArrow',
        }),
        connector: ({ props }) => ({
          class: ['w-[2px] grow bg-grayscale-900'],
        }),
        content: ({ props, context }) => ({
          class: ['grid grid-rows-[max-content,auto] pb-2.5 gap-1'],
        }),
      };

      var toast = {
        root: ({ props }) => ({
          class: [
            //Size and Shape
            'w-full max-w-[496px]',

            // Positioning
            {
              '-translate-x-2/4':
                props.position == 'top-center' || props.position == 'bottom-center',
            },
          ],
          style: `z-index: ${props.baseZIndex}`,
        }),
        container: ({ props }) => ({
          'class': [
            'border-0 mt-6',
            'rounded-[7px] w-full',
            'shadow-lg',
            // Colors
            {
              'border-success-600 text-white bg-success-500':
                props.message.severity === 'success',
              'border-danger text-white bg-danger-500':
                props.message.severity === 'error',
              'border-primary-500 text-primary-500 bg-primary-50':
                props.message.severity === 'info',
            },
          ],
          'data-wv-severity': props.message.severity,
        }),
        content: {
          class: 'flex items-center py-3 px-3 text-inherit gap-2',
        },
        icon: {
          class: 'hidden',
        },
        text: {
          class: [
            // Font and Text
            'text-xs leading-4 font-normal tracking-[0.02em] text-inherit',
            'flex-1',
          ],
        },
        summary: {
          class: 'font-medium block',
        },
        detail: {
          class: 'text-inherit',
        },
        closebutton: ({ props }) => ({
          class: [
            // Flexbox
            'flex items-center justify-center',

            // Size
            'w-6 h-6',

            // Spacing and Misc
            'ml-auto relative',

            // Shape
            'rounded-full',

            // Colors
            'bg-transparent',
            {
              'text-white': props.message.severity === 'success',
              'text-danger': props.message.severity === 'error',
              'text-primary-500': props.message.severity === 'info',
            },

            // Transitions
            'transition duration-200 ease-in-out',

            // States

            // Misc
            'overflow-hidden',
          ],
        }),
        closeicon: {
          class: [
            // Sizing and Spacing
            'w-3 h-3',
            'shrink-0',
          ],
        },
        transition: {
          enterFromClass: 'opacity-0 translate-y-2/4',
          enterActiveClass: 'transition-[transform,opacity] duration-300',
          leaveFromClass: 'max-h-[1000px]',
          leaveActiveClass:
            '!transition-[max-height_.45s_cubic-bezier(0,1,0,1),opacity_.3s,margin-bottom_.3s] overflow-hidden',
          leaveToClass: 'max-h-0 opacity-0 mb-0',
        },
      };

      var togglebutton = {
        root: {
          class: [
            'relative',

            // Alignment
            'inline-flex',
            'align-bottom',

            // Misc
            'cursor-pointer',
            'select-none',
          ],
        },
        box: ({ props }) => ({
          class: [
            // Alignments
            'items-center inline-flex flex-1 text-center align-bottom justify-center',

            // Sizes & Spacing
            'px-2.5 py-1.5',
            'text-sm',

            // Shapes
            'rounded-md shadow-sm',

            // Colors
            'text-surface-700/80',
            'ring-1 ring-surface-200',
            {
              'bg-surface-0 ': !props.modelValue,
              'bg-surface-100': props.modelValue,
            },

            // States
            'peer-hover:bg-surface-200',
            {
              'peer-focus-visible:ring-2 peer-focus-visible:ring-inset peer-focus-visible:ring-primary-500':
                !props.disabled,
            },

            // Transitions
            'transition-all duration-200',

            // Misc
            {
              'cursor-pointer': !props.disabled,
              'opacity-60 select-none pointer-events-none cursor-default':
                props.disabled,
            },
          ],
        }),
        label: {
          class: 'font-semibold text-center w-full',
        },
        input: {
          class: [
            'peer',

            // Size
            'w-full ',
            'h-full',

            // Position
            'absolute',
            'top-0 left-0',
            'z-10',

            // Spacing
            'p-0',
            'm-0',

            // Shape
            'opacity-0',
            'rounded-md',
            'outline-none',
            'border border-surface-200',

            // Misc
            'appareance-none',
            'cursor-pointer',
          ],
        },
        icon: {
          class: [' mr-2', 'text-surface-700/80'],
        },
      };

      var toolbar = {
        root: {
          class: [
            // Flex & Alignment
            'flex items-center justify-between flex-wrap',
            'gap-6',

            // Spacing
            'px-6 py-2',
            'min-h-[4rem]',

            // Shape
            'rounded-md',
            'shadow-md',

            // Color
            'bg-surface-0',
            'ring-1 ring-surface-100',
          ],
        },
        start: {
          class: 'flex items-center',
        },
        center: {
          class: 'flex items-center',
        },
        end: {
          class: 'flex items-center',
        },
      };

      var tooltip = {
        root: ({ context, props }) => ({
          class: [
            // Position
            'absolute',

            {
              // Add space 4px at the bottom
              'pb-1': context?.top,
              'pt-1': context?.bottom,
              'pl-1': context?.right,
              'pr-1': context?.left,
            },

            '!transition',
          ],
        }),
        arrow: {
          class: 'hidden',
        },
        text: {
          class: [
            // Shape
            'rounded-[10px]',

            // Background
            'bg-white',

            // Spacing
            'px-3 py-1',

            // Typography
            'text-xs font-normal leading-4 text-left',

            // Color
            'text-grayscale-900',

            // Misc
            'shadow-[0px_4px_4px_0px_#00000040]',
            'whitespace-pre-line',
            'break-words',
          ],
        },
      };

      var tree = {
        root: {
          class: [
            'relative',

            // Space

            // Shape
            'rounded-md',
            'min-h-8',

            // Color
          ],
        },
        wrapper: {
          class: ['overflow-auto'],
        },
        container: {
          class: [
            // Spacing
            'm-0 p-0',

            // Misc
            'list-none overflow-auto',
          ],
        },
        node: {
          class: [
            'p-[2px]',
            'rounded-md',
            'focus:outline-none focus:outline-offset-0',
          ],
        },
        content: ({ context, props }) => ({
          class: [
            // Flex and Alignment
            'flex items-center',

            // Shape
            'rounded-md',

            // Spacing
            'p-1',

            // Colors
            'text-surface-700',
            {
              'bg-primary-50 !text-primary-500 [&_*]:text-primary-500':
                context.selected,
            },

            // States
            {
              'hover:bg-primary-50':
                props.selectionMode == 'single' || props.selectionMode == 'multiple',
            },

            // Transition
            'transition-shadow duration-200',

            {
              'cursor-pointer select-none':
                props.selectionMode == 'single' || props.selectionMode == 'multiple',
            },

            // Miscelanous
            'cursor-pointer',
          ],
        }),
        toggler: ({ context }) => ({
          class: [
            // Flex and Alignment
            'inline-flex items-center justify-center',

            'shrink-0',

            // Shape
            'border-0 rounded-full',

            // Size and Spacing
            'w-4 h-4',

            // Spacing
            'mr-0.5',

            // Colors
            'text-general-800',
            'bg-transparent',
            {
              invisible: context.leaf,
            },

            // States
            'hover:bg-surface-100',

            // Transition
            'transition duration-200',

            // Misc
            'cursor-pointer select-none',
          ],
        }),
        togglericon: {
          class: [
            // Size
            'w-4 h-4',

            // Color
          ],
        },
        nodeCheckbox: {
          root: {
            class: [
              'relative',

              // Alignment
              'inline-flex',
              'align-bottom',

              // Size
              'w-4',
              'h-4',

              // Spacing
              'mr-2',

              // Misc
              'cursor-default',
              'select-none',
            ],
          },
          box: ({ props, context }) => ({
            class: [
              // Alignment
              'flex',
              'items-center',
              'justify-center',

              // Size
              'w-4',
              'h-4',

              // Shape
              'rounded',
              'border',

              // Colors
              'text-surface-600',
              {
                'border-surface-300 bg-surface-0':
                  !context.checked,
                'border-primary-500 bg-primary-500':
                  context.checked,
              },

              {
                'ring-2 ring-primary-500':
                  !props.disabled && context.focused,
                'cursor-default opacity-60': props.disabled,
              },

              // States
              {
                'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500':
                  !props.disabled,
                'cursor-default opacity-60': props.disabled,
              },

              // Transitions
              'transition-colors',
              'duration-200',
            ],
          }),
          input: {
            class: [
              'peer',

              // Size
              'w-full ',
              'h-full',

              // Position
              'absolute',
              'top-0 left-0',
              'z-10',

              // Spacing
              'p-0',
              'm-0',

              // Shape
              'rounded',
              'border',

              // Shape
              'opacity-0',
              'rounded-md',
              'outline-none',
              'border-2 border-surface-300',

              // Misc
              'appareance-none',
            ],
          },
          icon: {
            class: [
              // Font
              'text-normal',

              // Size
              'w-3',
              'h-3',

              // Colors
              'text-white',

              // Transitions
              'transition-all',
              'duration-200',
            ],
          },
        },
        nodeicon: {
          class: [
            // Space
            'hidden',
          ],
        },
        subgroup: {
          class: ['!m-0 list-none p-0 !pl-1'],
        },
        filtercontainer: {
          class: [
            'relative block',

            // Space
            'mb-2',

            // Size
            'w-full',
          ],
        },
        input: {
          class: [
            'relative',

            // Font
            'font-sans leading-6',
            'sm:text-sm',

            // Spacing
            'm-0',
            'py-1.5 px-3 pr-10',

            // Size
            'w-full',

            // Shape
            'rounded-md',

            // Colors
            'text-surface-900',
            'placeholder:text-surface-400',
            'bg-surface-0',
            'ring-1 ring-inset ring-surface-300',

            // States
            'hover:border-primary-500',
            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500',

            // Transition & Misc
            'appearance-none',
            'transition-colors duration-200',
          ],
        },
        loadingicon: {
          class: [
            'text-general-400',
            'absolute text-lg top-[50%] right-[50%] -mt-2 -mr-2 animate-spin',
          ],
        },
        searchicon: {
          class: [
            // Position
            'absolute top-1/2 -mt-2 right-3',

            // Color
            'text-surface-600',
          ],
        },
        label: ({ props }) => ({
          class: [
            'inline-flex justify-between items-center gap-2',
            'w-full',
            'text-xs font-medium text-general-800',
          ],
        }),
      };

      var treeselect = {
        root: ({ props, state }) => ({
          class: [
            // Display and Position
            'inline-flex',
            'relative',

            // Shape
            'w-full md:w-56',
            'rounded-md',
            'shadow-sm',

            // Color and Background
            'bg-surface-0',

            // States
            {
              'ring-1 ring-inset ring-surface-300':
                !state.focused,
              'ring-2 ring-inset ring-primary-500':
                state.focused,
            },

            // Misc
            'cursor-default',
            'select-none',
            { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled },
          ],
        }),
        labelContainer: {
          class: ['overflow-hidden flex flex-auto cursor-pointer'],
        },
        label: {
          class: [
            'block leading-5',

            // Space
            'py-1.5 px-3',

            // Color
            'text-surface-800/80',

            // Transition
            'transition duration-200',

            // Misc
            'overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis',
          ],
        },
        trigger: {
          class: [
            //Font
            'sm:text-sm',

            // Flexbox
            'flex items-center justify-center',
            'shrink-0',

            // Color and Background
            'bg-transparent',
            'text-surface-500',

            // Size
            'w-12',

            // Shape
            'rounded-tr-md',
            'rounded-br-md',
          ],
        },
        panel: {
          class: [
            // Position
            'absolute top-0 left-0',
            'mt-2',

            // Shape
            'border-0',
            'rounded-md',
            'shadow-md',

            // Color
            'bg-surface-0',
            'text-surface-800/80',
            'ring-1 ring-inset ring-surface-300',
          ],
        },
        wrapper: {
          class: [
            // Sizing
            'max-h-[200px]',

            // Misc
            'overflow-auto',
          ],
        },
        tree: {
          root: {
            class: [
              'relative',

              // Space
              'p-1.5',
            ],
          },
          wrapper: {
            class: ['overflow-auto'],
          },
          container: {
            class: [
              // Spacing
              'm-0 p-0',

              // Misc
              'list-none overflow-auto',
            ],
          },
          node: {
            class: [
              'p-[2px]',
              'rounded-md',
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500',
            ],
          },
          content: ({ context, props }) => ({
            class: [
              // Flex and Alignment
              'flex items-center',

              // Shape
              'rounded-md',

              // Spacing
              'p-2',

              // Colors
              'text-surface-700',
              {
                'bg-surface-100 text-primary-500':
                  context.selected,
              },

              // States
              {
                'hover:bg-surface-200':
                  props.selectionMode == 'single' ||
                  props.selectionMode == 'multiple',
              },

              // Transition
              'transition-shadow duration-200',

              {
                'cursor-pointer select-none':
                  props.selectionMode == 'single' ||
                  props.selectionMode == 'multiple',
              },
            ],
          }),
          toggler: ({ context }) => ({
            class: [
              // Flex and Alignment
              'inline-flex items-center justify-center',

              // Shape
              'border-0 rounded-full',

              // Size and Spacing
              'mr-2',
              'w-6 h-6',

              // Spacing
              'mr-2',

              // Colors
              'text-surface-500',
              'bg-transparent',
              {
                invisible: context.leaf,
              },

              // States
              'hover:text-surface-700',
              'hover:bg-surface-100',
              'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset',
              'focus:ring-primary-500',

              // Transition
              'transition duration-200',

              // Misc
              'cursor-pointer select-none',
            ],
          }),
          togglericon: {
            class: [
              // Size
              'w-4 h-4',

              // Color
              'text-surface-500/70',
            ],
          },
          nodeCheckbox: {
            root: {
              class: [
                'relative',

                // Alignment
                'inline-flex',
                'align-bottom',

                // Size
                'w-4',
                'h-4',

                // Spacing
                'mr-2',

                // Misc
                'cursor-default',
                'select-none',
              ],
            },
            box: ({ props, context }) => ({
              class: [
                // Alignment
                'flex',
                'items-center',
                'justify-center',

                // Size
                'w-4',
                'h-4',

                // Shape
                'rounded',
                'border',

                // Colors
                'text-surface-600',
                {
                  'border-surface-300 bg-surface-0':
                    !context.checked,
                  'border-primary-500 bg-primary-500':
                    context.checked,
                },

                {
                  'ring-2 ring-primary-500':
                    !props.disabled && context.focused,
                  'cursor-default opacity-60': props.disabled,
                },

                // States
                {
                  'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500':
                    !props.disabled,
                  'cursor-default opacity-60': props.disabled,
                },

                // Transitions
                'transition-colors',
                'duration-200',
              ],
            }),
            input: {
              class: [
                'peer',

                // Size
                'w-full ',
                'h-full',

                // Position
                'absolute',
                'top-0 left-0',
                'z-10',

                // Spacing
                'p-0',
                'm-0',

                // Shape
                'rounded',
                'border',

                // Shape
                'opacity-0',
                'rounded-md',
                'outline-none',
                'border-2 border-surface-300',

                // Misc
                'appareance-none',
              ],
            },
            icon: {
              class: [
                // Font
                'text-normal',

                // Size
                'w-3',
                'h-3',

                // Colors
                'text-white',

                // Transitions
                'transition-all',
                'duration-200',
              ],
            },
          },
          nodeicon: {
            class: [
              // Space
              'mr-2',

              // Color
              'text-surface-600/70',
            ],
          },
          subgroup: {
            class: ['m-0 list-none p-0 pl-2 mt-1'],
          },
          filtercontainer: {
            class: [
              'relative block',

              // Space
              'mb-2',

              // Size
              'w-full',
            ],
          },
          input: {
            class: [
              'relative',

              // Font
              'font-sans leading-6',
              'sm:text-sm',

              // Spacing
              'm-0',
              'py-1.5 px-3 pr-10',

              // Size
              'w-full',

              // Shape
              'rounded-md',

              // Colors
              'text-surface-900',
              'placeholder:text-surface-400',
              'bg-surface-0',
              'ring-1 ring-inset ring-surface-300',

              // States
              'hover:border-primary-500',
              'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500',

              // Transition & Misc
              'appearance-none',
              'transition-colors duration-200',
            ],
          },
          loadingicon: {
            class: [
              'text-surface-500',
              'absolute top-[50%] right-[50%] -mt-2 -mr-2 animate-spin',
            ],
          },
          searchicon: {
            class: [
              // Position
              'absolute top-1/2 -mt-2 right-3',

              // Color
              'text-surface-600',
            ],
          },
        },
        transition: {
          enterFromClass: 'opacity-0 scale-y-[0.8]',
          enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
          leaveActiveClass: 'transition-opacity duration-100 ease-linear',
          leaveToClass: 'opacity-0',
        },
      };

      var tristatecheckbox = {
        root: {
          class: [
            'relative',

            'w-4 h-4',

            // Alignment
            'inline-flex',
            'align-bottom',

            // Transitions
            'transition-all',
            'duration-200',
          ],
        },
        box: ({ props, context }) => ({
          class: [
            // Alignment
            'flex',
            'items-center',
            'justify-center',

            // Size
            'w-full',
            'h-full',

            // Shape
            'rounded',
            'border-2',

            // Colors
            'text-surface-0',
            {
              'border-general-100 bg-surface-0':
                !context.active && !props.disabled,
              'border-primary-500 bg-primary-500':
                context.active && !props.disabled,
            },

            {
              'ring-2 ring-primary-500':
                !props.disabled && context.focused,
              'cursor-default': props.disabled,
            },

            // States
            {
              'peer-focus-visible:ring-2 peer-focus-visible:ring-offset-1 peer-focus-visible:ring-primary':
                !props.disabled,
              'cursor-default bg-general-50 border-general-200':
                props.disabled && !context.active,
              'bg-general-200 border-general-200':
                props.disabled && context.active,
            },

            // Transitions
            'transition-colors',
            'duration-200',
          ],
        }),
        input: {
          class: [
            'peer',

            // Size
            'w-full ',
            'h-full',

            // Position
            'absolute',
            'top-0 left-0',
            'z-10',

            // Spacing
            'p-0',
            'm-0',

            // Shape
            'rounded',
            'border',

            // Shape
            'opacity-0',
            'rounded-md',
            'outline-none',
            'border-2 border-surface-300',

            // Misc
            'appareance-none',
            'cursor-pointer',
          ],
        },
        checkicon: {
          class: [
            // Font
            'text-normal',

            // Size
            'w-3',
            'h-3',

            // Colors
            'text-surface-0',

            // Transitions
            'transition-all',
            'duration-200',
          ],
        },
        uncheckicon: {
          class: [
            // Font
            'text-normal',

            // Size
            'w-3',
            'h-3',

            // Colors
            'text-surface-0',

            // Transitions
            'transition-all',
            'duration-200',
          ],
        },
      };

      var validatormessage = {
        root: {
          'class': ['!mt-0 text-danger-500 text-xs font-light'],
          'data-wv-name': 'validatormessage',
          'data-wv-section': 'root',
        },
      };

      var buttonbulkaction = {
        root: {
          'class': 'flex gap-2 items-center',
          'data-wv-name': 'buttonbulkaction',
          'data-wv-section': 'root',
        },

        menu: {
          'id': 'bulkaction-overlay-menu',
          'class': 'flex gap-2 items-center',
          'data-wv-section': 'menu',
        },

        selectionmessage: {
          'class': 'text-xs text-grayscale-900 cursor-default whitespace-nowrap',
          'data-wv-section': 'selectionmessage',
        },

        buttonselectall: ({ context }) => ({
          'class': [
            '!px-1.5 !py-1 -ml-1.5 -mr-1.5 !text-xs',
            { 'pointer-events-none': context.selectedAll },
          ],
          'data-wv-section': 'buttonselectall',
        }),

        bulkactiontoggle: {
          'data-wv-section': 'bulkactiontoggle',
        },

        buttonclearselection: {
          'class': '!p-0 !w-6 !h-6 [&_.icon]:!w-5 [&_.icon]:!h-5',
          'data-wv-section': 'buttonclearselection',
        },
      };

      var badgegroup = {
        root: {
          'class': 'flex flex-nowrap items-center gap-1',
          'data-wv-name': 'badgegroup',
          'data-wv-section': 'root',
        },
        showmorebutton: {
          'class': '!p-1 !h-auto',
          'data-wv-section': 'showmorebutton',
        },

        showmoredialog: {
          root: {
            'class': [dialog.root({ state: {} }).class, 'w-[400px] !px-6 !py-3'],
            'data-wv-section': 'showmoredialog',
          },
          title: {
            'class': ['text-general-900 text-sm font-semibold leading-5'],
            'data-wv-section': 'showmoredialogtitle',
          },
          content: {
            'class': ['flex flex-wrap gap-1'],
            'data-wv-section': 'showmoredialogcontent',
          },

          // All section in Dialog Preset can be added here
        },
      };

      var breadcrumb = {
        root: {
          'class': [
            'flex gap-[5px] items-center text-left h-[21px]',
            'text-grayscale-900 ',
            'text-xs font-normal leading-none',
          ],
          'data-wv-name': 'breadcrumb',
          'data-wv-section': 'root',
        },

        item: ({ context }) => ({
          'class': {
            'font-semibold': context.lastItem,
            'text-primary-400': context.hasRoute && !context.firstItem,
          },
          'data-wv-section': 'breadcrumb-menu',
        }),

        itemlink: {
          'class': 'cursor-pointer text-primary',
          'data-wv-section': 'itemlink',
        },

        separator: {
          'content': '>',
          'data-wv-section': 'separator',
        },
      };

      var inputcurrency = {
        currency: (ctx) => ({
          'class': [...commonClasses(ctx), 'rounded-l rounded-r-none h-[30px]'],
          'data-wv-section': 'currency',
        }),
        value: (ctx) => ({
          'class': [...commonClasses(ctx), 'rounded-r rounded-l-none -ml-[1px]'],
          'data-wv-section': 'value',
        }),
      };

      var fileupload = {
        root: {
          'data-wv-name': 'fileupload',
          'data-wv-section': 'root',
        },
        wrapper: {
          'class': ['w-full'],
          'data-wv-section': 'wrapper',
        },
        input: ({ state }) => ({
          class: [
            {
              '': state.fileName != null,
            },
          ],
        }),
        buttonupload: ({ props }) => ({
          class: [{ 'mb-[21px]': props.fileExtensions || props.fileRequirements }],
        }),
        requirements: {
          class: 'flex w-full justify-start',
        },
      };

      const directives = {
        badge: badgedirective,
        ripple,
        tooltip,
      };

      const wangsvue = exports("wangsvue", {
        global,
        directives,

        // Forms
        form,
        autocomplete,
        dropdown,
        inputnumber,
        inputphonenumber,
        inputcurrency,
        inputotp,
        inputtext,
        inputbadge,
        calendar,
        checkbox,
        buttonradio,
        inputswitch,
        selectbutton,
        slider,
        chips,
        rating,
        multiselect,
        togglebutton,
        cascadeselect,
        listbox,
        colorpicker,
        inputgroup,
        inputgroupaddon,
        inputmask,
        knob,
        treeselect,
        tristatecheckbox,
        textarea,
        password,
        validatormessage,
        fileupload,

        // Buttons
        button,
        buttondownload,
        buttonbulkaction,
        buttonsearch,
        buttonfilter,
        splitbutton,

        // Data
        paginator,
        datatable,
        tree,
        dataview,
        dataviewlayoutoptions,
        organizationchart,
        orderlist,
        picklist,
        timeline,
        changelog,

        // Panels
        accordion,
        panel,
        fieldset,
        card,
        tabview,
        divider,
        toolbar,
        scrollpanel,

        // Menu
        contextmenu,
        menu,
        menubar,
        steps,
        breadcrumb,
        tieredmenu,
        panelmenu,
        megamenu,
        dock,
        tabmenu,

        // Overlays
        dialog,
        dialogconfirm,
        dialogform,
        overlaypanel,
        sidebar,
        confirmpopup,

        // Messages
        message,
        inlinemessage,
        toast,

        // Media
        carousel,

        // Misc
        badge,
        badgegroup,
        avatar,
        avatargroup,
        tag,
        chip,
        progressbar,
        skeleton,
        scrolltop,
        terminal,
        image,
      });

    })
  };
}));
